import axios from "axios";

export const HTTP = axios.create({
    baseURL: import.meta.env.VITE_BASE_API,
    headers: {
        Authorization: 'Bearer {token}',
    },
})

export const BASE_API = import.meta.env.VITE_BASE_API
export const METAMAP_CLIENT_ID = import.meta.env.VITE_METAMAP_CLIENT_ID
export const METAMAP_FLOW_ID = import.meta.env.VITE_METAMAP_FLOW_ID
export const VITE_LIVECHAT = import.meta.env.VITE_LIVECHAT
export const VITE_BOT_API = import.meta.env.VITE_BOT_API
export const BOT_WS = import.meta.env.VITE_BOT_WS
