const persenTage = (rate, percentage_profit) => {
    const buyRate = parseFloat(rate)
    const percentageProfit = parseFloat(percentage_profit)

    // Calculate sell rate
    const sellRate = buyRate + buyRate * (percentageProfit / 100)

    // Calculate percentage result
    const percentageResult = ((sellRate - buyRate) / buyRate) * 100

    return percentageResult.toFixed(2)
  }

  export default persenTage
