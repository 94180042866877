<template>
  <Transition name="modal-upload">
    <div v-if="show" class="modal-masks">
      <div v-if="!isSuccess" class="modal-container">
        <div class="modal-header">
          <button class="close-button" @click="handleClose">
            <span class="mdi mdi-close-circle-outline"></span>
          </button>
        </div>

        <div class="modal-body">
          <label
            for="input-file"
            id="drop-area"
            @dragover="handleDragOver"
            @dragleave="handleDragLeave"
            @drop="handleDropUploadImg"
            :style="isDragOver && 'background-color: #e7f3fe'"
          >
            <input type="file" accept="image/*" id="input-file" @change="uploadImg" hidden />
            <div v-if="!isDragOver" class="text-center">
              <span class="mdi mdi-cloud-upload-outline"></span>
              <h1>Drag & drop <span>image</span>,</h1>
              <h1><span>JPG, PNG</span> or <span>JPEG</span></h1>
              <h3>or <span>browser files</span> on your computer</h3>
            </div>
            <div v-else class="text-center">
              <span class="mdi mdi-tooltip-image-outline"></span>
              <h1>Drop'em right here !</h1>
            </div>
          </label>

          <!-- erorr message -->
          <span class="text-danger" v-if="imgForUpload == null || isError">{{ resMsg }}</span>
          <div v-if="imgDetails" class="card-container">
            <div class="d-flex gap-3">
              <div class="image-cover" :style="`background-image: url(${imgDetails.url})`"></div>
              <div class="card-details">
                <h2>{{ imgDetails.name }}</h2>
                <h4>{{ imgDetails.size }} MB • {{ imgDetails.type }}</h4>
              </div>
            </div>
            <button @click="removeImg" class="button-reset">
              <span class="mdi mdi-close-circle-outline" style="color: #dd8181"></span>
            </button>
          </div>
        </div>

        <div class="modal-footer" @click="submitImg">
          <button type="button" class="btn btn-primary" :disabled="loading">
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-else>Upload</span>
          </button>
        </div>
      </div>

      <div v-else class="modal-container">
        <div class="suc_wrap">
          <success-animation />
          <p class="text-center">{{ resMsg }}</p>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// service
import service from '@/utils/service'

// stores
import { useProfile } from '@/stores/profile-store'

// components
import SuccessAnimation from '../animations/SuccessAnimation.vue'

export default {
  components: {
    SuccessAnimation
  },
  props: {
    show: Boolean
  },
  data() {
    return {
      store: useProfile(),
      isDragOver: false,
      imgDetails: null,
      imgForUpload: null,
      loading: false,
      resMsg: null,
      isSuccess: false,
      isError: false
    }
  },
  methods: {
    handleDragOver(e) {
      e.preventDefault()
      e.stopPropagation()
      this.isDragOver = true
    },
    handleDragLeave(e) {
      e.preventDefault()
      e.stopPropagation()
      this.isDragOver = false
    },
    handleDropUploadImg(e) {
      e.preventDefault()
      const img = e.dataTransfer.files
      this.allowedFormat(img[0])
    },
    uploadImg(e) {
      const img = e.target.files
      this.allowedFormat(img[0])
    },
    allowedFormat(img) {
      const format = ['image/jpeg', 'image/png', 'image/jpg']
      if (format.includes(img.type)) {
        this.imgForUpload = img
        this.setImgValues(img)
      } else {
        this.resMsg = '* format is not allowed'
      }
      this.isDragOver = false
    },
    setImgValues(img) {
      this.imgDetails = {
        url: URL.createObjectURL(img),
        name: img.name,
        size: this.bytesToMB(img.size),
        type: img.type
      }
    },
    removeImg() {
      this.imgDetails = null
      this.imgForUpload = null
      this.resMsg = null
    },
    bytesToMB(bytes) {
      return `${bytes / (1024 * 1024)}`.substring(0, 5)
    },
    submitImg() {
      this.loading = true

      if (this.imgForUpload !== null) {
        service
          .post(
            '/profile/update-avatar',
            { avatar_file: this.imgForUpload },
            { headers: { 'Content-Type': 'multipart/form-data', requiresAuth: true } }
          )
          .then((response) => {
            if (response.success) {
              this.resMsg = 'Successful Upload'
              this.isSuccess = true
              this.store.getProfile()
              setTimeout(() => {
                this.$emit('close')
                this.isSuccess = false
              }, 3000)
              console.log(response)
            } else {
              console.log(response)
            }
          })
          .catch((error) => {
            this.isError = true
            this.resMsg = '* Server error'
            console.error('Error:', error)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.resMsg = '* Upload is required !'
        this.loading = false
      }
    },
    handleClose() {
      this.isSuccess = false
      this.isError = false
      this.resMsg = null
      this.removeImg()
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
* {
  color: black;
}
h1 {
  font-size: large;
}
h1 span {
  color: #96a3df;
}
h2 {
  color: #0c467e;
  font-size: medium;
}
h3 {
  font-size: small;
}
h3 span {
  color: #96a3df;
}
h4 {
  font-size: smaller;
}
img {
  width: 100%;
}
.button-reset {
  border: none;
  background-color: transparent;
}
.card-container {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  margin-top: 1.5rem;
}
.card-details {
  display: flex;
  flex-direction: column;
}
.image-cover {
  width: 2.7rem;
  height: 2.7rem;
  background-size: cover;
  border-radius: 10rem;
  outline: solid 3px #96a5dfa4;
}
.close-button {
  border: none;
  padding: 0;
  background-color: transparent;
}
.mdi-close-circle-outline {
  font-size: 30px;
  color: #96a3df;
  transition: filter 0.1s ease;
}
.mdi-close-circle-outline:hover {
  filter: brightness(80%);
}
.mdi-cloud-upload-outline {
  font-size: 50px;
}
.mdi-tooltip-image-outline {
  font-size: 50px;
}
#drop-area {
  width: 100%;
  height: 15rem;
  padding: 3rem 0;
  border: 2.5px dotted #85c1fc;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.modal-masks {
  padding: 20px;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
}
.modal-container {
  width: 500px;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
.modal-header {
  display: flex;
  justify-content: flex-end;
  cursor: auto;
}
.modal-body {
  width: 100%;
}
.modal-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.suc_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.suc_wrap p {
  font-size: 20px;
}

/* transition */
.modal-upload-enter-active,
.modal-upload-leave-active {
  transition: opacity 0.2s ease;
}
.modal-upload-enter,
.modal-upload-leave-to {
  opacity: 0;
}
.modal-upload-enter-from .modal-container,
.modal-upload-leave-to .modal-container {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
</style>
