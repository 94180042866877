<template>
  <div>
    <div class="mb-3 row">
      <label for="example-text-input" class="col-md-3 col-form-label"> Available: </label>
      <div class="col-md-9">
        <input type="text" readonly class="form-control" v-model="balanceGroup.withdraw_income" />
      </div>
    </div>

    <div class="mb-3 row">
      <label for="example-text-input" class="col-md-3 col-form-label"> Withdraw: </label>
      <div class="col-md-9">
        <input type="text" class="form-control" v-model="wd.amount_input" />
      </div>
    </div>

    <div class="mb-3 row">
      <label for="example-text-input" class="col-md-3 col-form-label"> Network: </label>
      <div class="col-md-9">
        <input type="text" readonly class="form-control" v-model="networkWallet" />
      </div>
    </div>

    <div class="mb-3 row">
      <label for="example-text-input" class="col-md-3 col-form-label"> Address: </label>
      <div class="col-md-9">
        <input
          v-if="addressWallet"
          type="text"
          readonly
          class="form-control"
          v-model="addressWallet"
        />
        <div v-else class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body add_addr">
                <p>
                  No addresses have been saved. <br />
                  Please add an address first to proceed with withdrawals.
                </p>

                <button @click="showModalCreate = true" class="btn_add">Add Address</button>
              </div>
            </div>
          </div>
        </div>

        <button v-if="addressWallet" @click="showModalCreate = true" class="btn_add mt-3">
          Change Address
        </button>
      </div>
    </div>

    <div v-if="profiles.orang?.two_fa_is_active" class="mb-3 row">
      <label for="example-text-input" class="col-md-3 col-form-label"> 2FA Code </label>
      <div class="col-md-9">
        <input
          type="text"
          class="form-control"
          placeholder="Enter your code"
          v-model="google_secret"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <button
          @click="wdSubmit"
          style="
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            box-sizing: border-box;
            font-size: 14px;
            color: #fff;
            text-decoration: none;
            line-height: 2em;
            font-weight: bold;
            text-align: center;
            cursor: pointer;
            display: inline-block;
            border-radius: 5px;
            text-transform: capitalize;
            background-color: #34c38f;
            margin: 0;
            border-color: #34c38f;
            border-style: solid;
            border-width: 6px 16px;
          "
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>Submit</span>
        </button>
      </div>
    </div>

    <Teleport to="body">
      <modal-reusable
        :show="showModalFail"
        @close="showModalFail = false"
        :typeSuccess="true"
        :isSuccess="false"
        :resMsg="resMsg"
      >
      </modal-reusable>

      <modal-reusable
        :show="showModalSuccess"
        @close="showModalSuccess = false"
        :typeSuccess="true"
        :isSuccess="true"
        :resMsg="resMsg"
      >
      </modal-reusable>
    </Teleport>

    <ModalAddress :showModalCreate="showModalCreate" @close="(val) => (showModalCreate = val)" />
  </div>
</template>

<script>
// modules
import axios from 'axios'
import Cookies from 'js-cookie'
import { computed } from 'vue'

// store
import { useWalletHistory } from '@/stores/wallet-store'
import { useProfile } from '@/stores/profile-store'

// components
import ModalReusable from '@/components/modals/ModalReusable.vue'
import ModalAddress from '../../../modals/ModalAddress.vue'

export default {
  components: { ModalReusable, ModalAddress },
  data() {
    return {
      store: useWalletHistory(),
      profileStore: useProfile(),
      addressWallet: computed(() => this.store.addressWalletA),
      networkWallet: computed(() => this.store.networkWalletA),
      profiles: computed(() => this.profileStore.profiles),
      loading: false,
      showModalCreate: false,
      showModalSuccess: false,
      showModalFail: false,
      resMsg: null,
      isNoValid: false,
      isSuccess: false,

      baseURL: import.meta.env.VITE_BASE_API,
      balanceGroup: {},
      wd: {
        amount_input: 0,
        profit_name: 'WITHDRAW_INCOME',
        google_secret: ''
      }
    }
  },
  async mounted() {
    await this.ambilDataProfitGroup()
  },

  methods: {
    async ambilDataProfitGroup() {
      var res = await axios({
        method: 'get',
        url: `${this.baseURL}/balances/balance-group`,
        headers: {
          JWT: Cookies.get('jwt')
        }
      })

      this.balanceGroup = res.data.data
    },

    async wdSubmit() {
      this.loading = true

      try {
        var res = await axios({
          method: 'post',
          url: `${this.baseURL}/withdraws/create-profit`,
          headers: {
            JWT: Cookies.get('jwt')
          },
          data: {
            amount_input: this.wd.amount_input,
            profit_name: this.wd.profit_name,
            google_secret: this.wd.google_secret
          }
        })

        if (res.data.success) {
          this.showModalSuccess = true
          this.resMsg = 'Withdrawal processing'
          setTimeout(() => {
            window.location.reload()
          }, 4000)
        } else {
          this.showModalFail = true
          this.resMsg = res.data.msg
        }

        console.log('res', res.data)
      } catch (error) {
        console.error('Error: ', error)
      } finally {
        this.loading = false

        setTimeout(() => {
          this.resetState()
        }, 4000)
      }
    },
    resetState() {
      this.showModalFail = false
      this.showModalSuccess = false
      this.resMsg = null
      this.isNoValid = false
      this.totalSumSelectedDeposits = 0
      this.showModalCreate = false
      // this.netWorkSelected = ''
      this.address = ''
      this.warMsg = null
      this.isSuccess = false
    }
  }
}
</script>

<style scoped>
.add_addr {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add_addr p {
  text-align: center;
}
.btn_add {
  padding: 5px 15px;
  background-color: #eaf5ff;
  border: none;
  border-radius: 8px;
  color: #1e90ff;
}
</style>
