<template>
  <div class="card">
    <div class="card-body">
      <div class="row gap-5 col-12 py-5 mx-0 justify-content-center text-center">
        <h2 class="font-weight-bold">Subscriptions</h2>
        <SubscriptionCard v-for="(item, index) in subsPackage" :subsPackage="item" :key="index" />
      </div>
    </div>
  </div>

  <Teleport to="body">
    <modal-reusable :show="showModalSelect" @close="showModalSelect = false">
      <template #body>
        <div v-if="isSuccess" class="success_wrap">
          <SuccessAnimation />
          <h2>{{ responseMsg }}</h2>
        </div>

        <div v-else-if="isFailur" class="success_wrap">
          <FailureAnimation />
          <p>{{ responseMsg }}</p>
        </div>

        <div v-else class="select_net">
          <h2 class="tex-center">Select Network</h2>
          <p class="text-center">Choose the network option to complete the payment process.</p>

          <div class="row select_wrap">
            <div class="col">
              <label class="card-radio-label mb-3">
                <input
                  type="radio"
                  name="pay-method"
                  id="pay-methodoption1"
                  @change="updateSelectNetwork('USDT.TRC20')"
                  class="card-radio-input"
                  checked
                />

                <div class="card-radio">
                  <i class="bx bx-cube-alt"></i>
                  <span>TRC20</span>
                </div>
              </label>
            </div>

            <div class="col">
              <label class="card-radio-label mb-3">
                <input
                  type="radio"
                  name="pay-method"
                  id="pay-methodoption3"
                  @change="updateSelectNetwork('USDT.BEP20')"
                  class="card-radio-input"
                />

                <div class="card-radio">
                  <i class="bx bx-cube-alt"></i>
                  <span>BEP20</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <router-link
          v-if="isSuccess"
          :to="`/dashboard/subscriptions/${subsID}`"
          select_net
          class="btn btn-outline-primary btn_c"
          >Detail Transaction</router-link
        >

        <div v-else-if="isFailur"></div>

        <button @click="subscribe" class="btn_submit" :disabled="loading" v-else>
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>Submit</span>
        </button>
      </template>
    </modal-reusable>
  </Teleport>
</template>

<script>
// service
import service from '@/utils/service'

// stores
import { useSubscription } from '@/stores/subscription-store'
import { useSessionStore } from '@/stores/session-store'

// components
import ModalReusable from '../../modals/ModalReusable.vue'
import SuccessAnimation from '@/components/animations/SuccessAnimation.vue'
import FailureAnimation from '@/components/animations/FailureAnimation.vue'
import SubscriptionCard from './SubscriptionCard.vue'

export default {
  components: {
    ModalReusable,
    SuccessAnimation,
    FailureAnimation,
    SubscriptionCard
  },
  data() {
    return {
      subsStore: useSubscription(),
      sessionStore: useSessionStore(),
      showModalSelect: false,
      responseMsg: '',
      subsID: null,
      loading: false,
      type: null,
      isSuccess: false,
      isFailur: false,
      currency_network: 'USDT.TRC20',
      subsPackage: {
        monthly: {
          mainColor: '#00b1e2',
          secondColor: '#a0daeb',
          thirdColor: '#6DCEE4',
          title: 'BASIC',
          childTitle: 'Basic Trial Features',
          price: '15',
          time: 'Monthly',
          lists: [true, false, false, false],
          selectPackageHandler: () => this.selectNetwork('monthly')
        },
        yearly: {
          mainColor: '#0F75BC',
          secondColor: '#9FCFEF',
          thirdColor: '#F48CA6',
          title: 'STANDARD',
          childTitle: 'Advance Features',
          price: '100',
          time: 'Yearly',
          lists: [true, true, true, false],
          selectPackageHandler: () => this.selectNetwork('yearly')
        },
        threeYears: {
          mainColor: '#283891',
          secondColor: '#8785BC',
          thirdColor: '#FBB86D',
          title: 'MASTER',
          childTitle: 'Advance Features',
          price: '250',
          time: '3 Years',
          lists: [true, true, true, true],
          selectPackageHandler: () => this.selectNetwork('3 years')
        }
      }
    }
  },
  methods: {
    selectNetwork(type) {
      this.type = type
      this.showModalSelect = true
    },
    updateSelectNetwork(network) {
      this.currency_network = network
    },
    subscribe() {
      this.loading = true
      this.sessionOff()
      service
        .post(
          '/subscriptions/create',
          { subtype: this.type, currency_network: this.currency_network },
          { headers: { requiresAuth: true } }
        )
        .then((response) => {
          if (response.success) {
            this.responseMsg = 'Success'
            this.isSuccess = true
            this.subsID = response.data.subscription.id
            this.subsStore.getSubsDatas(1)
          } else {
            this.isFailur = true
            this.responseMsg = response.msg
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
        .finally(() => {
          this.loading = false
          this.sessionStore.authenticate()
          this.sessionStore.checkSessionTimeout()

          setTimeout(() => {
            this.isSuccess = false
            this.isFailur = false
            this.showModalSelect = false
          }, 5000)
        })
    },
    sessionOff() {
      this.sessionStore.removeAuticaticated()
    }
  }
}
</script>

<style scoped>
.success_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}
.btn_c {
  width: 100%;
}
.select_wrap {
  width: 100%;
}
.btn_submit {
  width: 100%;
  height: 30px;
  background-color: #556ee6 !important;
  color: #fff !important;
}
.btn_submit:hover {
  background-color: #3b52c7 !important;
}
.select_net {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bold {
  font-weight: 700;
}
</style>
