<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex flex-column">
        <div class="d-flex flex-column gap-4">
          <div>
            <h4 class="card-title mb-4">Current Balance</h4>
            <h4 class="text-muted mb-4">
              ${{ parseFloat(balanceGroup.total_balance).toFixed(2) }}
            </h4>
            <h3 class="text-primary">Today's PNL {{ balanceGroup.today_pnl }}</h3>
          </div>
          <div class="alert alert-info p-2 m-0" v-for="item in deposits" :key="item.id">
            <h3>Maturity date deposit #{{ item.id }}</h3>
            <h3>{{ moment(item.mature_time).format('DD MMMM YYYY, h:mm:ss') }}</h3>
          </div>

          <div v-if="paidTime != null" class="mt-3">
            <h5 v-if="!isTimeUp" class="text-center mb-3">Your bonus will appear in:</h5>
            <div v-if="!isTimeUp" class="coming_box-wrapper">
              <div class="coming_box">
                <h2>{{ hours }}</h2>
                <p>HOURS</p>
              </div>
              <span>:</span>
              <div class="coming_box">
                <h2>{{ minutes }}</h2>
                <p>MINUTES</p>
              </div>
              <span>:</span>
              <div class="coming_box">
                <h2>{{ seconds }}</h2>
                <p>SECONDS</p>
              </div>
            </div>
          </div>
          <!-- <div>
            <h3 class="text-primary">Monthly PNL 32.5%</h3>
          </div> -->
        </div>
        <router-link
          to="/dashboard/withdraw/profit?tab=deposit"
          class="btn btn-success align-self-center mt-4"
        >
          Withdraw
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// modules
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

export default {
  data() {
    return {
      moment,
      baseURL: import.meta.env.VITE_BASE_API,
      balanceGroup: {},
      deposits: [],

      countdownInterval: null,
      paidTime: null,
      hours: 0,
      minutes: 0,
      seconds: 0,
      isTimeUp: false
    }
  },
  async mounted() {
    await this.ambilDataProfitGroup()

    // data deposit diambil untuk melihat maturity depositnya
    await this.ambilDataDeposits()

    this.startCountdown()
  },

  methods: {
    async ambilDataProfitGroup() {
      var res = await axios({
        method: 'get',
        url: `${this.baseURL}/balances/balance-group`,
        headers: {
          JWT: Cookies.get('jwt')
        }
      })

      this.balanceGroup = res.data.data
    },

    async ambilDataDeposits() {
      var res = await axios({
        method: 'get',
        url: `${this.baseURL}/deposits?filter_rules=[{"field":"paid","op":"boolean","value":"true"},{"field":"claimed","op":"boolean","value":"false"}]`,
        headers: {
          JWT: Cookies.get('jwt')
        }
      })

      this.deposits = res.data.data.datas
      this.paidTime = res.data.data.datas[0]?.paid_time
      console.log('paid_time', res.data.data.datas[0]?.paid_time)
    },
    startCountdown() {
      this.countdownInterval = setInterval(this.updateCountdown, 1000)
    },
    updateCountdown() {
      const currentTime = new Date().getTime()
      const timeRemaining = new Date(this.paidTime).getTime() + 48 * 3600 * 1000 - currentTime

      this.hours = Math.floor(timeRemaining / (1000 * 60 * 60))
      this.minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)

      if (timeRemaining <= 0) {
        clearInterval(this.countdownInterval)
        this.hours = this.minutes = this.seconds = 0
        this.isTimeUp = true
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.countdownInterval)
  }
}
</script>

<style scoped>
h3 {
  font-size: 16px;
}

.coming_box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.coming_box-wrapper span {
  font-size: 24px;
  font-weight: bold;
}
.coming_box {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.coming_box h2 {
  font-size: 20px;
  font-weight: bold;
  color: #0d6efd;
}
.coming_box p {
  font-size: 12px;
  margin: 0;
}
</style>
