<template>
  <li class="card padding-right">
    <div :class="{ bold: isFolder }" @click="toggle" @dblclick="changeType">
      <div class="d-flex align-items-center py-1">
        <div class="flex-shrink-0 me-3 avatar-cover">
          <img :src="model.avatar" alt="avatar" class="rounded-circle img-thumbnails" />
        </div>
        <div>{{ model.name }}</div>
        <span
          :class="isOpen ? 'text-danger' : 'text-primary'"
          class="font-size-20 fw-semibold ms-2 opacity-75"
          v-if="isFolder"
          >[{{ isOpen ? '-' : '+' }}]</span
        >
      </div>
    </div>
    <ul v-show="isOpen" v-if="isFolder">
      <TreeItem
        class="item"
        v-for="childModel in model.children"
        :key="childModel"
        :model="childModel"
      >
      </TreeItem>
      <!-- <li class="add" @click="addChild">+</li> -->
    </ul>
  </li>
</template>

<script>
// import { ref, computed } from 'vue'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isOpen: false
    }
  },

  computed: {
    isFolder() {
      return this.model.children && this.model.children.length
    }
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },

    changeType() {
      if (!this.isFolder) {
        const updatedModel = { ...this.model, children: [] }
        this.addChild(updatedModel)
        this.$emit('update:model', updatedModel)
        this.isOpen = true
      }
    },

    addChild(updatedModel) {
      updatedModel.children.push({ name: 'new stuff' })
    }
  }
}
</script>

<style scoped>
.avatar-cover {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 3rem;
  position: relative;
  outline: 2px solid #d4dde9b9;
  overflow: hidden;
}
.img-thumbnails {
  width: 100%;
  height: 100%;
}
.bordering {
  border: 2px solid #d2d2d2;
  border-radius: 1rem;
  padding: 0.4rem;
}
.card {
  padding: 0.4rem;
  border-width: 2px;
}
.padding-right {
  padding-right: 2rem;
}
ul li {
  list-style: none;
  margin: 0.5rem 0;
  cursor: default;
}
</style>
