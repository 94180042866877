// store.js
import { defineStore } from 'pinia'
import Cookies from 'js-cookie'

export const useSessionStore = defineStore('session', {
  state: () => ({
    isAuthenticated: false,
    lastActiveTime: null,
    sessionIntervalId: null,
    isSessionTimeEnd: false
  }),
  actions: {
    authenticate() {
      this.isAuthenticated = true
      this.isSessionTimeEnd = false
      this.lastActiveTime = new Date()
      this.checkSessionTimeout()
    },
    showAlert() {
      this.isAuthenticated = false
      this.lastActiveTime = null
      this.isSessionTimeEnd = true
      this.clearSessionInterval()
    },
    removeCookies() {
      Cookies.remove('jwt')
      Cookies.remove('usid')
    },
    logout() {
      this.removeCookies()
      window.location.href = '/auth/login'
    },
    checkSessionTimeout() {
      const sessionTimeout = 5 * 60 * 1000

      if (this.isAuthenticated && !this.sessionIntervalId) {
        console.log('start timeout')
        this.sessionIntervalId = setInterval(() => {
          const currentTime = new Date()
          const elapsedTime = currentTime - this.lastActiveTime

          if (elapsedTime >= sessionTimeout) {
            this.showAlert()
          }
        }, 1000)
      }
    },
    removeAuticaticated() {
      this.isAuthenticated = false
      this.lastActiveTime = null
      this.clearSessionInterval()
    },
    clearSessionInterval() {
      // clear the interval if it exists
      if (this.sessionIntervalId) {
        console.log('clear interval')
        clearInterval(this.sessionIntervalId)
        this.sessionIntervalId = null
      }
    }
  }
})
