<template>
  <div id="layout-wrapper">
    <NavBar />
    <SideBar />

    <div class="main-content">
      <div class="page-content">
        <BreadcrumbsBarVue />
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="crypto-buy-sell-nav">
                    <ul class="nav nav-tabs nav-tabs-custom" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: activeTab === 'subscription' }"
                          @click.prevent="activeTab = 'subscription'"
                          role="tab"
                        >
                          Subscription Bonus
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: activeTab === 'sales' }"
                          @click.prevent="activeTab = 'sales'"
                          role="tab"
                        >
                          Sales Income
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: activeTab === 'withdraw' }"
                          @click.prevent="activeTab = 'withdraw'"
                          role="tab"
                        >
                          Withdraw Income
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: activeTab === 'trading' }"
                          @click.prevent="activeTab = 'trading'"
                          role="tab"
                        >
                          Trading Income
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: activeTab === 'deposit' }"
                          @click.prevent="activeTab = 'deposit'"
                          role="tab"
                        >
                          Capital
                        </a>
                      </li>
                    </ul>

                    <div class="tab-content crypto-buy-sell-nav-content p-4">
                      <div
                        class="tab-pane"
                        :class="{ active: activeTab === 'subscription' }"
                        id="subscription"
                        role="tabpanel"
                      >
                        <SubscriptionSection />
                      </div>
                      <div
                        class="tab-pane"
                        :class="{ active: activeTab === 'sales' }"
                        id="sales"
                        role="tabpanel"
                      >
                        <SalesSection />
                      </div>
                      <div
                        class="tab-pane"
                        :class="{ active: activeTab === 'withdraw' }"
                        id="withdraw"
                        role="tabpanel"
                      >
                        <WithdrawSection />
                      </div>
                      <div
                        class="tab-pane"
                        :class="{ active: activeTab === 'trading' }"
                        id="trading"
                        role="tabpanel"
                      >
                        <TradingSection />
                      </div>
                      <div
                        class="tab-pane"
                        :class="{ active: activeTab === 'deposit' }"
                        id="deposit"
                        role="tabpanel"
                      >
                        <DepositSection />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>

        <RecentTransaction />
        <FooterBar />
      </div>
    </div>
  </div>

  <div class="rightbar-overlay"></div>

  <ModalSession :show="isShowModalSession" @close="isShowModalSession = false"></ModalSession>
</template>

<script>
// sotore
import { useWalletHistory } from '@/stores/wallet-store'
// components
import SideBar from '../../../components/dashboard/global/SideBar.vue'
import NavBar from '../../../components/dashboard/global/NavBar.vue'
import ModalSession from '../../../components/modals/ModalSession.vue'
import SubscriptionSection from '@/components/dashboard/wallet/withdraw/SubscriptionSection.vue'
import SalesSection from '@/components/dashboard/wallet/withdraw/SalesSection.vue'
import WithdrawSection from '@/components/dashboard/wallet/withdraw/WithdrawSection.vue'
import TradingSection from '@/components/dashboard/wallet/withdraw/TradingSection.vue'
import DepositSection from '@/components/dashboard/wallet/withdraw/DepositSection.vue'
import FooterBar from '../../../components/dashboard/global/FooterBar.vue'
import RecentTransaction from '../../../components/dashboard/wallet/RecentTransaction.vue'
import BreadcrumbsBarVue from '../../../components/dashboard/global/BreadcrumbsBar.vue'

export default {
  components: {
    BreadcrumbsBarVue,
    SideBar,
    NavBar,
    ModalSession,
    SubscriptionSection,
    SalesSection,
    WithdrawSection,
    TradingSection,
    DepositSection,
    RecentTransaction,
    FooterBar
  },
  data() {
    return {
      store: useWalletHistory(),
      activeTab: this.$route.query.tab,
      baseURL: import.meta.env.VITE_BASE_API
    }
  },
  mounted() {
    this.store.getMyWallet()
  }
}
</script>

<style scoped>
.nav-link {
  cursor: pointer;
}
</style>
