<template>
  <!-- topnav terbaru -->
  <nav
    :class="{ isScrolled: isScrolled }"
    id="themesBg"
    class="navbar navbar-expand-lg navigation fixed-top sticky"
  >
    <div class="container">
      <router-link to="/" class="navbar-logo">
        <img src="../../assets/images/logo-dark.png" alt="" height="19" class="logo logo-dark" />
        <img src="../../assets/images/logo-light.png" alt="" height="19" class="logo logo-light" />
      </router-link>

      <button
        @click="toglerMenu"
        type="button"
        class="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
        data-bs-toggle="collapse"
        data-bs-target="#topnav-menu-content"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20 17.5C20.3852 17.5002 20.7556 17.6486 21.0344 17.9144C21.3132 18.1802 21.479 18.5431 21.4975 18.9279C21.516 19.3127 21.3858 19.6898 21.1338 19.9812C20.8818 20.2726 20.5274 20.4558 20.144 20.493L20 20.5H4C3.61478 20.4998 3.24441 20.3514 2.96561 20.0856C2.68682 19.8198 2.52099 19.4569 2.50248 19.0721C2.48396 18.6873 2.61419 18.3102 2.86618 18.0188C3.11816 17.7274 3.47258 17.5442 3.856 17.507L4 17.5H20ZM20 10.5C20.3978 10.5 20.7794 10.658 21.0607 10.9393C21.342 11.2206 21.5 11.6022 21.5 12C21.5 12.3978 21.342 12.7794 21.0607 13.0607C20.7794 13.342 20.3978 13.5 20 13.5H4C3.60218 13.5 3.22064 13.342 2.93934 13.0607C2.65804 12.7794 2.5 12.3978 2.5 12C2.5 11.6022 2.65804 11.2206 2.93934 10.9393C3.22064 10.658 3.60218 10.5 4 10.5H20ZM20 3.5C20.3978 3.5 20.7794 3.65804 21.0607 3.93934C21.342 4.22064 21.5 4.60218 21.5 5C21.5 5.39782 21.342 5.77936 21.0607 6.06066C20.7794 6.34196 20.3978 6.5 20 6.5H4C3.60218 6.5 3.22064 6.34196 2.93934 6.06066C2.65804 5.77936 2.5 5.39782 2.5 5C2.5 4.60218 2.65804 4.22064 2.93934 3.93934C3.22064 3.65804 3.60218 3.5 4 3.5H20Z"
            fill="black"
            class="hum_menu"
          />
        </svg>
      </button>

      <div class="collapse navbar-collapse" id="topnav-menu-content">
        <ul class="navbar-nav ms-auto" id="topnav-menu">
          <li class="nav-item">
            <a :class="{ linkSrolled: isScrolled }" class="nav-link active" href="#home">Home</a>
          </li>
          <li class="nav-item">
            <a :class="{ linkSrolled: isScrolled }" class="nav-link" href="#about">About</a>
          </li>
          <li class="nav-item">
            <a :class="{ linkSrolled: isScrolled }" class="nav-link" href="#features">Features</a>
          </li>
          <li class="nav-item">
            <a :class="{ linkSrolled: isScrolled }" class="nav-link" href="#roadmap">Roadmap</a>
          </li>
          <li class="nav-item">
            <a :class="{ linkSrolled: isScrolled }" class="nav-link" href="#news">News</a>
          </li>
        </ul>

        <div class="my-2 ms-lg-2">
          <div v-if="isLogin" class="dropdown d-inline-block">
            <button
              type="button"
              class="btn header-item waves-effect"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                class="rounded-circle header-profile-user"
                :src="profile.orang?.orang_avatar_file_url"
                alt="Header Avatar"
              />
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <router-link to="/dashboard" class="dropdown-item"
                ><i class="mdi mdi-view-dashboard font-size-16 align-middle me-1"></i>

                <span key="t-dashboard">Dashboard</span></router-link
              >
              <router-link to="/dashboard/profile" class="dropdown-item"
                ><i class="bx bx-user font-size-16 align-middle me-1"></i>
                <span key="t-my-profile">My Profile</span></router-link
              >
              <!-- <router-link to="#" class="dropdown-item d-block"
                ><span class="badge bg-success float-end">11</span
                ><i class="bx bx-wrench font-size-16 align-middle me-1"></i>
                <span key="t-settings">Settings</span></router-link
              >
              <a class="dropdown-item" href="#"
                ><i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
                <span key="t-lock-screen">Lock screen</span></a
              > -->
              <div class="dropdown-divider"></div>
              <div @click="logoutUser" class="dropdown-item text-danger cursor_pointer">
                <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                <span key="t-logout">Logout</span>
              </div>
            </div>
          </div>
          <router-link v-else to="/auth/login" class="btn btn-success w-xs">Sign in</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// modules
import Cookies from 'js-cookie'

// stores
import { useProfile } from '@/stores/profile-store'
import { computed } from 'vue'

export default {
  data() {
    return {
      store: useProfile(),
      profile: computed(() => this.store.profiles),
      isScrolled: false,
      isLogin: false,
      isHover: false
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.getProfileData()
    this.checkSession()
  },
  methods: {
    handleScroll() {
      const sy = window.scrollY
      const theme = Cookies.get('theme')
      const themesBgElement = document.getElementById('themesBg')

      if (themesBgElement) {
        if (sy > 60) {
          this.isScrolled = true

          if (theme) {
            themesBgElement.style.backgroundColor = theme === 'dark' ? '#2a3042' : '#ffff'
          }
        } else {
          this.isScrolled = false
          themesBgElement.style.backgroundColor = 'transparent'
        }
      }
    },

    handleMenuAvatar() {
      this.isHover = true
    },

    handleLogout() {
      Cookies.remove('jwt')
      Cookies.remove('usid')
      this.$router.go()
    },

    async getProfileData() {
      await this.store.getProfile()
    },

    checkSession() {
      const login = Cookies.get('jwt')
      this.isLogin = login ? true : false
    },

    logoutUser() {
      Cookies.remove('jwt')
      Cookies.remove('usid')
      this.$router.go('/')
    },

    toglerMenu() {
      this.isScrolled = !this.isScrolled
      const getId = document.getElementById('themesBg')
      const theme = Cookies.get('theme')
      if (this.isScrolled) {
        if (theme) {
          if (theme === 'dark') {
            getId.style.backgroundColor = '#2a3042'
          } else {
            getId.style.backgroundColor = '#ffff'
          }
        }
      } else {
        getId.style.background = 'none'
      }
    }
  }
}
</script>

<style scoped>
.isScrolled {
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.linkSrolled {
  color: #555b6d !important;
}
.linkSrolled:hover {
  color: aliceblue !important;
}
</style>
