<template>
  <div>
    <table>
      <tr>
        <td>
          ID
        </td>
        <td>
          Start
        </td>
        <td>
          End
        </td>
        <td>
          Action
        </td>
      </tr>
    </table>
  </div>
</template>

