<!-- NotFound.vue -->
<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <span class="mdi mdi-account-hard-hat"></span>

            <h4 class="text-uppercase">Under Development</h4>
            <div class="mt-5 text-center">
              <a @click="goBack" class="btn btn-primary waves-effect waves-light">Back</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.back(-1)
    }
  }
}
</script>

<style scoped>
.mdi-account-hard-hat {
  font-size: 10rem;
}
</style>
