<template>
  <main>
    <TopNav />
    <HeroSection />
    <AboutSection />
    <FeatureSection />
    <RoadmapSection />
    <BlogSection />
    <FaqSection />
    <FooterSecondary />
  </main>

  <Teleport to="body">
    <modal-reusable :show="showModalMsg" @close="showModalMsg = false">
      <template #body>
        <p>{{ responseMsg }}</p>
      </template>

      <template #footer>
        <button @click="showModalMsg = false" class="btn btn-primary p-1">OK</button>
      </template>
    </modal-reusable>
  </Teleport>
</template>

<script>
// componets
import TopNav from '../../components/global/TopNav.vue'
import HeroSection from '../../components/home/HeroSection.vue'
import AboutSection from '../../components/home/AboutSection.vue'
import FeatureSection from '../../components/home/FeatureSection.vue'
import RoadmapSection from '../../components/home/RoadmapSection.vue'
import BlogSection from '../../components/home/BlogSection.vue'
import FaqSection from '../../components/home/FaqSection.vue'
import FooterSecondary from '../../components/global/FooterSecondary.vue'
import ModalReusable from '@/components/modals/ModalReusable.vue'

//service
import service from '@/utils/service'

export default {
  components: {
    TopNav,
    HeroSection,
    AboutSection,
    FeatureSection,
    RoadmapSection,
    BlogSection,
    FaqSection,
    FooterSecondary,
    ModalReusable
  },
  data() {
    return {
      showModal: true,
      refCode: this.$route.query.ref,
      showModalMsg: false,
      responseMsg: ''
    }
  },
  mounted() {
    // jika tidak undefined, maka diarahkan ke halaman register
    if (this.refCode != undefined) {
      this.$router.push(`/auth/register?ref=${this.refCode}`)
    }

    this.getMsg()
  },
  methods: {
    getMsg() {
      service
        .get('/home/msg-public')
        .then((response) => {
          if (response.success) {
            if (response.data) {
              this.responseMsg = response.data
              this.showModalMsg = true
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }
}
</script>

<style scoped>
p {
  font-weight: 600;
  font-size: 18px;
}
</style>
