<template>
  <!-- Faqs start -->
  <section class="section" id="faqs">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <div class="small-title">FAQs</div>
            <h4>Frequently Asked Questions</h4>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">
        <div class="col-lg-12">
          <div class="vertical-nav">
            <div class="row">
              <div class="col-lg-2 col-sm-4">
                <div class="nav flex-column nav-pills" role="tablist">
                  <a
                    class="nav-link active"
                    id="v-pills-gen-ques-tab"
                    data-bs-toggle="pill"
                    href="#v-pills-gen-ques"
                    role="tab"
                  >
                    <i class="bx bx-help-circle nav-icon d-block mb-2"></i>
                    <p class="fw-bold mb-0">General Questions</p>
                  </a>
                  <!-- <a
                    class="nav-link"
                    id="v-pills-token-sale-tab"
                    data-bs-toggle="pill"
                    href="#v-pills-token-sale"
                    role="tab"
                  >
                    <i class="bx bx-receipt nav-icon d-block mb-2"></i>
                    <p class="fw-bold mb-0">Pre-Register</p>
                  </a> -->
                  <a
                    class="nav-link"
                    id="v-pills-roadmap-tab"
                    data-bs-toggle="pill"
                    href="#v-pills-roadmap"
                    role="tab"
                  >
                    <i class="bx bx-timer d-block nav-icon mb-2"></i>
                    <p class="fw-bold mb-0">Roadmap</p>
                  </a>
                </div>
              </div>
              <div class="col-lg-10 col-sm-8">
                <div class="card">
                  <div class="card-body">
                    <div class="tab-content">
                      <div class="tab-pane fade show active" id="v-pills-gen-ques" role="tabpanel">
                        <h4 class="card-title mb-4">General Questions</h4>

                        <div>
                          <div id="gen-ques-accordion" class="accordion custom-accordion">
                            <div class="mb-3">
                              <a
                                href="#general-collapseOne"
                                class="accordion-list"
                                data-bs-toggle="collapse"
                                aria-expanded="true"
                                aria-controls="general-collapseOne"
                              >
                                <div>How do I get started with wavedex?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>

                              <div
                                id="general-collapseOne"
                                class="collapse show"
                                data-bs-parent="#gen-ques-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    To get started, sign up for an account on our platform, complete
                                    the necessary verification procedures, and deposit funds into
                                    your account. Once your account is funded, you can start with
                                    the subscription and begin the trading.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#general-collapseTwo"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="general-collapseTwo"
                              >
                                <div>What cryptocurrencies is supported on wavedex?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="general-collapseTwo"
                                class="collapse"
                                data-bs-parent="#gen-ques-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    As for now, the fund is only BASED on USDT. The trading bot will
                                    trade, manage and execute the buy and sell on behalf of the
                                    client in USDT pair. So client will only hold USDT in their
                                    wallet.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#general-collapseThree"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="general-collapseThree"
                              >
                                <div>How can I deposit funds into my account?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="general-collapseThree"
                                class="collapse"
                                data-bs-parent="#gen-ques-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    You can deposit funds into your account using USDT (TRC-20) &
                                    USDT (BEP-20). Check your deposit tab for more details.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#general-collapseFour"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="general-collapseFour"
                              >
                                <div>
                                  What security measures are in place to protect my investments?
                                </div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="general-collapseFour"
                                class="collapse"
                                data-bs-parent="#gen-ques-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    We prioritize security. Our platform employs industry-standard
                                    security protocols, including encryption, two-factor
                                    authentication (2FA), and cold storage for digital assets. We
                                    also conduct regular security audits to ensure your funds are
                                    safe.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#general-collapseFive"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="general-collapseFive"
                              >
                                <div>What fees are associated with using the platform?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="general-collapseFive"
                                class="collapse"
                                data-bs-parent="#gen-ques-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    Fees may include trading fees, withdrawal fees, and other
                                    charges. You can find detailed information about our fee
                                    structure on our website.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#general-collapseSix"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="general-collapseSix"
                              >
                                <div>Can I hold my funds long-term on the platform?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="general-collapseSix"
                                class="collapse"
                                data-bs-parent="#gen-ques-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    Yes, you can hold your funds in your account for the long term.
                                    The bot will only execute short term trading on the pool.
                                    Investor’s profits are on another wallet for capital management
                                    purposes. Many investors use our platform for both short-term
                                    trading and long-term investment.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#general-collapseSeven"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="general-collapseSeven"
                              >
                                <div>Are my investments insured?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="general-collapseSeven"
                                class="collapse"
                                data-bs-parent="#gen-ques-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    While cryptocurrencies are not typically insured like
                                    traditional bank deposits, we take extensive security measures
                                    to protect your assets. We recommend you also use personal
                                    wallets for added security.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div>
                              <a
                                href="#general-collapseEight"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="general-collapseEight"
                              >
                                <div>Can I transfer USDT to external wallets?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="general-collapseEight"
                                class="collapse"
                                data-bs-parent="#gen-ques-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    Yes, you can transfer your USDT to external wallets. We
                                    encourage users to store a portion of their assets in secure
                                    personal wallets for added security.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="tab-pane fade" id="v-pills-token-sale" role="tabpanel">
                        <h4 class="card-title mb-4">What is Pre-register</h4>

                        <div>
                          <div id="token-accordion" class="accordion custom-accordion">
                            <div class="mb-3">
                              <a
                                href="#token-collapseOne"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="token-collapseOne"
                              >
                                <div>What is the prelaunch promotion for Wavedex platform?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="token-collapseOne"
                                class="collapse"
                                data-bs-parent="#token-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    The prelaunch promotion for wavedex is a limited-time
                                    opportunity for investors to receive bonus rewards based on
                                    their investments made between October 28th and November 30th,
                                    2023, before the platform's official dashboard launch.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#token-collapseTwo"
                                class="accordion-list"
                                data-bs-toggle="collapse"
                                aria-expanded="true"
                                aria-controls="token-collapseTwo"
                              >
                                <div>When does the prelaunch promotion period start and end?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>

                              <div
                                id="token-collapseTwo"
                                class="collapse show"
                                data-bs-parent="#token-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    The prelaunch promotion period begins on October 28th, 2023, and
                                    ends on November 30th, 2023. It's important to make your
                                    investments during this period to be eligible for the bonuses.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#token-collapseThree"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="token-collapseThree"
                              >
                                <div>
                                  What is the minimum investment amount required to participate in
                                  the promotion?
                                </div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="token-collapseThree"
                                class="collapse"
                                data-bs-parent="#token-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    The minimum investment amount to participate in the promotion is
                                    $500.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#token-collapseFour"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="token-collapseFour"
                              >
                                <div>
                                  Are the bonus rewards guaranteed for all eligible investments
                                  during the promotion period?
                                </div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="token-collapseFour"
                                class="collapse"
                                data-bs-parent="#token-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    Yes, the bonus rewards are guaranteed for all eligible
                                    investments made within the specified investment ranges during
                                    the prelaunch promotion period.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#token-collapseFive"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="token-collapseFive"
                              >
                                <div>
                                  What happens once the platform's dashboard is officially launched?
                                </div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="token-collapseFive"
                                class="collapse"
                                data-bs-parent="#token-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    Once the dashboard is officially launched, the prelaunch
                                    promotion will no longer be valid, and there will be no bonus
                                    rewards for investors. Therefore, it's essential to take
                                    advantage of this limited-time offer before the launch to secure
                                    your bonus.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#token-collapseSix"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="token-collapseSix"
                              >
                                <div>How can I participate in the prelaunch promotion?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="token-collapseSix"
                                class="collapse"
                                data-bs-parent="#token-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    To participate, you need to invest a minimum of $500 during the
                                    promotion period. Simply visit our platform and follow the
                                    instructions provided to make your investment.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#token-collapseSeven"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="token-collapseSeven"
                              >
                                <div>
                                  Can I combine multiple investments to reach the bonus thresholds?
                                </div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="token-collapseSeven"
                                class="collapse"
                                data-bs-parent="#token-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    No, the bonus rewards are determined based on individual
                                    investments within the specified ranges. Combining multiple
                                    investments to reach the bonus threshold is not allowed.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#token-collapseEight"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="token-collapseEight"
                              >
                                <div>
                                  Are there any specific terms and conditions for the prelaunch
                                  promotion that I should be aware of?
                                </div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="token-collapseEight"
                                class="collapse"
                                data-bs-parent="#token-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    Yes, please review the terms and conditions provided on our
                                    platform for complete details about the promotion, including
                                    eligibility, investment rules, and other important information.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#token-collapseNine"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="token-collapseNine"
                              >
                                <div>
                                  Where can I get more information or assistance regarding the
                                  prelaunch promotion?
                                </div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="token-collapseNine"
                                class="collapse"
                                data-bs-parent="#token-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    If you have additional questions or need assistance, please feel
                                    free to contact our customer support team. They will be happy to
                                    help you with any inquiries related to the prelaunch promotion.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <div class="tab-pane fade" id="v-pills-roadmap" role="tabpanel">
                        <h4 class="card-title mb-4">Features</h4>

                        <div>
                          <div id="roadmap-accordion" class="accordion custom-accordion">
                            <div class="mb-3">
                              <a
                                href="#roadmap-collapseOne"
                                class="accordion-list"
                                data-bs-toggle="collapse"
                                aria-expanded="true"
                                aria-controls="roadmap-collapseOne"
                              >
                                <div>What is Pool Trade?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>

                              <div
                                id="roadmap-collapseOne"
                                class="collapse show"
                                data-bs-parent="#roadmap-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    Pool trade is where community put a fund into a account to
                                    trade. Bots can implement complex trading strategies that would
                                    be difficult to execute manually. They can analyze vast amounts
                                    of data and respond to market changes quickly.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#roadmap-collapseTwo"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="roadmap-collapseTwo"
                              >
                                <div>What is Arbitrage?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="roadmap-collapseTwo"
                                class="collapse"
                                data-bs-parent="#roadmap-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    Wavedex Arbitrage are automated software programs that are
                                    designed to identify and exploit price discrepancies for
                                    cryptocurrencies across different exchanges or trading pairs.
                                    Wavedex Arbitrage leverage the speed and efficiency of
                                    algorithmic trading to quickly execute trades and capitalize on
                                    temporary price imbalances in the highly volatile cryptocurrency
                                    markets.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                              <a
                                href="#roadmap-collapseThree"
                                class="accordion-list collapsed"
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                aria-controls="roadmap-collapseThree"
                              >
                                <div>What is DEX?</div>
                                <i class="mdi mdi-minus accor-plus-icon"></i>
                              </a>
                              <div
                                id="roadmap-collapseThree"
                                class="collapse"
                                data-bs-parent="#roadmap-accordion"
                              >
                                <div class="card-body">
                                  <p class="mb-0">
                                    DEX stands for "Decentralized Exchange." It is a type of
                                    cryptocurrency exchange that operates in a decentralized manner,
                                    meaning it doesn't rely on a centralized authority or
                                    intermediary to facilitate cryptocurrency trades. Instead, DEXs
                                    enable peer-to-peer trading of digital assets directly between
                                    users.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end vertical nav -->
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Faqs end -->
</template>
