<template>
  <Transition name="modal-session">
    <div v-if="show" class="modal-masks">
      <div class="modal-container">
        <div class="modal-header bg-danger">
          <!-- session timeout -->
          <h1 v-if="isShowTimeoutSession" class="font-size-20 text-white">Session Timeout</h1>

          <!-- session expired -->
          <h1 v-else class="font-size-20 text-white">Session Expired</h1>

          <button class="close-button" @click="closeHandler">
            <span class="mdi mdi-close"></span>
          </button>
        </div>

        <div class="modal-body">
          <!-- session timeout -->
          <div v-if="isShowTimeoutSession" class="text-center">
            <div class="d-flex gap-2 mt-3">
              <span class="mdi mdi-clock-time-eight-outline text-danger"></span>
              <p>Your online session will expire in</p>
            </div>
            <h1 class="fs-1 fw-light mb-4">{{ counter }} Seconds</h1>
            <p>
              Please click "Continue" to keep working, or click "Log Off" to end your session now.
            </p>
          </div>

          <!-- session expired -->
          <div v-else class="d-flex justify-content-center mt-3">
            <span class="mdi mdi-clock-time-eight-outline text-danger"></span>
            <div class="px-2">
              <p>Your session has expired.</p>
              <p>You will be redirected to the Login page.</p>
            </div>
          </div>
        </div>

        <div class="modal-footers">
          <!-- session timeout -->
          <div v-if="isShowTimeoutSession" class="d-flex justify-content-between">
            <button type="button" class="btn btn-danger px-3 py-2" @click="closeModal">
              Continue
            </button>
            <button type="button" class="btn btn-outline-danger px-3 py-2" @click="logout">
              Log Off
            </button>
          </div>

          <!-- session expired -->
          <div v-else class="d-flex justify-content-end">
            {{ store.removeCookies() }}
            <button type="button" class="btn btn-danger px-5 py-2" @click="logout">OK</button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// stores
import { useSessionStore } from '@/stores/session-store'

export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      store: useSessionStore(),
      counter: null,
      isShowTimeoutSession: true,
      isCountdownStart: false
    }
  },
  methods: {
    logout() {
      this.store.logout()
    },
    closeModal() {
      this.$emit('close')
      this.store.authenticate()
      this.store.checkSessionTimeout()
    },
    closeHandler() {
      if (this.isShowTimeoutSession) {
        this.closeModal()
      } else {
        this.logout()
      }
    },
    startCountdown() {
      this.counter = 60

      if (!this.isCountdownStart) {
        this.isCountdownStart = true

        setInterval(() => {
          if (this.counter > 1) {
            this.counter = this.counter - 1
          } else {
            this.isShowTimeoutSession = false
          }
        }, 1000)
      }
    }
  },
  updated() {
    this.startCountdown()
  }
}
</script>

<style scoped>
p {
  font-size: 17px;
  font-weight: 500;
}
h1 {
  font-size: large;
}
.close-button {
  border: none;
  padding: 0;
  background-color: transparent;
}
.mdi-close {
  font-size: 30px;
  color: white;
  transition: filter 0.1s ease;
}
.mdi-close:hover {
  filter: brightness(92%);
}
.mdi-clock-time-eight-outline {
  font-size: 40px;
  line-height: 25px;
}
.modal-masks {
  padding: 20px;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
}
.modal-container {
  width: 400px;
  padding: 0;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
.modal-header {
  display: flex;
  justify-content: space-between;
  cursor: auto;
  padding: 0.8rem 1.5rem;
}
.modal-body {
  justify-content: center;
  padding: 0.1rem 2rem;
}
.modal-footers {
  /* display: flex;
  justify-content: flex-end; */
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

/* transition */
.modal-session-enter-active,
.modal-session-leave-active {
  transition: opacity 0.2s ease;
}
.modal-session-enter,
.modal-session-leave-to {
  opacity: 0;
}
.modal-session-enter-from .modal-container,
.modal-session-leave-to .modal-container {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
</style>
