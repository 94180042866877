<template>
  <div class="row">
    <div class="col-xl-8">
      <div class="card">
        <div class="card-body">
          <div class="clearfix">
            <div class="float-end"></div>

            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4">Revenue</h4>
                <div id="revenue-chart" class="apex-charts" dir="ltr"></div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4">My Client</h4>
                <div v-if="clients?.client_obj" class="table-responsive scrollable-div">
                  <span
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <ul v-else class="min-width-full">
                    <TreeItem class="item min-width-full" :model="treeData"></TreeItem>
                  </ul>
                </div>

                <div v-else class="not_found">
                  <img src="@/assets/images/found-icon.png" alt="found" width="50" />
                  <p>No clients have been recorded</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// service
import service from '@/utils/service'

import DateFormat from '@/helper/DateFormat'

// components
import TreeItem from './TreeItem.vue'

// modules
import { computed, watch } from 'vue'

// stores
import { useProfile } from '@/stores/profile-store'

// const treeDatas = {
//   name: 'username',
//   children: [
//     { name: 'Client 1' },
//     { name: 'Client 2' },
//     {
//       name: 'Client 3',
//       children: [
//         {
//           name: 'Client 3.1',
//           children: [
//             {
//               name: 'Client 3.1.1',
//               children: [
//                 {
//                   name: 'Client 3.1',
//                   children: [{ name: 'Client 3.1.1' }, { name: 'Clinet 3.1.2' }]
//                 },
//                 { name: 'Client 3.2' },
//                 { name: 'Client 3.3' },
//                 {
//                   name: 'Client 3.4',
//                   children: [{ name: 'Client 3.4.1' }, { name: 'Client 3.4.2' }]
//                 }
//               ]
//             },
//             { name: 'Clinet 3.1.2' }
//           ]
//         },
//         { name: 'Client 3.2' },
//         { name: 'Client 3.3' },
//         {
//           name: 'Client 3.4',
//           children: [{ name: 'Client 3.4.1' }, { name: 'Client 3.4.2' }]
//         }
//       ]
//     }
//   ]
// }

export default {
  components: {
    TreeItem
  },
  data() {
    return {
      DateFormat,
      isLoading: false,
      clients: [],
      page: 1,
      treeData: { name: '', avatar: '', children: null },
      store: useProfile(),
      datas: computed(() => this.store.profiles.orang)
    }
  },
  async mounted() {
    this.getProfileData()
    watch(
      () => this.datas,
      (newVal) => {
        if (newVal) {
          this.treeData.name = newVal.orang_nickname
          this.treeData.avatar = newVal.orang_avatar_file_url
        }
      }
    )
    await this.getClient()
    this.getDownline(this.clients?.upline_refid)
  },
  methods: {
    getClient() {
      return service
        .get(`/clients?page=${this.page}`, { headers: { requiresAuth: true } })
        .then((response) => {
          this.clients = response.data.datas[0]
        })
    },
    async getDownline(refId) {
      this.isLoading = true
      let result = []

      try {
        const response = await service.get(`/clients/downline-step?refid=${refId}`, {
          headers: { requiresAuth: true }
        })

        for (let client of response) {
          let clientCopy = { name: client.nickname, avatar: client.avatar_file }
          clientCopy.children = await this.getDownline(client.refid)
          result.push(clientCopy)
        }

        this.treeData.children = result
      } finally {
        this.isLoading = false
      }

      return result
    },
    getProfileData() {
      this.store.getProfile()
    }
  }
}
</script>

<style scoped>
.not_found {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.not_found p {
  max-width: 150px;
  text-align: center;
}
.usdt_icon {
  margin-right: -5px !important;
}
.btn_act button {
  width: 80px;
}
.scrollable-div {
  overflow-x: auto;
  white-space: nowrap;
}
.scrollable-div ul {
  display: inline-flex;
  flex-direction: row;
}
.scrollable-div ul li {
  flex: 0 0 auto;
}
.min-width-full {
  min-width: 100%;
}
</style>
