<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="mt-4">
                <div>
                  <label for="formFileSm" class="form-label">KYC Verification&nbsp;</label>
                  <p>
                    To ensure the security of your account and comply with regulatory requirements,
                    we need to verify your identity. <strong>You will be done in 5mins!</strong>
                  </p>
                  <!-- <input class="form-control form-control-sm" id="formFileSm" type="file" /> -->
                </div>
                <div v-if="kycStatus" class="kyc_approved">KYC Approval Successful</div>
                <div v-else class="mt-2 kyc_btn">
                  <button>Start KYC</button>
                  <metamap-button
                    @click="sessionOff"
                    :clientid="METAMAP_CLIENT_ID"
                    :flowid="METAMAP_FLOW_ID"
                    :metadata="metadata"
                    class="metamap_btn"
                  >
                  </metamap-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { METAMAP_CLIENT_ID, METAMAP_FLOW_ID } from '../../applibs/http-common'
import { useSessionStore } from '@/stores/session-store'
import { useProfile } from '@/stores/profile-store'

export default {
  props: {
    kycStatus: Boolean
  },
  data() {
    return {
      sessionStore: useSessionStore(),
      profileStore: useProfile(),
      METAMAP_CLIENT_ID,
      METAMAP_FLOW_ID,
      metadata: '',
      intervalId: null,
      kycpanding: false
    }
  },
  mounted() {
    this.getUsid()
    this.handleMetamapRes()
  },
  methods: {
    getUsid() {
      const usid = Cookies.get('usid')
      if (usid) {
        this.metadata = `{"user_id":"${usid}"}`
      }
    },
    sessionOff() {
      this.sessionStore.removeAuticaticated()
    },
    handleMetamapRes() {
      const metamapButton = document.querySelector('metamap-button')

      if (metamapButton) {
        metamapButton.addEventListener('metamap:userFinishedSdk', ({ detail }) => {
          console.log('finished payload', detail)
          this.sessionStore.authenticate()
          this.sessionStore.checkSessionTimeout()

          // Clear existing interval, if any
          clearInterval(this.intervalId)

          // Set up a new interval
          this.intervalId = setInterval(() => {
            this.profileStore.getProfile()

            // Check if kycStatus is true, and clear the interval if true
            if (this.kycStatus) {
              clearInterval(this.intervalId)
            }
          }, 5000)
        })

        metamapButton.addEventListener('metamap:exitedSdk', ({ detail }) => {
          console.log('exited payload', detail)
          this.sessionStore.authenticate()
          this.sessionStore.checkSessionTimeout()
          this.profileStore.getProfile()
        })
      }
    }
  }
}
</script>

<style scoped>
.kyc_btn {
  position: relative;
  cursor: pointer;
}

.kyc_btn button {
  position: absolute;
  top: 35%;
  left: 8%;
  transform: translate(-35%, -8%);
  color: #fff;
  width: 120px;
  padding: 5px 0;

  background: #556ee6;
  border: none;
  border-radius: 3px;
}
.kyc_btn button:hover {
  background: #445bcc;
}
.metamap_btn {
  opacity: 0;
  width: 120px;
}
.kyc_approved {
  width: fit-content;
  padding: 5px 20px;
  border: 1px solid green;
  border-radius: 5px;
  color: green;
}
</style>
