<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <!-- breadcrumbs -->
        <BreadcrumbsBar />
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-lg-4">
              <FinancialPanel />
            </div>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-12">
                  <IncomePanel />
                </div>
                <div class="col-lg-12">
                  <TradeTable />
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer-bar />
      </div>
    </div>
  </div>
</template>

<script>
// components
import FinancialPanel from '../home/FinancialPanel.vue'
import IncomePanel from '../home/IncomePanel.vue'
import TradeTable from '../home/TradeTable.vue'
import BreadcrumbsBar from '@/components/dashboard/global/BreadcrumbsBar.vue'
import FooterBar from '../global/FooterBar.vue'

export default {
  components: {
    FinancialPanel,
    IncomePanel,
    TradeTable,
    BreadcrumbsBar,
    FooterBar
  },
  data() {
    return {
      dateTime: '2023'
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year
  }
}
</script>

<style scoped>
@media only screen and (max-width: 500px) {
  .page-content {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
