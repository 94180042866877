<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-primary-subtle">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Lock screen</h5>
                    <p>Enter your password to unlock the screen!</p>
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img src="../../assets/images/profile-img.png" alt="" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <a href="index.html">
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <img
                        src="../../assets/images/logo.svg"
                        alt=""
                        class="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </a>
              </div>
              <div class="p-2">
                <form action="index.html" @submit.prevent="submitForm">
                  <div class="user-thumb text-center mb-4">
                    <img
                      src="../../assets/images/users/avatar-1.jpg"
                      class="rounded-circle img-thumbnail avatar-md"
                      alt="thumbnail"
                    />
                    <h5 class="font-size-15 mt-3">Maria Laird</h5>
                  </div>

                  <div class="mb-3">
                    <label for="userpassword">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="userpassword"
                      placeholder="Enter password"
                      v-model="postData.password"
                      @blur="v$.postData.password.$touch"
                    />
                    <div v-if="v$.postData.password.$error" style="color: red">
                      <p v-if="v$.postData.password.required.$invalid">* Password is required</p>
                    </div>
                  </div>

                  <div class="text-end">
                    <button class="btn btn-primary w-md waves-effect waves-light" type="submit">
                      Unlock
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p>
              Not you ? return
              <router-link to="/auth/login" class="fw-medium text-primary"> Sign In </router-link>
            </p>
            <p>© {{ dateTime }} Wavedex.io</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from '@/utils/service'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      dateTime: '2023',
      postData: {
        password: ''
      }
    }
  },
  validations() {
    return {
      postData: {
        password: { required }
      }
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year
  },
  methods: {
    submitForm() {
      this.v$.postData.$touch()

      if (!this.v$.$invalid) {
        service
          .post('', this.postData)
          .then((response) => {})
          .catch((error) => {
            console.error('Error:', error)
          })
      }
    }
  }
}
</script>
