<template>
  <div class="package-container">
    <div class="package-header" :style="`background-color: ${subsPackage.mainColor}`">
      <h1>{{ subsPackage.title }}</h1>
      <h3>{{ subsPackage.childTitle }}</h3>
    </div>
    <div>
      <div>
        <div class="package-price">
          <h2>USDT</h2>
          <h1>{{ subsPackage.price }}<sup>.00</sup></h1>
        </div>
        <div class="package-time" :style="`background-color: ${subsPackage.secondColor}`">
          {{ subsPackage.time.toUpperCase() }}
        </div>
      </div>
      <div class="package-list" :style="`color: ${subsPackage.thirdColor}`">
        <ul>
          <li>
            <span v-if="subsPackage.lists[0]" class="mdi mdi-check-circle-outline"></span>
            <span v-else class="mdi mdi-close-circle-outline"></span>
            <h3>{{ subsPackage.time }} Subscription</h3>
          </li>
          <li>
            <span v-if="subsPackage.lists[1]" class="mdi mdi-check-circle-outline"></span>
            <span v-else class="mdi mdi-close-circle-outline"></span>
            <h3>Full Features</h3>
          </li>
          <li>
            <span v-if="subsPackage.lists[2]" class="mdi mdi-check-circle-outline"></span>
            <span v-else class="mdi mdi-close-circle-outline"></span>
            <h3>Maximum Deposit</h3>
          </li>
          <li>
            <span v-if="subsPackage.lists[3]" class="mdi mdi-check-circle-outline"></span>
            <span v-else class="mdi mdi-close-circle-outline"></span>
            <h3>Save Money</h3>
          </li>
        </ul>
      </div>
    </div>
    <div class="package-footer">
      <button
        class="button"
        @click="subsPackage.selectPackageHandler"
        :style="`background-color: ${subsPackage.mainColor}`"
      >
        Subscribe
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subsPackage: Object
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 25px;
  letter-spacing: 5px;
}
h2 {
  font-size: 20px;
  text-align: left;
}
h3 {
  font-size: 15px;
  font-weight: 400;
}
sup {
  font-size: 20px;
  vertical-align: super;
}
li {
  padding: 1rem 0;
  border-bottom: solid rgb(223, 223, 223) 0.1px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  gap: 1rem;
}
li h3 {
  color: black;
}
span {
  line-height: 0;
  font-size: 35px;
}
.package-container {
  max-width: 18rem;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.package-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  gap: 0.7rem;
  color: white;
}
.package-price {
  padding: 1rem 2rem;
}
.package-price h1 {
  font-size: 60px;
}
.package-time {
  color: white;
  letter-spacing: 3px;
  padding: 0.3rem 0;
}
.package-list {
  margin: 2rem 0;
}
.package-footer {
  margin-bottom: 2rem;
}
.button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  color: white;
}
.button:hover {
  filter: brightness(97%);
}
.button:active {
  filter: brightness(93%);
}
</style>
