<template>
  <!-- Features start -->
  <section class="section" id="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <div class="small-title">Features</div>
            <h4>Our Product Features</h4>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row align-items-center pt-4">
        <div class="col-md-6 col-sm-8">
          <div>
            <img
              src="../../assets/images/crypto/features-img/img-1.png"
              alt=""
              class="img-fluid mx-auto d-block"
            />
          </div>
        </div>
        <div class="col-md-5 ms-auto">
          <div class="mt-4 mt-md-auto">
            <div class="d-flex align-items-center mb-2">
              <div class="features-number fw-semibold display-4 me-3">01</div>
              <h4 class="mb-0">Automation and Consistency</h4>
            </div>
            <p class="text-muted">
              Trading bots can execute trades automatically based on pre-defined criteria, ensuring
              that trades are executed without emotions, 24/7. Emotions like fear and greed can lead
              to impulsive decisions in manual trading, which bots avoid.
            </p>
            <div class="text-muted mt-4">
              <p class="mb-2">
                <i class="mdi mdi-circle-medium text-success me-1"></i>Fully Automated Trade
              </p>
              <p class="mb-2">
                <i class="mdi mdi-circle-medium text-success me-1"></i>Speed & Efficiency
              </p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Reliability</p>
              <p class="mb-2">
                <i class="mdi mdi-circle-medium text-success me-1"></i>Minimal Supervision
              </p>
              <p class="mb-2">
                <i class="mdi mdi-circle-medium text-success me-1"></i>Emotion Free Trading
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row align-items-center mt-5 pt-md-5">
        <div class="col-md-5">
          <div class="mt-4 mt-md-0">
            <div class="d-flex align-items-center mb-2">
              <div class="features-number fw-semibold display-4 me-3">02</div>
              <h4 class="mb-0">User-Friendly UI</h4>
            </div>
            <p class="text-muted">
              User-friendly, minimalist UI design ensures that it is easy for everyone to use and
              Just push a button to start and stop trade in apps, make it trading is easy and fun to
              use.
            </p>
            <div class="text-muted mt-4">
              <p class="mb-2">
                <i class="mdi mdi-circle-medium text-success me-1"></i>Minimalist UI
              </p>
              <p class="mb-2">
                <i class="mdi mdi-circle-medium text-success me-1"></i>Can turn to Light Mode & Dark
                Mode
              </p>
              <p class="mb-2">
                <i class="mdi mdi-circle-medium text-success me-1"></i>Easy button to trade
              </p>
              <p class="mb-2">
                <i class="mdi mdi-circle-medium text-success me-1"></i>No need skills to trade
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-8 ms-md-auto">
          <div class="mt-4 me-md-0">
            <img
              src="../../assets/images/crypto/features-img/img-2.png"
              alt=""
              class="img-fluid mx-auto d-block"
            />
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Features end -->
</template>
