<template>
  <!-- Blog start -->
  <section class="section bg-white" id="news">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <div class="small-title">Blog</div>
            <h4>Latest News</h4>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">
        <div v-for="news in latestNews" :key="news.tokenId" class="col-lg-3 mb-4">
          <a :href="news.articleURI" target="_blank" class="card">
            <img :src="news.image" alt="" class="card-img-top" />
            <div class="card-body">
              <h2 class="card-title">{{ getTitle(news.articleURI) }}</h2>
            </div>
          </a>
        </div>

        <div class="latest_news">
          <a href="https://cointelegraph.com/tags/blockchain" target="_blank"
            ><span>Latest News on CoinTelegraph</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M0.0799823 11.08L0.0799825 5.07999L8.07998 5.07999L8.07998 0.23999L15.92 8.07999L8.07998 15.92L8.07998 11.08L0.0799823 11.08Z"
                fill="aliceblue"
              /></svg
          ></a>
        </div>

        <!-- end row -->
      </div>
    </div>
    <!-- end container -->
  </section>
  <!-- Blog end -->
</template>

<script>
// applibs
import { HTTP } from '@/applibs/http-common'

export default {
  data() {
    return {
      latestNews: []
    }
  },
  async mounted() {
    await this.getNews()
  },
  methods: {
    async getNews() {
      const res = await HTTP.get(
        'https://widgets.mnt.md/recently-collected?slug=/recently-collected?target=https://mint.cointelegraph.com&slug=blockchain&target=https://mint.cointelegraph.com/'
      )
      this.latestNews = res.data.slice(0, 8)
    },
    getTitle(url) {
      let modifiedText = url.replace('https://cointelegraph.com/news/', '').replace(/-/g, ' ')
      modifiedText = modifiedText.charAt(0).toUpperCase() + modifiedText.slice(1)
      return modifiedText
    }
  }
}
</script>

<style scoped>
.card-title {
  min-height: 80px;
  font-size: 20px;
  font-weight: 400;
}
.latest_news {
  display: flex;
  justify-content: center;
}
.latest_news a {
  color: aliceblue;
  padding: 4px 10px;
  border-radius: 5px;
  background-color: #705bf5;
}
.latest_news span {
  margin-right: 5px;
}
.latest_news a:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 500px) {
  .card-title {
    font-size: 14px;
    min-height: auto;
  }
  .latest_news a {
    font-size: 14px;
  }
}
</style>
