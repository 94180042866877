<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0 font-size-18">{{ routeName }}&nbsp;</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li v-if="routeName !== 'Dashboard'" class="breadcrumb-item">
              <router-link to="/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active">{{ routeName }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->

  <!-- because this depends on orang data, we make it like this.
  If it's not like this, the application will just be blank -->
  <div class="row" v-if="datas && datas.orang">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-lg-4">
              <div class="d-flex">
                <div
                  class="flex-shrink-0 me-3 avatar-cover"
                  @click="() => (isShowModalUpload = !isShowModalUpload)"
                >
                  <h5 class="avatar-label">EDIT</h5>
                  <img
                    :src="datas.orang.orang_avatar_file_url"
                    alt="avatar"
                    class="rounded-circle img-thumbnails"
                  />
                </div>

                <ModalUpload
                  :show="isShowModalUpload"
                  @close="isShowModalUpload = false"
                ></ModalUpload>

                <div class="flex-grow-1 align-self-center">
                  <div class="text-muted">
                    <p class="mb-2">Welcome to Wavedex</p>
                    <h5 v-if="datas.orang.orang_nickname != null" class="mb-1">
                      {{ datas.orang.orang_nickname }}
                    </h5>
                    <h5 v-else>
                      {{ datas.orang.orang_name }}
                    </h5>
                    <div v-show="datas.orang.orang_kyc_approve" class="badge_wrap">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <path
                          d="M9.007 1.10402C8.32847 0.884557 7.59394 0.913988 6.93513 1.18704C6.27633 1.46009 5.73635 1.9589 5.412 2.59402L4.606 4.17002C4.51022 4.35765 4.35763 4.51024 4.17 4.60602L2.593 5.41202C1.95788 5.73636 1.45907 6.27635 1.18602 6.93515C0.91297 7.59396 0.883538 8.32848 1.103 9.00702L1.649 10.692C1.71382 10.8922 1.71382 11.1078 1.649 11.308L1.104 12.993C0.884539 13.6716 0.91397 14.4061 1.18702 15.0649C1.46007 15.7237 1.95888 16.2637 2.594 16.588L4.17 17.394C4.35763 17.4898 4.51022 17.6424 4.606 17.83L5.412 19.407C5.73635 20.0421 6.27633 20.541 6.93513 20.814C7.59394 21.0871 8.32847 21.1165 9.007 20.897L10.692 20.351C10.8922 20.2862 11.1078 20.2862 11.308 20.351L12.993 20.896C13.6714 21.1156 14.4059 21.0863 15.0647 20.8134C15.7234 20.5406 16.2635 20.042 16.588 19.407L17.394 17.83C17.4898 17.6424 17.6424 17.4898 17.83 17.394L19.407 16.589C20.0423 16.2647 20.5413 15.7245 20.8144 15.0655C21.0874 14.4065 21.1167 13.6717 20.897 12.993L20.351 11.308C20.2862 11.1078 20.2862 10.8922 20.351 10.692L20.896 9.00702C21.1155 8.3286 21.0863 7.59416 20.8134 6.93537C20.5405 6.27658 20.0419 5.73653 19.407 5.41202L17.83 4.60602C17.6424 4.51024 17.4898 4.35765 17.394 4.17002L16.589 2.59302C16.2646 1.95768 15.7245 1.45871 15.0655 1.18565C14.4064 0.912583 13.6717 0.883288 12.993 1.10302L11.308 1.64902C11.1078 1.71384 10.8922 1.71384 10.692 1.64902L9.007 1.10402ZM5.76 10.757L7.174 9.34302L10.002 12.172L15.659 6.51502L17.074 7.92902L10.002 14.999L5.76 10.757Z"
                          fill="#ffff"
                        />
                      </svg>
                      <p><var></var>Verified</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 align-self-center">
              <div class="text-lg-center mt-4 mt-lg-0">
                <div class="row">
                  <div class="col-4">
                    <div>
                      <p class="text-muted text-truncate mb-2">Total Client</p>
                      <h5 class="mb-0">{{ datas.total_client }}</h5>
                    </div>
                  </div>
                  <div class="col-4">
                    <div>
                      <p class="text-muted text-truncate mb-2">Total Pool</p>
                      <h5 class="mb-0">{{ datas.total_pool }}</h5>
                    </div>
                  </div>
                  <div class="col-4">
                    <div>
                      <p class="text-muted text-truncate mb-2">Active Client</p>
                      <h5 class="mb-0">{{ datas.active_client }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 d-none d-lg-block">
              <div class="clearfix mt-4 mt-lg-0">
                <!-- <div class="dropdown float-end">
                  <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="bx bxs-cog align-middle me-1"></i> Setting
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else</a>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import ModalUpload from '../../modals/ModalUpload.vue'

// modules
import { computed } from 'vue'

// stores
import { useProfile } from '@/stores/profile-store'

export default {
  components: { ModalUpload },
  data() {
    return {
      store: useProfile(),
      datas: computed(() => this.store.profiles),
      routeName: null,
      isShowModalUpload: false
    }
  },
  mounted() {
    this.getProfileData()
    this.handleBreadcrumbs()
  },
  methods: {
    getProfileData() {
      this.store.getProfile()
    },

    handleBreadcrumbs() {
      const routeName = this.$route.name
      this.routeName = routeName
    }
  }
}
</script>

<style scoped>
.avatar-cover {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 3rem;
  position: relative;
  outline: 4px solid #d4dde9b9;
  overflow: hidden;
  transition: 0.2s ease;
}
.avatar-cover:hover {
  cursor: pointer;
  outline: 4px solid #babec4c2;
}
.img-thumbnails {
  width: 100%;
  height: 100%;
}
.avatar-label {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  opacity: 0;
  text-shadow: 1px 1px 2px #000000;
  font-weight: 600;
  font-size: small;
  transition: 0.2s ease;
}
.avatar-label:hover {
  opacity: 1;
}
.badge_wrap {
  width: fit-content;
  padding: 3px 8px;
  background-color: #007a4d;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.badge_wrap p {
  margin: 0;
  font-size: 14px;
  color: aliceblue;
}
@media only screen and (max-width: 985px) {
  .avatar-label {
    opacity: 1;
  }
}
</style>
