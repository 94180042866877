<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Recent Transaction</h4>

          <div v-if="isDeposit" class="crypto-buy-sell-nav">
            <deposit-history />
          </div>
          <div v-else class="crypto-buy-sell-nav">
            <withdraw-history />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
// import AllHistory from './AllHistory.vue'
import DepositHistory from './DepositHistory.vue'
import WithdrawHistory from './WithdrawHistory.vue'

export default {
  components: {
    // AllHistory,
    DepositHistory,
    WithdrawHistory
  },
  data() {
    return {
      isDeposit: false
    }
  },
  mounted() {
    this.handleActive()
  },
  methods: {
    handleActive() {
      const route = this.$route
      const routeName = route.name

      if (routeName === 'Deposit') {
        this.isDeposit = true
      } else {
        this.isDeposit = false
      }
    }
  }
}
</script>
