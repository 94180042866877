<template>
  <div>
    <div class="row">
      <div class="col-xl-2 col-sm-4">
        <div class="mb-3">
          <label class="card-radio-label mb-2">
            <input
              type="radio"
              name="currency"
              id="buycurrencyoption1"
              class="card-radio-input"
              checked
            />

            <div class="card-radio">
              <div>
                <i class="mdi mdi-dollar font-size-24 text-warning align-middle me-2"></i>
                <span>USDT</span>
              </div>
            </div>
          </label>

          <!-- <div>
            <p class="text-muted mb-1">Current price :</p>
            <h5 class="font-size-16">0.00016 BTC</h5>
          </div> -->
        </div>
      </div>

      <!-- networks -->

      <div class="mb-2">
        <label>Network</label>

        <!-- TRC20 -->
        <div class="row">
          <div class="col-xl-2 col-sm-4">
            <label class="card-radio-label mb-3">
              <input
                @change="selectedCurrency('TRC20')"
                type="radio"
                name="pay-method"
                id="pay-methodoption1"
                class="card-radio-input"
                checked
              />

              <div class="card-radio">
                <i class="bx bx-cube-alt"></i>
                <span>TRC20</span>
              </div>
            </label>
          </div>

          <!-- BEP20 -->
          <div class="col-xl-2 col-sm-4">
            <label class="card-radio-label mb-3">
              <input
                type="radio"
                name="pay-method"
                id="pay-methodoption3"
                class="card-radio-input"
                @change="selectedCurrency('BEP20')"
              />

              <div class="card-radio">
                <i class="bx bx-cube-alt"></i>
                <span>BEP20</span>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="col-xl-2 col-sm-4">
        <div class="mb-3">
          <label class="card-radio-label mb-2">
            <input type="radio" name="currency" id="buycurrencyoption2" class="card-radio-input" />
          </label>
          <div></div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label>Amount :</label>
      <input
        @input="handleInput"
        @click="warningMsg = ''"
        v-model="amount"
        type="text"
        class="form-control"
        placeholder="1000"
      />
      <div v-if="warningMsg" class="text-danger">{{ warningMsg }}</div>
    </div>
    <div class="text-center mt-4">
      <button @click="handleDeposit" class="btn btn-success" :disabled="loading">
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span v-else>Deposit</span>
      </button>

      <Teleport to="body">
        <modal-reusable
          :show="showModalSuccess"
          @close="showModalSuccess = false"
          :typeSuccess="true"
          :isSuccess="true"
          :resMsg="resMsg"
        >
        </modal-reusable>

        <modal-reusable
          :show="showModalFailure"
          @close="showModalFailure = false"
          :typeSuccess="true"
          :isSuccess="false"
          :resMsg="resMsg"
        >
        </modal-reusable>
      </Teleport>
    </div>
  </div>
</template>

<script>
// service
import service from '@/utils/service'

// stores
import { useWalletHistory } from '@/stores/wallet-store.js'
import { useSessionStore } from '@/stores/session-store'

// components
import ModalReusable from '../../modals/ModalReusable.vue'

export default {
  components: {
    ModalReusable
  },
  data() {
    return {
      store: useWalletHistory(),
      sessionStore: useSessionStore(),
      amount: '',
      currencyNetwork: 'TRC20',
      loading: false,
      warningMsg: '',
      resMsg: '',
      showModalSuccess: false,
      showModalFailure: false
    }
  },
  methods: {
    handleDeposit() {
      if (this.amount) {
        this.loading = true
        this.sessionOff()
        this.fecthAPI()
      } else {
        this.warningMsg = 'Amount is required'
      }
    },

    handleInput() {
      this.amount = this.amount.replace(/[^0-9]/g, '')
    },
    selectedCurrency(value) {
      this.currencyNetwork = value
    },
    fecthAPI() {
      service
        .post(
          '/deposits/create',
          { amount: this.amount, currency_network: 'USDT.' + this.currencyNetwork },
          { headers: { requiresAuth: true } }
        )
        .then((response) => {
          if (response.success) {
            this.showModalSuccess = true
            this.resMsg = 'Deposit Success'
            this.store.getDepositHistory(1)
          } else {
            this.showModalFailure = true
            this.resMsg = response.msg
          }
        })
        .catch((error) => {
          console.error('Error Aman:', error)
        })
        .finally(() => {
          this.loading = false
          this.amount = ''
          this.sessionStore.authenticate()
          this.sessionStore.checkSessionTimeout()

          setTimeout(() => {
            this.showModalSuccess = false
            this.showModalFailure = false
          }, 3000)
        })
    },
    sessionOff() {
      this.sessionStore.removeAuticaticated()
    }
  }
}
</script>
