<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5 text-muted">
            <a href="index.html" class="d-block auth-logo">
              <img
                src="../../assets/images/logo-dark.png"
                alt=""
                height="20"
                class="auth-logo-dark mx-auto"
              />
              <img
                src="../../assets/images/logo-light.png"
                alt=""
                height="20"
                class="auth-logo-light mx-auto"
              />
            </a>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div class="card-body">
              <div class="p-2">
                <div class="text-center">
                  <div class="avatar-md mx-auto">
                    <div class="avatar-title rounded-circle bg-light">
                      <i class="bx bxs-envelope h1 mb-0 text-primary"></i>
                    </div>
                  </div>
                  <div class="p-2 mt-4">
                    <h4>Verify your email</h4>
                    <p class="mb-5">
                      Please enter the 4 digit code sent to
                      <span class="fw-semibold">example@abc.com</span>
                    </p>

                    <div class="d-flex align-items-center justify-content-center">
                      <otp v-model="pin" :count="5" ref="otpComponent">
                        <template v-slot="{ digits, onInput, onPaste, onBlur }">
                          <div class="d-flex align-items-center mx-4">
                            <otp-group class="d-flex mx-2 p-2">
                              <template v-slot="{ focusNext, focusPrev }">
                                <otp-group-input
                                  v-for="(digit, index) in digits"
                                  :key="index"
                                  :value="digit"
                                  autofocus
                                  placeholder="*"
                                  @blur="onBlur"
                                  @next="focusNext(index)"
                                  @prev="focusPrev(index)"
                                  @paste="onPaste(index, $event)"
                                  @input="onInput(index, $event)"
                                  class="form-control h-12 w-12 rounded-xl text-3xl text-center text-muted border-2 border-muted placeholder-text-muted focus-outline-none focus-border-teal"
                                />
                              </template>
                            </otp-group>

                            <button
                              class="btn btn-light rounded-circle transition-color text-muted hover-text-teal"
                              @click="clearPin"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </button>
                          </div>
                        </template>

                        <template v-slot:error="{ message, hasError }">
                          <otp-error-message :message="message" :hasError="hasError" />
                        </template>
                      </otp>
                    </div>

                    <div class="mt-4">
                      <a href="index.html" class="btn btn-success w-md">Confirm</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p>Didn't receive a code ? <a href="#" class="fw-medium text-primary"> Resend </a></p>
            <p>© {{ dateTime }} Wavedex.io</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Otp, OtpGroup, OtpGroupInput, OtpErrorMessage } from '@/components/otp'

export default {
  components: {
    Otp,
    OtpGroup,
    OtpGroupInput,
    OtpErrorMessage
  },
  data() {
    return {
      dateTime: '2023',
      pin: ''
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year
  },
  watch: {
    pin(pin) {
      console.log({ pin })
    }
  },

  methods: {
    clearPin() {
      this.pin = ''
      this.$refs.otpComponent.clearDigits()
    }
  }
}
</script>

<style scoped>
.form-control {
  margin-right: 10px;
}
</style>
