<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <!-- breadcrumbs -->
        <BreadcrumbsBar />

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4">Deposit</h4>
                <DepositSection />
              </div>
            </div>
          </div>

          <!-- recent transaction -->
          <recent-transaction />
        </div>
      </div>
    </div>

    <FooterBar />
  </div>
</template>

<script>
// componets
import RecentTransaction from '../wallet/RecentTransaction.vue'
import BreadcrumbsBar from '../global/BreadcrumbsBar.vue'
import FooterBar from '../global/FooterBar.vue'
import DepositSection from '../wallet/DepositSection.vue'
export default {
  components: {
    RecentTransaction,
    BreadcrumbsBar,
    FooterBar,
    DepositSection
  }
}
</script>
