<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container card">
        <div class="modal-header">
          <svg
            @click="$emit('close')"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M14 0C6.2 0 0 6.2 0 14C0 21.8 6.2 28 14 28C21.8 28 28 21.8 28 14C28 6.2 21.8 0 14 0ZM19.4 21L14 15.6L8.6 21L7 19.4L12.4 14L7 8.6L8.6 7L14 12.4L19.4 7L21 8.6L15.6 14L21 19.4L19.4 21Z"
              fill="#E62A07"
            />
          </svg>
        </div>

        <div class="modal-body">
          <!-- hanya muncul saat typeSuccess -->
          <div v-if="typeSuccess" class="">
            <div class="anim_wrap">
              <div v-if="isSuccess">
                <success-animation />
              </div>
              <div v-else>
                <failure-animation />
              </div>
              <p>{{ resMsg }}</p>
            </div>
          </div>

          <!-- diinject dari parent component -->
          <slot v-else name="body"> default body </slot>
        </div>

        <div v-if="!typeSuccess" class="modal-footer">
          <slot name="footer">
            <button @click="$emit('close')">Close</button>
            <button class="modal-default-button">Submit</button>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// components
import SuccessAnimation from '@/components/animations/SuccessAnimation.vue'
import FailureAnimation from '@/components/animations/FailureAnimation.vue'

export default {
  props: {
    show: Boolean,
    typeSuccess: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    resMsg: {
      type: String,
      default: ''
    },
    closeIn: {
      type: String,
      default: '4000'
    }
  },
  components: {
    SuccessAnimation,
    FailureAnimation
  },
  data() {
    return {}
  },
  watch: {
    typeSuccess: {
      immediate: true, // Run the handler immediately after component is created
      handler(newVal) {
        if (newVal) {
          this.autoCloseModal()
        }
      }
    }
  },
  methods: {
    autoCloseModal() {
      const toNum = Number(this.closeIn)
      setTimeout(() => {
        this.$emit('close')
      }, toNum)
    }
  }
}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 300px;
  margin: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-footer button {
  width: 80px;
  border-radius: 4px;
}
.modal-footer button:nth-child(2) {
  background-color: #556ee6;
  border: 1px solid #556ee6;
  color: #fff;
}
.modal-footer button:nth-child(1) {
  background: none;
  border: 1px solid #556ee6;
  color: #556ee6;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.anim_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.anim_wrap p {
  max-width: 300px;
  font-size: 14px;
  text-align: center;
}
/* [data-bs-theme='dark'] .modal-container {
  background-color: #2a3042 !important;
}
[data-bs-theme='dark'] .modal-body {
  color: #fff !important;
} */
</style>
