<template>
  <div id="layout-wrapper">
    <NavBar />
    <SideBar />
    <NewDashboardMain />
  </div>

  <div class="rightbar-overlay"></div>

  <ModalSession :show="isShowModalSession" @close="isShowModalSession = false"></ModalSession>
  <ModalAnnouncement
    :show="isShowModalAnnouncement"
    @close="setOffAnnouncement()"
  ></ModalAnnouncement>
</template>

<script>
// stores
import { useSessionStore } from '@/stores/session-store'

// components
import SideBar from '../../components/dashboard/global/SideBar.vue'
import NavBar from '../../components/dashboard/global/NavBar.vue'
import NewDashboardMain from '../../components/dashboard/main/NewDashboardMain.vue'
import ModalAnnouncement from '../../components/modals/ModalAnnouncement.vue'
import ModalSession from '../../components/modals/ModalSession.vue'

export default {
  components: {
    SideBar,
    NavBar,
    NewDashboardMain,
    ModalAnnouncement,
    ModalSession
  },
  data() {
    return {
      store: useSessionStore(),
      isShowModalSession: false,
      isShowModalAnnouncement: false
    }
  },
  created() {
    this.store.authenticate()
    this.store.checkSessionTimeout()
    this.getAnnouncement()
  },
  methods: {
    setOffAnnouncement() {
      this.isShowModalAnnouncement = false
      localStorage.removeItem('showAnnouncement')
    },
    getAnnouncement() {
      // diberi jeda agar ketika transisi dibuat terlihat
      setTimeout(() => {
        this.isShowModalAnnouncement = localStorage.getItem('showAnnouncement')
      }, 100)
    }
  },
  watch: {
    'store.isSessionTimeEnd': {
      handler() {
        if (this.store.isSessionTimeEnd) {
          this.isShowModalSession = true
        }
      }
    }
  }
}
</script>
