const dateFormat = (inputDate) => {
  var dateObject = new Date(inputDate)

  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'Asia/Jakarta'
  }

  var outputDate = dateObject.toLocaleString('en-US', options)

  return outputDate
}

export default dateFormat
