<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          {{ dateTime }}
          © Wavedex.io
        </div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">www.wavedex.io</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      dateTime: '2023'
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year
  }
}
</script>
