// modules
import { createRouter, createWebHistory } from 'vue-router'
import Cookies from 'js-cookie'

import HomeView from '../views/home/HomeView.vue'

// auth
import LoginView from '../views/auth/LoginView.vue'
import RegisterView from '../views/auth/RegisterView.vue'
// import EmailVerification from '../views/auth/EmailVerificationView.vue'
import TwoStepVerificationView from '../views/auth/TwoStepVerificationView.vue'
import TwoFactorAuth from '@/views/auth/TwoFactorAuth.vue'
import SuccessRequestRegisterView from '../views/auth/SuccessRequestRegisterView.vue'
import LockScreenView from '../views/auth/LockScreenView.vue'
import ResetPasswordView from '../views/auth/ResetPasswordView.vue'
import AuthChangePasswordView from '../views/auth/AuthChangePasswordView.vue'
import RequestEmailVerification from '../views/auth/RequestEmailVerificationView.vue'

// dashboard
// import DashboardView from '../views/dashboard/DashboardView.vue'
import NewDashboardView from '../views/dashboard/NewDashboardView.vue'
import ProfileView from '../views/dashboard/ProfileView.vue'
import ClientView from '../views/dashboard/ClientView.vue'
import SubscriptionsView from '../views/dashboard/SubscriptionsView.vue'
// import ChangePasswordView from '../views/dashboard/ChangePasswordView.vue'
import SubscriptionDetail from '@/components/dashboard/subscriptions/SubscriptionDetail.vue'
import DepositView from '@/views/dashboard/DepositView.vue'
// import WithdrawView from '@/views/dashboard/WithdrawView.vue'
import TwoFactorView from '@/views/dashboard/TwoFactorView.vue'

import WithdrawProfitView from '@/views/dashboard/Withdraw/ProfitView.vue'

import DepositDetail from '@/components/dashboard/wallet/deposit/DepositDetail.vue'

import NotFound from '../views/404/NotFound.vue'
import UnderDevelopment from '../views/404/UnderDevelopment.vue'

const isLogin = Cookies.get('jwt') ? true : false

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomeView
    },
    {
      path: '/auth/login',
      name: 'Login',
      component: LoginView,
      beforeEnter: (to, from, next) => {
        if (isLogin) {
          next('/dashboard')
        } else {
          next()
        }
      }
    },
    {
      path: '/auth/register',
      name: 'Register',
      component: RegisterView,
      beforeEnter: (to, from, next) => {
        if (isLogin) {
          next('/dashboard')
        } else {
          next()
        }
      }
    },
    // {
    //   path: '/auth/email-verification',
    //   name: 'Email Verification',
    //   component: EmailVerification,
    //   beforeEnter: (to, from, next) => {
    //     if (isLogin) {
    //       next('/dashboard')
    //     } else {
    //       next()
    //     }
    //   }
    // },
    {
      path: '/auth/two-step-verification',
      name: 'Two Step Verification',
      component: TwoStepVerificationView,

    },
    {
      path: '/auth/email-verification',
      name: 'Success Request Register',
      component: SuccessRequestRegisterView,
      beforeEnter: (to, from, next) => {
        if (isLogin) {
          next('/dashboard')
        } else {
          next()
        }
      }
    },
    {
      path: '/auth/two-factor-auth',
      name: 'Two Factor Auth',
      component: TwoFactorAuth,
      beforeEnter: (to, from, next) => {
        if (isLogin) {
          next('/dashboard')
        } else {
          next()
        }
      }
    },
    {
      path: '/auth/lock-screen',
      name: 'Lock Screen',
      component: LockScreenView
    },
    {
      path: '/auth/reset-password',
      name: 'Reset Password',
      component: ResetPasswordView
    },
    {
      path: '/auth/passchange-form',
      name: 'Auth Change Password',
      component: AuthChangePasswordView
    },
    {
      path: '/auth/request-email-verification',
      name: 'Request Email Verification',
      component: RequestEmailVerification
    },

    // dashboard
    // TODO: Mungkin ini nanti tidak difungsikan lagi
    // {
    //   path: '/dashboard',
    //   name: 'Dashboard',
    //   component: DashboardView,
    //   beforeEnter: (to, from, next) => {
    //     if (!isLogin) {
    //       next('/')
    //     } else {
    //       next()
    //     }
    //   }
    // },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: NewDashboardView,
      beforeEnter: (to, from, next) => {
        if (!isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },
    {
      path: '/dashboard/profile',
      name: 'Profile',
      component: ProfileView,
      beforeEnter: (to, from, next) => {
        if (!isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },
    {
      path: '/dashboard/two-factor-panel',
      name: 'Security',
      component: TwoFactorView,
      beforeEnter: (to, from, next) => {
        if (!isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },
    {
      path: '/dashboard/client',
      name: 'Client',
      component: ClientView,
      beforeEnter: (to, from, next) => {
        if (!isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },
    {
      path: '/dashboard/deposit',
      name: 'Deposit',
      component: DepositView,
      beforeEnter: (to, from, next) => {
        if (!isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },
    {
      path: '/dashboard/deposit/:id',
      name: 'Deposit Detail',
      component: DepositDetail,
      beforeEnter: (to, from, next) => {
        if (!isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },

    // TODO: Mungkin ini nanti tidak difungsikan lagi
    // {
    //   path: '/dashboard/withdraw',
    //   name: 'Withdraw',
    //   component: WithdrawView,
    //   beforeEnter: (to, from, next) => {
    //     if (!isLogin) {
    //       next('/')
    //     } else {
    //       next()
    //     }
    //   }
    // },

    {
      path: '/dashboard/withdraw/profit',
      name: 'Withdraw',
      component: WithdrawProfitView,
      beforeEnter: (to, from, next) => {
        if (!isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },
    {
      path: '/dashboard/subscriptions',
      name: 'Subscriptions',
      component: SubscriptionsView,
      beforeEnter: (to, from, next) => {
        if (!isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },
    {
      path: '/dashboard/subscriptions/:id',
      name: 'Subscription Detail',
      component: SubscriptionDetail,
      beforeEnter: (to, from, next) => {
        if (!isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },
    {
      path: '/dashboard/change-password',
      name: 'Change Password',
      component: UnderDevelopment,
      beforeEnter: (to, from, next) => {
        if (!isLogin) {
          next('/')
        } else {
          next()
        }
      }
    },
    // {
    //   path: '/dashboard/security',
    //   name: 'Security',
    //   component: UnderDevelopment,
    //   beforeEnter: (to, from, next) => {
    //     if (!isLogin) {
    //       next('/')
    //     } else {
    //       next()
    //     }
    //   }
    // },
    {
      path: '/:catchAll(.*)',
      component: NotFound,
      name: '404'
    }
  ]
})

router.beforeEach(function (to, _2, next) {
  const appName = 'Wavedex'
  const metaName = to.name
  document.title = `${appName} - ${metaName}`
  next()
})

export default router
