<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <!-- start page title -->
        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 class="mb-sm-0 font-size-18">Payment Detail</h4>

              <div class="page-title-right">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item">
                    <span @click="goBack">Detail</span>
                  </li>
                  <li class="breadcrumb-item active">Payment</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <!-- end page title -->

        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="invoice-title">
                  <h4 class="float-end font-size-16">TXN ID # {{ datas.txn_id }}</h4>
                  <div class="auth-logo mb-4">
                    <img
                      src="@/assets/images/logo-dark.png"
                      alt="logo"
                      class="auth-logo-dark"
                      height="20"
                    />
                    <img
                      src="@/assets/images/logo-light.png"
                      alt="logo"
                      class="auth-logo-light"
                      height="20"
                    />
                  </div>
                </div>
                <hr />
                <div class="addr_wrapper">
                  <div class="qr_wrap">
                    <div class="text-sm-center dt_container">
                      <h2 class="text-center">
                        Perform a transaction by scanning the QR code below.
                      </h2>
                      <img :src="datas.qrcode_url" alt="qr-code" />
                    </div>
                  </div>
                  <div class="dt_container">
                    <div class="mt-3 text-sm-center qr_wrap">
                      <div v-if="isHovered" class="copy_icon">
                        <img v-if="isClicked" src="@/assets/images/copied.png" alt="copied" />
                        <img v-else src="@/assets/images/copy.png" alt="copy" />
                      </div>
                      <address
                        class="addr_dsh"
                        @click="handleCopyClick(datas.address)"
                        @mouseover="handleMouseHover"
                        @mouseleave="handleMouseLeave"
                      >
                        <strong>Address</strong><br />
                        {{ datas.address }}<br /><br />
                      </address>
                      <address
                        class="addr_mob"
                        @click="handleCopyClick(datas.address)"
                        @mouseover="handleMouseHover"
                        @mouseleave="handleMouseLeave"
                      >
                        <strong>Address</strong><br />
                        {{ datas.address?.substring(0, 8) }}...{{
                          datas.address?.substring(datas.address.length, datas.address.length - 8)
                        }}<br /><br />
                      </address>
                    </div>

                    <div class="link_checkout">
                      <strong>Or click this link</strong>
                      <a :href="datas.checkout_url"> Checkout</a>
                    </div>

                    <div class="count_down card">
                      <h2 class="text-danger" v-if="timeRemaining <= 0 && !paymentStatus">
                        Timed Out
                      </h2>
                      <h2 v-else-if="paymentStatus" class="text-success">Payment Completed</h2>
                      <div v-else>
                        <h2 class="text-center">OFFER ENDS IN:</h2>

                        <div class="coming_box-wrapper">
                          <div class="coming_box">
                            <h2>{{ hours }}</h2>
                            <p>HOURS</p>
                          </div>
                          <span>:</span>
                          <div class="coming_box">
                            <h2>{{ minutes }}</h2>
                            <p>MINUTES</p>
                          </div>
                          <span>:</span>
                          <div class="coming_box">
                            <h2>{{ seconds }}</h2>
                            <p>SECONDS</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-2 mt-4">
                  <h3 class="font-size-15 fw-bold">Coinpayment summary</h3>
                </div>
                <div class="table-responsive">
                  <table class="table table-nowrap">
                    <thead>
                      <tr>
                        <th style="width: 70px">TXN ID</th>
                        <th>AMOUNT</th>
                        <th>Network</th>
                        <th>Payment Status</th>
                        <th class="text-end">STATUS URL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ datas.txn_id }}</td>
                        <td>{{ datas.amount }}</td>
                        <td>
                          <img
                            style="margin-right: -4px"
                            src="@/assets/images/usdt-icon.svg"
                            alt="usdt"
                            width="20"
                          />
                          <img
                            v-if="network === 'USDT.BEP20'"
                            src="@/assets/images/bep.png"
                            alt="bep"
                            width="20"
                          />
                          <img v-else src="@/assets/images/trc.png" alt="trc" width="20" />
                        </td>
                        <td>
                          <span
                            v-if="paymentStatus === true"
                            class="badge badge-pill badge-soft-success font-size-11"
                            >Paid</span
                          >
                          <span v-else class="badge badge-pill badge-soft-warning font-size-11"
                            >Unpaid</span
                          >
                        </td>
                        <td class="text-end">
                          <a
                            class="btn btn-primary w-md waves-effect waves-light"
                            :href="datas.status_url"
                            >Check Status</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterBar />
  </div>
</template>

<script>
// modules
import copy from 'copy-to-clipboard'

// stores
import { useSubscription } from '@/stores/subscription-store'
import { useWalletHistory } from '@/stores/wallet-store'

// component
import FooterBar from './FooterBar.vue'

export default {
  components: {
    FooterBar
  },
  props: {
    datas: Object,
    network: String,
    dateUpdate: String,
    paymentStatus: Boolean,
    id: String
  },
  data() {
    return {
      storeSubs: useSubscription(),
      storeDep: useWalletHistory(),
      isHovered: false,
      isClicked: false,

      countdownInterval: null,
      timeRemaining: null,
      hours: 0,
      minutes: 0,
      seconds: 0,

      oneOnly: false
    }
  },
  async mounted() {
    this.updateCountdown()
    this.countdownInterval = setInterval(this.updateCountdown, 1000)
  },
  methods: {
    updateCountdown() {
      const currentTime = new Date().getTime()
      const startTime = new Date(this.dateUpdate).getTime()
      this.timeRemaining = startTime + 3 * 60 * 60 * 1000 - currentTime

      if (this.timeRemaining <= 0) {
        clearInterval(this.countdownInterval)
        this.countdownInterval = null
        this.reCall()
      } else {
        this.hours = Math.floor(this.timeRemaining / (1000 * 60 * 60))
        this.minutes = Math.floor((this.timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
        this.seconds = Math.floor((this.timeRemaining % (1000 * 60)) / 1000)
      }
    },
    reCall() {
      this.oneOnly = true
      if (this.oneOnly == false) {
        this.storeSubs.getSubsDetail(this.id)
        this.storeDep.getDepositDetail(this.id)
      }
    },
    handleCopyClick(val) {
      this.isClicked = true
      copy(val)
    },

    handleMouseLeave() {
      this.isHovered = false
      this.isClicked = false
    },

    handleMouseHover() {
      this.isHovered = true
    },

    goBack() {
      this.$router.back(-1)
    }
  }
}
</script>

<style scoped>
.addr_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}
.addr_wrapper h2 {
  max-width: 320px;
  font-size: 18px;
}
.qr_wrap {
  position: relative;
}
.copy_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -120%);
}
.copy_icon img {
  width: 90px;
  height: auto;
}
address {
  cursor: copy;
}
.link_checkout {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.breadcrumb-item span:nth-child(1) {
  cursor: pointer !important;
}
.count_down {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.count_down h2 {
  font-weight: bold;
  font-size: 24px;
}
.coming_box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.coming_box-wrapper span {
  font-size: 24px;
  font-weight: bold;
}
.coming_box {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.coming_box h2 {
  font-size: 20px;
  font-weight: bold;
  color: #0d6efd;
}
.coming_box p {
  font-size: 12px;
  margin: 0;
}
.addr_mob {
  display: none;
}
address {
  text-align: center;
}
.dt_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .qr_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

@media only screen and (max-width: 500px) {
  .addr_mob {
    display: block;
  }
  .addr_dsh {
    display: none;
  }
  .invoice-title h4 {
    font-size: 14px !important;
  }
}
</style>
