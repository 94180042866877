<template>
  <div class="row">
    <div class="col-lg-3 d-flex">
      <div class="card flex-grow-1">
        <div class="card-body">
          <div
            class="d-flex flex-column align-items-center justify-content-between h-100 text-center gap-2"
          >
            <div>
              <h4 class="card-title">Subscription Bonus</h4>
              <p class="text-muted">Monthly Calculation</p>
            </div>
            <h4 class="text-muted mb-4">
              ${{ parseFloat(balanceGroup.subscription_bonus).toFixed(2) }}
            </h4>
            <router-link to="/dashboard/withdraw/profit?tab=subscription" class="btn btn-success">
              Withdraw
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 d-flex">
      <div class="card flex-grow-1">
        <div class="card-body">
          <div
            class="d-flex flex-column align-items-center justify-content-between h-100 text-center gap-2"
          >
            <div>
              <h4 class="card-title">Sales Income</h4>
              <p class="text-muted">Monthly Calculation</p>
            </div>
            <h4 class="text-muted mb-4">${{ parseFloat(balanceGroup.sales_income).toFixed(2) }}</h4>
            <router-link to="/dashboard/withdraw/profit?tab=sales" class="btn btn-success">
              Withdraw
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 d-flex">
      <div class="card flex-grow-1">
        <div class="card-body">
          <div
            class="d-flex flex-column align-items-center justify-content-between h-100 text-center gap-2"
          >
            <div>
              <h4 class="card-title">Withdrawal Income</h4>
              <p class="text-muted">Daily Calculation</p>
            </div>
            <h4 class="text-muted mb-4">
              ${{ parseFloat(balanceGroup.withdraw_income).toFixed(2) }}
            </h4>
            <router-link to="/dashboard/withdraw/profit?tab=withdraw" class="btn btn-success">
              Withdraw
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 d-flex">
      <div class="card flex-grow-1">
        <div class="card-body">
          <div
            class="d-flex flex-column align-items-center justify-content-between h-100 text-center gap-2"
          >
            <div>
              <h4 class="card-title">Trading Income</h4>
              <p class="text-muted">Daily Calculation</p>
            </div>
            <h4 class="text-muted mb-4">
              ${{ parseFloat(balanceGroup.trading_income).toFixed(2) }}
            </h4>
            <router-link to="/dashboard/withdraw/profit?tab=trading" class="btn btn-success">
              Withdraw
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// modules
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      baseURL: import.meta.env.VITE_BASE_API,
      balanceGroup: {}
    }
  },
  async mounted() {
    await this.ambilDataProfitGroup()
  },

  methods: {
    async ambilDataProfitGroup() {
      var res = await axios({
        method: 'get',
        url: `${this.baseURL}/balances/balance-group`,
        headers: {
          JWT: Cookies.get('jwt')
        }
      })

      this.balanceGroup = res.data.data
    }
  }
}
</script>
