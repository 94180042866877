<template>
  <div>
    <div v-if="loading" class="spinner_wrapper">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else class="table-responsive">
      <table
        v-if="histories.length > 0"
        id="datatable"
        class="table table-hover dt-responsive nowrap"
        style="border-collapse: collapse; border-spacing: 0; width: 100%"
      >
        <thead class="table-light">
          <tr>
            <th>#</th>
            <th>Amount</th>
            <th>Txn Hash</th>
            <th>Status</th>
            <th>Created At</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="history in histories" :key="history.id">
            <td>
              {{ history.id }}
            </td>
            <td>{{ history.amount }} USDT</td>
            <td
              @click="handleCopyClick(history.trx_out_res.data.transactionHash)"
              @mouseover="handleMouseHover"
              @mouseleave="handleMouseLeave"
              class="qr_wrap"
            >
              <div v-if="isHovered" class="copy_icon">
                <img v-if="isClicked" src="@/assets/images/copied.png" alt="copied" />
                <img v-else src="@/assets/images/copy.png" alt="copy" />
              </div>

              <span>{{ formatAddrees(history.trx_out_res.data.transactionHash) }}</span>
            </td>
            <!-- <td>{{ formatAddrees(history.trx_out_res.data.transactionHash) }}</td> -->
            <td>
              <span
                v-if="history.step === 'COMPLETE'"
                class="badge badge-pill badge-soft-success font-size-11"
                >{{ history.step }}</span
              >
              <span v-else class="badge badge-pill badge-soft-warning font-size-11">{{
                history.step
              }}</span>
            </td>
            <td>
              {{ moment(history.created_at).format('DD MMMM YYYY, h:mm:ss') }}
            </td>
          </tr>
        </tbody>
      </table>

      <div v-else class="not_found">
        <img src="@/assets/images/found-icon.png" alt="found" width="50" />
        <p>No transactions have been recorded</p>
      </div>
    </div>
  </div>
</template>

<script>
// store
import { useWalletHistory } from '@/stores/wallet-store.js'

// modules
import { computed } from 'vue'
import moment from 'moment'
import copy from 'copy-to-clipboard'

export default {
  data() {
    return {
      store: useWalletHistory(),
      histories: computed(() => this.store.profitHistory),
      loading: computed(() => this.store.loading),
      page: 1,
      moment,

      isHovered: false,
      isClicked: false
    }
  },
  mounted() {
    this.getDataHistory()
    // this.dateFormat()
  },
  methods: {
    async getDataHistory() {
      await this.store.getProfitHistory(this.page)
    },
    formatAddrees(val) {
      let prefix = val.substring(0, 6)
      let suffix = val.substring(val.length, val.length - 8)

      return `${prefix}...${suffix}`
    },
    handleCopyClick(val) {
      this.isClicked = true
      copy(val)
    },

    handleMouseLeave() {
      this.isHovered = false
      this.isClicked = false
    },

    handleMouseHover() {
      this.isHovered = true
    }
  }
}
</script>

<style scoped>
.spinner_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.not_found {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.not_found p {
  max-width: 150px;
  text-align: center;
}
.qr_wrap {
  position: relative;
}
.copy_icon {
  position: absolute;
  top: -40%;
  left: 5%;
}
.copy_icon img {
  width: 90px;
  height: auto;
}
.qr_wrap span {
  cursor: copy;
}

@media only screen and (max-width: 500px) {
  th,
  td {
    font-size: 14px;
  }
}
</style>
