import './assets/main.css'
// import './assets/js/app.js'
// import './assets/js/plugin.js'
// import './assets/libs/jquery-countdown/jquery.countdown.min.js'
// import './assets/libs/owl.carousel/owl.carousel.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'
import VueSocketIO from "vue-3-socket.io";

const app = createApp(App)

// karena semua yang ada pada .env adalah string, kita harus convert dulu
let socketio_debug = true;
if (import.meta.env.VITE_SOCKETIO_DEBUG == "true") {
  socketio_debug = true;
} else {
  socketio_debug = false;
}

app.use(createPinia())
app.use(router)
app.use(
  new VueSocketIO({
    debug: socketio_debug,
    // connection: SocketIO(process.env.VUE_APP_WS)
    connection: import.meta.env.VITE_BOT_WS,
  })
);

Sentry.init({
  dsn: 'https://d1c44d3c7789c7025a5abcefdbe1c2b2@o4506371002335232.ingest.sentry.io/4506471800700928',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/wavedex\.io\/api/]
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    })
  ],

  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

app.mount('#app')
