<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <BreadcrumbsBar />
        <SubcriptionsComponent />
        <RecentSubscribe />
        <FooterBar />
      </div>
    </div>
  </div>
</template>

<script>
// components
import BreadcrumbsBar from '../global/BreadcrumbsBar.vue'
import FooterBar from '../global/FooterBar.vue'
import SubcriptionsComponent from '../subscriptions/SubcriptionsComponet.vue'
import RecentSubscribe from '../subscriptions/RecentSubscribe.vue'

export default {
  components: { BreadcrumbsBar, SubcriptionsComponent, FooterBar, RecentSubscribe }
}
</script>

<style scoped></style>
