<template>
  <div id="layout-wrapper">
    <NavBar />
    <SideBar />
    <ProfileMain />
  </div>

  <div class="rightbar-overlay"></div>

  <ModalSession :show="isShowModalSession" @close="isShowModalSession = false"></ModalSession>
</template>

<script>
// stores
import { useSessionStore } from '@/stores/session-store'

// components
import SideBar from '../../components/dashboard/global/SideBar.vue'
import NavBar from '../../components/dashboard/global/NavBar.vue'
import ProfileMain from '../../components/dashboard/main/ProfileMain.vue'
import ModalSession from '../../components/modals/ModalSession.vue'

export default {
  components: {
    SideBar,
    NavBar,
    ProfileMain,
    ModalSession
  },
  data() {
    return {
      store: useSessionStore(),
      isShowModalSession: false
    }
  },
  created() {
    this.store.authenticate()
    this.store.checkSessionTimeout()
  },
  watch: {
    'store.isSessionTimeEnd': {
      handler() {
        if (this.store.isSessionTimeEnd) {
          this.isShowModalSession = true
        }
      }
    }
  }
}
</script>
