<template>
  <!-- Roadmap start -->
  <section class="section bg-white" id="roadmap">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <div class="small-title">Timeline</div>
            <h4>Our Roadmap</h4>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row mt-4">
        <div class="col-lg-12">
          <div class="hori-timeline" dir="ltr">
            <swiper
              class="d-flex events"
              :slides-per-view="perView"
              :grabCursor="true"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }"
              :modules="modules"
            >
              <swiper-slide v-for="(item, index) in items" :key="index">
                <div :class="index === 3 && 'active'" class="item event-list">
                  <div>
                    <div class="event-date">
                      <div class="text-primary mb-1">{{ item.date }}</div>
                      <h5 class="mb-4">{{ item.title }}</h5>
                    </div>
                    <div class="event-down-icon">
                      <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                    </div>

                    <div class="mt-3 px-3">
                      <p class="text-muted">{{ item.body }}</p>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>

            <div class="d-flex gap-3 mt-4 justify-content-center">
              <div class="swiper-button-prev">
                <div class="mdi mdi-chevron-left"></div>
              </div>
              <div class="swiper-button-next">
                <div class="mdi mdi-chevron-right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Roadmap end -->
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'

import 'swiper/css'

export default {
  components: { Swiper, SwiperSlide },
  data() {
    return {
      perView: 4,
      items: [
        {
          date: 'June, 2023',
          title: 'Wavedex Platform Idea',
          body: 'Team and networkers idea meet up discussion'
        },
        {
          date: 'August, 2023',
          title: 'Research on project',
          body: 'Development team research on project and marketing plan'
        },
        {
          date: 'September, 2023',
          title: 'UI & UX Development',
          body: 'Development start and Marketing team blast'
        },
        {
          date: 'October, 2023',
          title: 'Wavedex Pre-Register',
          body: 'Pre-register opening before the grand opening'
        },
        {
          date: 'December, 2023',
          title: 'Official Launch',
          body: 'Wavedex.io Officially Launch'
        },
        {
          date: 'March, 2024',
          title: 'Arbitrage Features',
          body: 'Arbitrage features activated'
        },
        {
          date: 'December, 2024',
          title: 'DEX Features',
          body: 'Full DEX Features Activated'
        }
      ]
    }
  },
  setup() {
    return {
      modules: [Navigation]
    }
  },
  mounted() {
    this.handlePerview()
  },
  methods: {
    handlePerview() {
      const widthScreen = window.innerWidth
      if (widthScreen > 802) {
        this.perView = 4
      } else if (widthScreen < 802) {
        this.perView = 2
      }
    }
  }
}
</script>

<style scoped>
.swiper-button-next,
.swiper-button-prev {
  width: 30px;
  height: 30px;
  background-color: #d4dbf9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
  cursor: auto;
}
.mdi-chevron-left,
.mdi-chevron-right {
  color: #5983eb;
  font-size: 20px;
}
</style>
