<template>
  <div id="layout-wrapper">
    <NavBar />
    <SideBar />
    <div v-if="loading" class="loader_itm">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <DetailComponents
      v-else
      :datas="datas"
      :network="network"
      :dateUpdate="dateUpdate"
      :paymentStatus="paymentStatus"
      :id="id"
    />
  </div>
</template>

<script>
// stores
import { useSubscription } from '@/stores/subscription-store'
import { useSessionStore } from '@/stores/session-store'
import { computed } from 'vue'

// componets
import DetailComponents from '../global/DetailComponents.vue'
import SideBar from '../global/SideBar.vue'
import NavBar from '../global/NavBar.vue'

export default {
  components: {
    SideBar,
    NavBar,
    DetailComponents
  },
  data() {
    return {
      store: useSubscription(),
      storeSession: useSessionStore(),
      datas: computed(() => this.store.subsDetail),
      network: computed(() => this.store.network),
      dateUpdate: computed(() => this.store.dateUpdate),
      loading: computed(() => this.store.loading),
      paymentStatus: computed(() => this.store.paymentStatus),
      id: null
    }
  },
  created() {
    this.storeSession.authenticate()
    this.storeSession.checkSessionTimeout()
  },
  mounted() {
    this.subsDetail()
    console.log('datas:', this.datas)
  },
  methods: {
    subsDetail() {
      const route = this.$route
      const id = route.params.id
      this.id = id
      this.store.getSubsDetail(id)
    }
  }
}
</script>
