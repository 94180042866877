<template>
  <!-- about section start -->
  <section class="section pt-4 bg-white" id="about">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <div class="small-title">About us</div>
            <h4>What is WaveDex</h4>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="text-muted">
            <h4>REVOLUTIONIZING THE FUTURE OF TRADING</h4>
            <p>
              As a leading platform service provider, we empower traders and investors with
              innovative tools and technologies, ushering in a new era of efficiency, transparency,
              and profitability in the world of digital assets.
            </p>

            <div class="d-flex flex-wrap gap-2">
              <div class="d-flex flex-wrap gap-2">
                <button
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasScrolling"
                  aria-controls="offcanvasScrolling"
                >
                  Read more
                </button>

                <div
                  class="offcanvas offcanvas-start"
                  data-bs-scroll="true"
                  data-bs-backdrop="false"
                  tabindex="-1"
                  id="offcanvasScrolling"
                  aria-labelledby="offcanvasScrollingLabel"
                >
                  <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasScrollingLabel">
                      Wavedex Platform Subscription
                    </h5>
                    <button
                      type="button"
                      class="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="offcanvas-body">
                    <p>
                      At WaveDex, we offer an annual subscription for a mere USD 100, granting our
                      valued clients access to an array of platform features meticulously crafted to
                      elevate their trading journey. Each of these features is meticulously designed
                      with unique advantages, simplifying the process for users to optimize their
                      earnings.
                    </p>

                    <h6>• USD15 Monthly</h6>
                    <h6>• USD100 Yearly</h6>
                    <h6>• USD250 3 Years</h6>
                  </div>
                </div>
                <div
                  class="offcanvas offcanvas-start"
                  tabindex="-1"
                  id="offcanvasWithBackdrop"
                  aria-labelledby="offcanvasWithBackdropLabel"
                >
                  <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasWithBackdropLabel">
                      Offcanvas with backdrop
                    </h5>
                    <button
                      type="button"
                      class="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="offcanvas-body">
                    <p>.....</p>
                  </div>
                  <div
                    class="offcanvas offcanvas-start"
                    data-bs-scroll="true"
                    tabindex="-1"
                    id="offcanvasWithBothOptions"
                    aria-labelledby="offcanvasWithBothOptionsLabel"
                  >
                    <div class="offcanvas-header">
                      <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">
                        Backdroped with scrolling
                      </h5>
                      <button
                        type="button"
                        class="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="offcanvas-body">
                      <p>Try scrolling the rest of the page to see this option in action.</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <a href="javascript: void(0);" class="btn btn-outline-primary">How It work</a> -->
            </div>

            <div class="row mt-4">
              <div class="col-lg-4 col-6">
                <div class="mt-4">
                  <h4>{{ users }}</h4>
                  <p>Users</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->

        <hr class="my-5" />

        <div class="row">
          <div class="col-lg-12">
            <CarauselSection :images="logos" />
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- about section end -->
</template>

<script>
import service from '@/utils/service'

import CarauselSection from '../home/CarauselSection.vue'
export default {
  components: {
    CarauselSection
  },
  data() {
    return {
      logos: [
        { id: '1', img: 'https://i.ibb.co/1vYHwnd/1.png' },
        { id: '2', img: 'https://i.ibb.co/H2znf3f/2.png' },
        { id: '3', img: 'https://i.ibb.co/Jqc42qm/3.png' },
        { id: '4', img: 'https://i.ibb.co/b73SP4k/4.png' },
        { id: '5', img: 'https://i.ibb.co/4tZMfmc/6.png' }
      ],
      users: 0
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      service
        .get('/home/total-user')
        .then((response) => {
          this.users = response.data
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }
}
</script>

<style scoped>
.offcanvas-body p {
  text-align: justify;
  text-justify: inter-word;
}
</style>
