// store.js
import { defineStore } from 'pinia'

export const usePaginationStore = defineStore('pagination', {
  state: () => ({
    currentPage: 0,
    itemsPerPage: 10,
    totalPages: null
  }),
  getters: {
    paginatedData(state) {
      return (items) => {
        this.calculatePages(items)
        const start = state.currentPage * state.itemsPerPage
        const end = start + state.itemsPerPage
        return items.slice(start, end)
      }
    }
  },
  actions: {
    changePage(page) {
      this.currentPage = page
    },
    nextPage(totalItems) {
      if ((this.currentPage + 1) * this.itemsPerPage < totalItems) {
        this.changePage(this.currentPage + 1)
      }
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.changePage(this.currentPage - 1)
      }
    },
    calculatePages(items) {
      this.totalPages = Math.ceil(items?.length / this.itemsPerPage)
    },
    resetPagination() {
      this.currentPage = 0
    }
  }
})
