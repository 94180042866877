<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <svg
            @click="$emit('close')"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M14 0C6.2 0 0 6.2 0 14C0 21.8 6.2 28 14 28C21.8 28 28 21.8 28 14C28 6.2 21.8 0 14 0ZM19.4 21L14 15.6L8.6 21L7 19.4L12.4 14L7 8.6L8.6 7L14 12.4L19.4 7L21 8.6L15.6 14L21 19.4L19.4 21Z"
              fill="#E62A07"
            />
          </svg>
        </div>
        <div class="modal-body">
          <ul class="metismenu list-unstyled">
            <li class="menu-title" key="t-menu">Menu</li>
            <li>
              <ul class="sub-menu ml-4 menu_modal">
                <!-- <router-link to="/dashboard/new" key="t-default" class="menu_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                  >
                    <path
                      fill="currentColor"
                      d="M7.825.12a.5.5 0 0 0-.65 0L0 6.27v7.23A1.5 1.5 0 0 0 1.5 15h4a.5.5 0 0 0 .5-.5v-3a1.5 1.5 0 0 1 3 0v3a.5.5 0 0 0 .5.5h4a1.5 1.5 0 0 0 1.5-1.5V6.27L7.825.12Z"
                    />
                  </svg>
                  <span>Home</span>
                </router-link> -->

                <router-link to="/dashboard" key="t-dashboard" class="menu_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="18"
                    width="18"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M14,10V22H4a2,2,0,0,1-2-2V10Z"></path>
                    <path d="M22,10V20a2,2,0,0,1-2,2H16V10Z"></path>
                    <path d="M22,4V8H2V4A2,2,0,0,1,4,2H20A2,2,0,0,1,22,4Z"></path>
                  </svg>
                  <span>Dashboard</span>
                </router-link>

                <router-link to="/dashboard/subscriptions" key="t-wallet" class="menu_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M14 10H3v2h11v-2m0-4H3v2h11V6M3 16h7v-2H3v2m18.5-4.5L23 13l-7 7l-4.5-4.5L13 14l3 3l5.5-5.5Z"
                    />
                  </svg>
                  <span>Subscriptions</span>
                </router-link>

                <router-link to="/dashboard/client" key="t-client" class="menu_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none">
                      <path
                        d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"
                      />
                      <path
                        fill="currentColor"
                        d="M13 13a4 4 0 0 1 4 4v1.5a1.5 1.5 0 0 1-1.5 1.5h-12A1.5 1.5 0 0 1 2 18.5V17a4 4 0 0 1 4-4h7Zm6 0a3 3 0 0 1 3 3v1.5a1.5 1.5 0 0 1-1.5 1.5H19v-2a4.992 4.992 0 0 0-2-4h2ZM9.5 3a4.5 4.5 0 1 1 0 9a4.5 4.5 0 0 1 0-9ZM18 6a3 3 0 1 1 0 6a3 3 0 0 1 0-6Z"
                      />
                    </g>
                  </svg>
                  <span>Client</span>
                </router-link>

                <router-link to="/dashboard/deposit" key="t-wallet" class="menu_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M17.12 9.88a2.997 2.997 0 1 0-4.24 4.24a2.997 2.997 0 1 0 4.24-4.24M7 6v12h16V6H7m14 8c-.53 0-1.04.21-1.41.59c-.38.37-.59.88-.59 1.41h-8c0-.53-.21-1.04-.59-1.41c-.37-.38-.88-.59-1.41-.59v-4c.53 0 1.04-.21 1.41-.59c.38-.37.59-.88.59-1.41h8c0 .53.21 1.04.59 1.41c.37.38.88.59 1.41.59v4M5 8H3c-.55 0-1-.45-1-1s.45-1 1-1h2v2m0 5H2c-.55 0-1-.45-1-1s.45-1 1-1h3v2m0 5H1c-.552 0-1-.45-1-1s.448-1 1-1h4v2Z"
                    />
                  </svg>
                  <span>Deposit</span>
                </router-link>

                <router-link
                  to="/dashboard/withdraw/profit?tab=deposit"
                  key="t-wallet"
                  class="menu_item"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 15c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92c0-1.12-.52-3-4-3c-2 0-2-.63-2-1s.7-1 2-1s1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3c2 0 2 .68 2 1s-.62 1-2 1z"
                    />
                    <path
                      fill="currentColor"
                      d="M5 2H2v2h2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4h2V2H5zm13 18H6V4h12z"
                    />
                  </svg>
                  <span>Withdraw</span>
                </router-link>

                <router-link to="/dashboard/profile" key="t-profile" class="menu_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M12 4a8 8 0 0 0-6.96 11.947A4.99 4.99 0 0 1 9 14h6a4.99 4.99 0 0 1 3.96 1.947A8 8 0 0 0 12 4Zm7.943 14.076A9.959 9.959 0 0 0 22 12c0-5.523-4.477-10-10-10S2 6.477 2 12a9.958 9.958 0 0 0 2.057 6.076l-.005.018l.355.413A9.98 9.98 0 0 0 12 22a9.947 9.947 0 0 0 5.675-1.765a10.055 10.055 0 0 0 1.918-1.728l.355-.413l-.005-.018ZM12 6a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span key="t-profile">Profile</span>
                </router-link>

                <router-link to="/dashboard/two-factor-panel" key="t-wallet" class="menu_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="20"
                    viewBox="0 0 24 32"
                    fill="none"
                  >
                    <path
                      d="M12 24.381C12.7956 24.381 13.5587 24.0599 14.1213 23.4883C14.6839 22.9168 15 22.1416 15 21.3333C15 20.5251 14.6839 19.7499 14.1213 19.1783C13.5587 18.6068 12.7956 18.2857 12 18.2857C11.2044 18.2857 10.4413 18.6068 9.87868 19.1783C9.31607 19.7499 9 20.5251 9 21.3333C9 22.1416 9.31607 22.9168 9.87868 23.4883C10.4413 24.0599 11.2044 24.381 12 24.381ZM21 10.6667C21.7956 10.6667 22.5587 10.9878 23.1213 11.5593C23.6839 12.1308 24 12.906 24 13.7143V28.9524C24 29.7607 23.6839 30.5358 23.1213 31.1074C22.5587 31.6789 21.7956 32 21 32H3C2.20435 32 1.44129 31.6789 0.87868 31.1074C0.31607 30.5358 0 29.7607 0 28.9524V13.7143C0 12.906 0.31607 12.1308 0.87868 11.5593C1.44129 10.9878 2.20435 10.6667 3 10.6667H4.5V7.61905C4.5 5.59835 5.29018 3.66042 6.6967 2.23157C8.10322 0.802719 10.0109 0 12 0C12.9849 0 13.9602 0.197073 14.8701 0.579966C15.7801 0.962858 16.6069 1.52407 17.3033 2.23157C17.9997 2.93906 18.5522 3.77898 18.9291 4.70336C19.306 5.62775 19.5 6.6185 19.5 7.61905V10.6667H21ZM12 3.04762C10.8065 3.04762 9.66193 3.52925 8.81802 4.38656C7.97411 5.24387 7.5 6.40663 7.5 7.61905V10.6667H16.5V7.61905C16.5 6.40663 16.0259 5.24387 15.182 4.38656C14.3381 3.52925 13.1935 3.04762 12 3.04762Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span>Security</span>
                </router-link>
                <a
                  href="http://kb.wavedex.io/"
                  target="_blank"
                  key="t-knowledge-base"
                  class="menu_item"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="20"
                    viewBox="0 0 11 12"
                    fill="none"
                  >
                    <path
                      d="M10 5C8.37474 5.09426 6.81165 5.65767 5.5 6.622C4.18835 5.65767 2.62526 5.09426 1 5V6.48C0.734341 6.48053 0.479764 6.58654 0.292242 6.77472C0.10472 6.96289 -0.000397097 7.21784 1.12735e-06 7.4835V7.9855C1.12735e-06 8.54 1 9.0895 1 9.0895V10.093C2.328 10.1784 3.60873 10.6187 4.7085 11.368C4.98619 11.5607 5.25062 11.7718 5.5 12C5.74938 11.7718 6.01381 11.5607 6.2915 11.368C7.39127 10.6187 8.672 10.1784 10 10.093V8.989C10.2657 8.98847 10.5202 8.88246 10.7078 8.69428C10.8953 8.50611 11.0004 8.25116 11 7.9855V7.4835C11.0004 7.21784 10.8953 6.96289 10.7078 6.77472C10.5202 6.58654 10.2657 6.48053 10 6.48V5ZM5.5 10.711C4.46299 9.95102 3.26573 9.43859 2 9.213V6.138C2.97004 6.33342 3.89103 6.7214 4.7085 7.279C4.98619 7.4717 5.25062 7.68284 5.5 7.911C5.74938 7.68284 6.01381 7.4717 6.2915 7.279C7.10893 6.72122 8.02992 6.33307 9 6.1375V9.213C7.73427 9.43859 6.53701 9.95102 5.5 10.711ZM5 4H6V4.5H5V4ZM5 2H6V2.5H5V2Z"
                      fill="currentColor"
                    />
                    <path
                      d="M5.1984 4.62453H6V5H5.1984V4.62453ZM4.3968 2.74719C4.5031 2.74719 4.60504 2.70763 4.68021 2.63722C4.75537 2.56681 4.7976 2.4713 4.7976 2.37172C4.79797 2.32281 4.78796 2.27432 4.76815 2.22906C4.74835 2.18381 4.71913 2.14269 4.68221 2.1081C4.64529 2.07352 4.6014 2.04615 4.55309 2.02759C4.50478 2.00904 4.45302 1.99966 4.40081 2.00001C4.34843 1.99976 4.29652 2.00918 4.24804 2.02773C4.19956 2.04628 4.15544 2.07359 4.11823 2.1081C4.04306 2.17781 4.00054 2.27264 4.00001 2.37172C3.99947 2.47081 4.04098 2.56603 4.11539 2.63644C4.18981 2.70686 4.29103 2.74669 4.3968 2.74719Z"
                      fill="currentColor"
                    />
                    <path
                      d="M6.5 2C6.77614 2 7 1.77614 7 1.5C7 1.22386 6.77614 1 6.5 1C6.22386 1 6 1.22386 6 1.5C6 1.77614 6.22386 2 6.5 2Z"
                      fill="currentColor"
                    />
                    <path
                      d="M7.5 4H3.5C3.1023 3.9996 2.721 3.84144 2.43978 3.56022C2.15856 3.279 2.0004 2.8977 2 2.5V1.5C2.0004 1.1023 2.15856 0.720997 2.43978 0.439778C2.721 0.15856 3.1023 0.000397108 3.5 0L7.5 0C7.8977 0.000397108 8.279 0.15856 8.56022 0.439778C8.84144 0.720997 8.9996 1.1023 9 1.5V2.5C8.9996 2.8977 8.84144 3.279 8.56022 3.56022C8.279 3.84144 7.8977 3.9996 7.5 4ZM3.5 1C3.36743 1.00013 3.24033 1.05285 3.14659 1.14659C3.05285 1.24033 3.00013 1.36743 3 1.5V2.5C3.00013 2.63257 3.05285 2.75967 3.14659 2.85341C3.24033 2.94715 3.36743 2.99987 3.5 3H7.5C7.63257 2.99987 7.75967 2.94715 7.85341 2.85341C7.94715 2.75967 7.99987 2.63257 8 2.5V1.5C7.99987 1.36743 7.94715 1.24033 7.85341 1.14659C7.75967 1.05285 7.63257 1.00013 7.5 1H3.5Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span>Knowledge Base</span>
                </a>

                <!-- <router-link to="/dashboard/change-password" key="t-password" class="menu_item">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M19 13c.34 0 .67.04 1 .09V10a2 2 0 0 0-2-2h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6a2 2 0 0 0-2 2v10c0 1.11.89 2 2 2h7.81c-.51-.88-.81-1.9-.81-3c0-3.31 2.69-6 6-6M9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6m3 11a2 2 0 1 1 2-2c0 1.11-.89 2-2 2m11 1v2h-3v3h-2v-3h-3v-2h3v-3h2v3h3Z"
                  />
                </svg>
                <span key="t-password">Change Password</span>
              </router-link>

              <router-link to="/dashboard/security" key="t-default" class="menu_item">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12 22q-3.475-.875-5.738-3.988T4 11.1V5l8-3l8 3v6.1q0 3.8-2.262 6.913T12 22Zm0-2.1q2.425-.75 4.05-2.963T17.95 12H12V4.125l-6 2.25v5.175q0 .175.05.45H12v7.9Z"
                  />
                </svg>
                <span>Security</span>
              </router-link> -->

                <a key="t-default" class="menu_item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="#F36A6A"
                      d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8c-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9a353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8c47.9 0 94.3 9.3 137.9 27.8c42.2 17.8 80.1 43.4 112.7 75.9c7.9 7.9 15.3 16.1 22.4 24.5c3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82C271.7 82.6 79.6 277.1 82 516.4C84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7c3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z"
                    />
                  </svg>
                  <span @click="logoutUser" class="text-danger">Log Out</span>
                </a>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// stores
import { useProfile } from '@/stores/profile-store'

export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      store: useProfile()
    }
  },
  methods: {
    logoutUser() {
      this.store.logOut()
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: start;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 100%;
  height: 100%;
  /* margin: auto; */
  padding: 20px 30px;
  background-color: rgb(42, 48, 66);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.h_wrapper {
  width: 100%;
  justify-content: space-between;
}

.btn_close {
  cursor: pointer;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.menu_modal {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.router-link-active a {
  font-weight: 700;
  color: #ffff;
}
.sub-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sub-menu a:hover {
  color: #ffff;
  font-size: 700;
}
.t-logout {
  cursor: pointer;
}
.menu_item {
  color: aliceblue;
  display: flex;
  align-items: center;
}
.menu_item span {
  margin-left: 5px;
}
</style>
