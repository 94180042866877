<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5 text-muted">
            <router-link to="/" class="d-block auth-logo">
              <img
                src="../../assets/images/logo-dark.png"
                alt=""
                height="20"
                class="auth-logo-dark mx-auto"
              />
              <img
                src="../../assets/images/logo-light.png"
                alt=""
                height="20"
                class="auth-logo-light mx-auto"
              />
            </router-link>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div class="card-body">
              <div class="p-2">
                <div class="text-center">
                  <div class="avatar-md mx-auto">
                    <div class="avatar-title rounded-circle bg-light">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="32"
                        viewBox="0 0 24 32"
                        fill="none"
                      >
                        <path
                          d="M12 24.381C12.7956 24.381 13.5587 24.0599 14.1213 23.4883C14.6839 22.9168 15 22.1416 15 21.3333C15 20.5251 14.6839 19.7499 14.1213 19.1783C13.5587 18.6068 12.7956 18.2857 12 18.2857C11.2044 18.2857 10.4413 18.6068 9.87868 19.1783C9.31607 19.7499 9 20.5251 9 21.3333C9 22.1416 9.31607 22.9168 9.87868 23.4883C10.4413 24.0599 11.2044 24.381 12 24.381ZM21 10.6667C21.7956 10.6667 22.5587 10.9878 23.1213 11.5593C23.6839 12.1308 24 12.906 24 13.7143V28.9524C24 29.7607 23.6839 30.5358 23.1213 31.1074C22.5587 31.6789 21.7956 32 21 32H3C2.20435 32 1.44129 31.6789 0.87868 31.1074C0.31607 30.5358 0 29.7607 0 28.9524V13.7143C0 12.906 0.31607 12.1308 0.87868 11.5593C1.44129 10.9878 2.20435 10.6667 3 10.6667H4.5V7.61905C4.5 5.59835 5.29018 3.66042 6.6967 2.23157C8.10322 0.802719 10.0109 0 12 0C12.9849 0 13.9602 0.197073 14.8701 0.579966C15.7801 0.962858 16.6069 1.52407 17.3033 2.23157C17.9997 2.93906 18.5522 3.77898 18.9291 4.70336C19.306 5.62775 19.5 6.6185 19.5 7.61905V10.6667H21ZM12 3.04762C10.8065 3.04762 9.66193 3.52925 8.81802 4.38656C7.97411 5.24387 7.5 6.40663 7.5 7.61905V10.6667H16.5V7.61905C16.5 6.40663 16.0259 5.24387 15.182 4.38656C14.3381 3.52925 13.1935 3.04762 12 3.04762Z"
                          fill="#0D6EFD"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="p-2 mt-4">
                    <h4>Google Authenticator</h4>
                    <p class="mb-5">Please enter the 6 digit Google Authenticator</p>

                    <div class="d-flex align-items-center justify-content-center">
                      <otp v-model="google_secret" :count="6" ref="otpComponent">
                        <template v-slot="{ digits, onInput, onPaste, onBlur }">
                          <div class="d-flex align-items-center mx-4">
                            <otp-group class="d-flex mx-2 p-2">
                              <template v-slot="{ focusNext, focusPrev }">
                                <otp-group-input
                                  v-for="(digit, index) in digits"
                                  :key="index"
                                  :value="digit"
                                  autofocus
                                  placeholder="*"
                                  @blur="onBlur"
                                  @next="focusNext(index)"
                                  @prev="focusPrev(index)"
                                  @paste="onPaste(index, $event)"
                                  @input="onInput(index, $event)"
                                  class="form-control h-12 w-12 rounded-xl text-3xl text-center text-muted border-2 border-muted placeholder-text-muted focus-outline-none focus-border-teal"
                                />
                              </template>
                            </otp-group>

                            <button class="btn" @click="clearPin">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 21 21"
                                fill="none"
                              >
                                <path
                                  d="M3.92996 18.07C2.97486 17.1475 2.21303 16.0441 1.68894 14.824C1.16485 13.604 0.888991 12.2918 0.877453 10.964C0.865915 9.63622 1.11893 8.31943 1.62174 7.09046C2.12455 5.8615 2.86708 4.74498 3.80601 3.80605C4.74493 2.86713 5.86145 2.1246 7.09042 1.62179C8.31938 1.11898 9.63618 0.86596 10.964 0.877499C12.2918 0.889037 13.604 1.1649 14.824 1.68899C16.044 2.21308 17.1475 2.9749 18.07 3.93C19.8915 5.81602 20.8995 8.34205 20.8767 10.964C20.8539 13.586 19.8022 16.0941 17.9481 17.9482C16.0941 19.8023 13.5859 20.854 10.964 20.8767C8.342 20.8995 5.81598 19.8916 3.92996 18.07ZM5.33996 16.66C6.84108 18.1611 8.87705 19.0045 11 19.0045C13.1229 19.0045 15.1588 18.1611 16.66 16.66C18.1611 15.1589 19.0044 13.1229 19.0044 11C19.0044 8.87709 18.1611 6.84113 16.66 5.34C15.1588 3.83888 13.1229 2.99555 11 2.99555C8.87705 2.99555 6.84108 3.83888 5.33996 5.34C3.83883 6.84113 2.99551 8.87709 2.99551 11C2.99551 13.1229 3.83883 15.1589 5.33996 16.66ZM15.24 8.17L12.41 11L15.24 13.83L13.83 15.24L11 12.41L8.16996 15.24L6.75996 13.83L9.58996 11L6.75996 8.17L8.16996 6.76L11 9.59L13.83 6.76L15.24 8.17Z"
                                  fill="#ED4C4C"
                                />
                              </svg>
                            </button>
                          </div>
                          <span v-if="message != null" class="text-danger">{{ message }}</span>
                        </template>
                      </otp>
                    </div>

                    <div class="mt-4">
                      <button @click="submitForm" class="btn btn-success w-md">Confirm</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p>© {{ dateTime }} Wavedex.io</p>
          </div>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <modal-reusable
        :show="showModal"
        @close="showModal = false"
        :typeSuccess="true"
        :isSuccess="false"
        :resMsg="resMsg"
      >
      </modal-reusable>
    </Teleport>
  </div>
</template>

<script>
// node_modules
import Cookies from 'js-cookie'

// applibs
import thestore from '@/applibs/thestore.js'

// components
import { Otp, OtpGroup, OtpGroupInput } from '@/components/otp'
import ModalReusable from '@/components/modals/ModalReusable.vue'

export default {
  components: {
    Otp,
    OtpGroup,
    OtpGroupInput,
    ModalReusable
  },
  data() {
    return {
      email: Cookies.get('temp_email'),
      password: Cookies.get('temp_password'),
      google_secret: '',
      message: null,
      showModal: false,
      resMsg: ''
    }
  },

  //   async beforeMount() {
  //     var this = this

  //     if (this.email && this.email != '' && this.password && this.password != '') {
  //       console.log('do nothing')
  //     } else {
  //       Cookies.remove('jwt')
  //       Cookies.remove('usid')
  //       Cookies.remove('temp_email')
  //       Cookies.remove('temp_password')
  //       this.$router.push('/')
  //     }
  //   },

  watch: {
    google_secret(google_secret) {
      if (google_secret) {
        this.message = null
      }
    }
  },

  methods: {
    async submitForm() {
      const emailEncryp = this.email
      const passwordEncryp = this.password

      if (this.google_secret != '') {
        var res = await thestore.postAuthLoginTwoFa(this.email, this.password, this.google_secret)

        if (res.success == true && res.action == 'login_complete') {
          Cookies.set('jwt', res.token, { expires: 1 })
          Cookies.set('usid', res.usid, { expires: 1 })
          window.location.href = '/dashboard'
        } else if (res.success == true && res.action == '2fa') {
          Cookies.set('temp_email', emailEncryp, { expires: 1 })
          Cookies.set('temp_password', passwordEncryp, { expires: 1 })
          Cookies.set('jwt', res.token, { expires: 1 })
          Cookies.set('usid', res.usid, { expires: 1 })
          this.$router.push('/auth/two-factor-auth')
        } else {
          this.showModal = true
          this.resMsg = res.msg
          setTimeout(() => {
            this.showModal = false
            this.clearPin()
            this.message = null
          }, 3000)
        }
      } else {
        this.message = 'Code cannot be empty.'
      }
    },
    clearPin() {
      this.message = null
      this.google_secret = ''
      this.$refs.otpComponent.clearDigits()
    }
  }
}
</script>

<style scoped>
.form-control {
  margin-right: 5px;
  padding: 3px;
}

@media only screen and (max-width: 500px) {
  .form-control {
    width: 30px;
    height: 30px;
    padding: 0;
  }
}
</style>
