<template>
  <div id="layout-wrapper">
    <NavBar />
    <SideBar />
    <div v-if="loading" class="loader_itm">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <DetailComponents
      v-else
      :datas="datas"
      :network="network"
      :dateUpdate="dateUpdate"
      :paymentStatus="paymentStatus"
      :id="id"
    />
  </div>
</template>

<script>
// stores
import { useWalletHistory } from '@/stores/wallet-store'
import { computed } from 'vue'

// componets
import DetailComponents from '../../global/DetailComponents.vue'
import SideBar from '../../global/SideBar.vue'
import NavBar from '../../global/NavBar.vue'

export default {
  components: {
    SideBar,
    NavBar,
    DetailComponents
  },
  data() {
    return {
      store: useWalletHistory(),
      datas: computed(() => this.store.depositDetail),
      network: computed(() => this.store.network),
      dateUpdate: computed(() => this.store.dateUpdate),
      loading: computed(() => this.store.loading),
      paymentStatus: computed(() => this.store.paymentStatus),
      id: null
    }
  },
  async mounted() {
    this.subsDetail()
  },
  methods: {
    async subsDetail() {
      const route = this.$route
      const id = route.params.id
      this.id = id
      this.store.getDepositDetail(id)
    }
  }
}
</script>
