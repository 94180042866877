import { ref } from 'vue'
import { defineStore } from 'pinia'
import service from '@/utils/service'

export const useWalletHistory = defineStore('history', () => {
  const withdrawHistory = ref([])
  const profitHistory = ref([])
  const depositHistory = ref([])
  const depositDetail = ref([])
  const network = ref('')
  const dateUpdate = ref(null)
  const loading = ref(false)
  const paymentStatus = ref(null)
  const addressWalletA = ref('')
  const networkWalletA = ref('')

  function getWitdhrowHistory(page) {
    loading.value = true
    service
      .get(`/withdraws?page=${page}&filter_rules=[{"field": "paid", "op": "boolean", "value": true}, {"field": "claimed", "op": "boolean", "value": false}]`, { headers: { requiresAuth: true } })
      .then((response) => {
        withdrawHistory.value = response.data.datas
      })
      .catch((error) => {
        console.error('Error Aman:', error)
      })
      .finally(() => {
        loading.value = false
      })
  }

  function getProfitHistory(page) {
    loading.value = true
    service
      .get(`/withdraws/history-profit?page=${page}`, { headers: { requiresAuth: true } })
      .then((response) => {
        profitHistory.value = response.data.datas
      })
      .catch((error) => {
        console.error('Error Aman:', error)
      })
      .finally(() => {
        loading.value = false
      })
  }

  function getDepositHistory(page) {
    loading.value = true
    service
      .get(`/deposits?page=${page}`, { headers: { requiresAuth: true } })
      .then((response) => {
        depositHistory.value = response.data.datas
      })
      .catch((error) => {
        console.error('Error Aman:', error)
      })
      .finally(() => {
        loading.value = false
      })
  }

  function getDepositDetail(id) {
    loading.value = true
    service
      .get(`/deposits?filter_rules=[{"field":"id", "op":"equal-num", "value":"${id}"}]`, { headers: { requiresAuth: true } })
      .then((response) => {
        depositDetail.value = response.data.datas[0].coinpayment_res.result
        network.value = response.data.datas[0].network
        dateUpdate.value = response.data.datas[0].updated_at
        paymentStatus.value = response.data.datas[0].paid
      })
      .catch((error) => {
        console.error('Error Aman:', error)
      })
      .finally(() => {
        loading.value = false
      })
  }

  function getMyWallet() {
    service.get('/profile/my-wallet', { headers: { requiresAuth: true } })
    .then((res) => {
      console.log('wallet: ', res)
      addressWalletA.value = res.data.address
      networkWalletA.value = res.data.currency_network
    })
    .catch((error) => {
      console.error('Error Aman:', error)
    })
  }

  return {
    loading,
    withdrawHistory,
    profitHistory,
    depositHistory,
    depositDetail,
    network,
    dateUpdate,
    paymentStatus,
    addressWalletA,
    networkWalletA,
    getWitdhrowHistory,
    getProfitHistory,
    getDepositHistory,
    getDepositDetail,
    getMyWallet
  }
})
