<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container" :class="{ bgDark: isDarkMode }">
        <div class="modal-body">
          <div class="h-100 h_wrapper">
            <div class="rightbar-title d-flex align-items-center justify-content-between px-3 py-4">
              <h5 class="m-0 me-2">Themes</h5>

              <div @click="$emit('close')" class="btn_close">
                <i class="mdi mdi-close noti-icon"></i>
              </div>
            </div>

            <!-- Settings -->
            <hr class="mt-0" />
            <h6 class="text-center mb-0">Choose Themes</h6>

            <div class="p-4">
              <!-- <div class="mb-2">
                <img
                  src="../../assets/images/layouts/layout-1.jpg"
                  class="img-thumbnail"
                  alt="layout images"
                />
              </div> -->

              <div @click="toggleDarkMode" class="form-check form-switch mb-3">
                <input
                  class="form-check-input theme-choice"
                  type="checkbox"
                  id="light-mode-switch"
                  :checked="!isDarkMode"
                />
                <label class="form-check-label" for="light-mode-switch">Light Mode</label>
              </div>

              <!-- <div class="mb-2">
                <img
                  src="../../assets/images/layouts/layout-2.jpg"
                  class="img-thumbnail"
                  alt="layout images"
                />
              </div> -->
              <div @click="toggleDarkMode" class="form-check form-switch mb-3">
                <input
                  class="form-check-input theme-choice"
                  type="checkbox"
                  id="dark-mode-switch"
                  :checked="isDarkMode"
                />
                <label class="form-check-label" for="dark-mode-switch">Dark Mode</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// modules
import Cookies from 'js-cookie'

export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      isDarkMode: false
    }
  },
  mounted() {
    if (Cookies.get('theme') === 'dark') {
      this.isDarkMode = true
    } else {
      this.isDarkMode = false
    }
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode
      const theme = this.isDarkMode ? 'dark' : 'light'
      document.documentElement.setAttribute('data-bs-theme', theme)
      Cookies.set('theme', theme)
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: flex;
  justify-content: end; */
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 300px;
  height: 100%;
  /* margin: auto; */
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  position: absolute;
  right: 0;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.h_wrapper {
  width: 100%;
  justify-content: space-between;
}

.btn_close {
  cursor: pointer;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

[data-bs-theme='dark'] .modal-container {
  background-color: #2a3042 !important;
  color: #f6f6f6 !important;
}
</style>
