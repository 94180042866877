<template>
  <div class="card mt-4">
    <div class="card-body">
      <h4 class="card-title mb-4">Recent Subscriptions</h4>
      <div v-if="datas.length" class="table-responsive">
        <table class="table align-middle table-nowrap mb-0">
          <thead class="table-light">
            <tr>
              <th class="align-middle">ID</th>
              <th class="align-middle">Start</th>
              <th class="align-middle">End</th>
              <th class="align-middle">Network</th>
              <th class="align-middle">Subscribe</th>
              <th class="align-middle">Amount</th>
              <th class="align-middle">Payment Status</th>
              <th class="align-middle">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in datas" :key="data.id">
              <td>#{{ data.id }}</td>
              <td v-if="data.date_in != null">{{ dateFormat(data.date_in) }}</td>
              <td v-else>-</td>
              <td v-if="data.date_out != null">{{ dateFormat(data.date_out) }}</td>
              <td v-else>-</td>
              <td>
                <img
                  style="margin-right: -4px"
                  src="@/assets/images/usdt-icon.svg"
                  alt="usdt"
                  width="20"
                  class="usdt_icon"
                />
                <img
                  v-if="data.network === 'USDT.BEP20'"
                  src="@/assets/images/bep.png"
                  alt="bep"
                  width="20"
                />
                <img v-else src="@/assets/images/trc.png" alt="trc" width="20" />
                <!-- <span>{{ data.network }}</span> -->
              </td>
              <td>{{ data.stype }}</td>
              <td>{{ data.amount }}</td>
              <td>
                <span
                  v-if="data.paid === true"
                  class="badge badge-pill badge-soft-success font-size-11"
                  >Paid</span
                >
                <span v-else class="badge badge-pill badge-soft-warning font-size-11">Unpaid</span>
              </td>
              <td class="btn_act">
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                  @click="detailSubs(data.id)"
                >
                  View Details
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else class="not_found">
        <img src="@/assets/images/found-icon.png" alt="found" width="50" />
        <p>No transactions have been recorded</p>
      </div>
    </div>
  </div>
</template>

<script>
// store
import { useSubscription } from '@/stores/subscription-store'

// modules
import { computed } from 'vue'

// helper
import dateFormat from '@/helper/DateFormat'

export default {
  data() {
    return {
      dateFormat,
      store: useSubscription(),
      datas: computed(() => this.store.subsDatas),
      page: 1
    }
  },
  mounted() {
    this.subsDatas()
  },
  methods: {
    subsDatas() {
      this.store.getSubsDatas(this.page)
    },

    detailSubs(id) {
      const path = `/dashboard/subscriptions/${id}`
      this.$router.push(path)
    }
  }
}
</script>

<style scoped>
.not_found {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.not_found p {
  max-width: 150px;
  text-align: center;
}
.usdt_icon {
  margin-right: -5px !important;
}
.btn_act button {
  padding: 2px 10px;
}
</style>
