<template>
  <div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="bg-primary-subtle">
                <div class="row">
                  <div class="col-7">
                    <div class="text-primary p-4">
                      <h5 class="text-primary">Sign In</h5>
                      <p>Sign in to continue to Wavedex.io</p>
                    </div>
                  </div>
                  <div class="col-5 align-self-end">
                    <img src="../../assets/images/profile-img.png" alt="" class="img-fluid" />
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="auth-logo">
                  <router-link to="/" class="auth-logo-light">
                    <div class="avatar-md profile-user-wid mb-4">
                      <span class="avatar-title rounded-circle bg-light">
                        <img
                          src="../../assets/images/logo-light.svg"
                          alt=""
                          class="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </router-link>

                  <router-link to="/" class="auth-logo-dark">
                    <div class="avatar-md profile-user-wid mb-4">
                      <span class="avatar-title rounded-circle bg-light">
                        <img
                          src="../../assets/images/logo.svg"
                          alt=""
                          class="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </router-link>
                </div>
                <div class="p-2">
                  <form class="form-horizontal" action="index.html" @submit.prevent="submitForm">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email</label>
                      <input
                        type="text"
                        class="form-control"
                        id="email"
                        placeholder="Enter email"
                        v-model="postData.email"
                        @blur="v$.postData.email.$touch"
                      />
                      <div v-if="v$.postData.email.$error" style="color: red">
                        <p v-if="v$.postData.email.required.$invalid">
                          * Email address is required
                        </p>
                        <p v-if="v$.postData.email.email.$invalid">* Please enter valid email</p>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Password</label>
                      <div class="input-group auth-pass-inputgroup">
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Enter password"
                          aria-label="Password"
                          aria-describedby="password-addon"
                          v-model="postData.password"
                          @blur="v$.postData.password.$touch"
                          ref="showPassword"
                        />
                        <button
                          class="btn btn-light"
                          type="button"
                          id="password-addon"
                          @click="showPassword"
                        >
                          <i
                            :class="
                              isShowPassword ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'
                            "
                          ></i>
                        </button>
                      </div>
                      <div v-if="v$.postData.password.$error" style="color: red">
                        <p v-if="v$.postData.password.required.$invalid">* Password is required</p>
                      </div>
                    </div>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="remember-check"
                        ref="rememberCheckbox"
                      />
                      <label class="form-check-label" for="remember-check"> Remember me </label>
                    </div>

                    <div class="mt-3 d-grid">
                      <button
                        class="btn btn-primary waves-effect waves-light"
                        :disabled="loading"
                        type="submit"
                      >
                        <span
                          v-if="loading"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span v-else>Log In</span>
                      </button>
                    </div>

                    <div class="mt-4 text-center">
                      <div class="mt-3 d-grid alert alert-info">
                        <h4>Important Information:</h4>
                        <br />
                        We made an application improvement. If you cannot log in, please press
                        forgot password below
                      </div>
                      <router-link to="/auth/reset-password" class="text-muted">
                        <i class="mdi mdi-lock me-1"></i> Forgot your password?
                      </router-link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="mt-5 text-center">
              <div>
                <p>
                  Don't have an account ?
                  <router-link to="/auth/register" class="fw-medium text-primary">
                    Signup now
                  </router-link>
                </p>
                <p>
                  Need a new verification link ?
                  <router-link to="/auth/request-email-verification" class="fw-medium text-primary">
                    Resend
                  </router-link>
                </p>
                <p>© {{ dateTime }} Wavedex.io</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <modal-reusable
        :show="showModalFail"
        @close="showModalFail = false"
        :typeSuccess="true"
        :isSuccess="false"
        :resMsg="responseMsg"
      >
      </modal-reusable>
    </Teleport>
  </div>
</template>

<script>
// modules
import Cookies from 'js-cookie'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

// service
import service from '@/utils/service'

// stores
import { useSessionStore } from '@/stores/session-store'

// components
import ModalReusable from '@/components/modals/ModalReusable.vue'

export default {
  components: { ModalReusable },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      store: useSessionStore(),
      dateTime: '2023',
      loading: false,
      showModalFail: false,
      responseMsg: '',
      isShowPassword: false,
      postData: {
        email: '',
        password: ''
      }
    }
  },
  validations() {
    return {
      postData: {
        email: { required, email },
        password: { required }
      }
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year
    this.getEmailPass()
  },
  methods: {
    saveEmailPass() {
      const email = this.postData.email
      const password = this.postData.password

      const encryptEmail = btoa(email)
      localStorage.setItem('exm@l', encryptEmail)
      const encryptPass = btoa(password)
      localStorage.setItem('pxm@l', encryptPass)
    },
    getEmailPass() {
      const encryptEmail = localStorage.getItem('exm@l')
      const encryptPass = localStorage.getItem('pxm@l')
      if (encryptEmail && encryptPass) {
        this.$refs.rememberCheckbox.checked = true
        const decryptEmail = atob(encryptEmail)
        const decryptPass = atob(encryptPass)
        this.postData.email = decryptEmail
        this.postData.password = decryptPass
      }
    },
    handleRememberMe() {
      if (this.$refs.rememberCheckbox.checked && this.postData.email && this.postData.password) {
        this.saveEmailPass()
      } else {
        localStorage.removeItem('exm@l')
        localStorage.removeItem('pxm@l')
      }
    },
    handleModalAnnouncement() {
      localStorage.setItem('showAnnouncement', true)
    },
    submitForm() {
      this.v$.postData.$touch()

      if (!this.v$.$invalid) {
        this.loading = true
        service
          .post('/auth/signin_post', this.postData)
          .then((response) => {
            if (!response.success) {
              this.showModalFail = true
              this.responseMsg = response.msg
              this.store.authenticate()
            } else if (response.success && response.action == 'login_complete') {
              this.handleRememberMe()
              this.handleModalAnnouncement()
              Cookies.set('jwt', response.token, { expires: 1 })
              Cookies.set('usid', response.usid, { expires: 1 })
              this.$router.go('/dashboard')
            } else if (response.success && response.action == '2fa') {
              const emailEncryp = this.postData.email
              const passwordEncryp = this.postData.password
              Cookies.set('temp_email', emailEncryp, { expires: 1 })
              Cookies.set('temp_password', passwordEncryp, { expires: 1 })
              this.$router.push('/auth/two-factor-auth')
            }
          })
          .catch((error) => {
            console.error('Error:', error)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    showPassword() {
      this.isShowPassword = !this.isShowPassword
      const showPassword = this.$refs.showPassword
      showPassword.type = this.isShowPassword ? 'text' : 'password'
    }
  }
}
</script>
