<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5 text-muted">
            <router-link to="/" class="d-block auth-logo">
              <img
                src="../../assets/images/logo-dark.png"
                alt=""
                height="20"
                class="auth-logo-dark mx-auto"
              />
              <img
                src="../../assets/images/logo-light.png"
                alt=""
                height="20"
                class="auth-logo-light mx-auto"
              />
            </router-link>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div class="card-body">
              <div v-if="loading" class="text-center">
                <div
                  class="spinner-border text-primary"
                  style="width: 5rem; height: 5rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

              <div v-else class="p-2">
                <div v-if="defaultView" class="text-center">
                  <div class="avatar-md mx-auto">
                    <div class="avatar-title rounded-circle bg-light">
                      <i class="bx bxs-envelope h1 mb-0 text-primary"></i>
                    </div>
                  </div>
                  <div class="p-2 mt-4">
                    <h3>Verification Successful</h3>
                    <p>Please log in using the registered account.</p>
                    <div class="mt-4">
                      <router-link to="/auth/login" class="btn btn-success w-md">Login</router-link>
                    </div>
                  </div>
                </div>

                <div v-else-if="isSuccess" class="succ_an">
                  <SuccessAnimation />
                  <h2>{{ resMsg }}</h2>
                </div>

                <div v-else-if="isError" class="succ_an">
                  <FailureAnimation />
                  <h3>{{ resMsg }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p>© {{ dateTime }} Wavedex.io</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// services
import service from '@/utils/service'

// components
import SuccessAnimation from '@/components/animations/SuccessAnimation.vue'
import FailureAnimation from '@/components/animations/FailureAnimation.vue'

export default {
  components: { SuccessAnimation, FailureAnimation },
  data() {
    return {
      dateTime: '2023',
      isSuccess: false,
      isError: false,
      defaultView: false,
      resMsg: null,
      loading: false
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year

    this.cekVerifyEmail()
  },
  methods: {
    cekVerifyEmail() {
      const verification_code = this.$route.query.verification_code
      this.loading = true

      service
        .post('/auth/email-verification', { verification_code: verification_code })
        .then((response) => {
          if (response.success) {
            this.isSuccess = true
            this.loading = false
            setTimeout(() => {
              this.isSuccess = false
              this.isError = false
              this.defaultView = true
            }, 3000)
          } else {
            this.isError = true
            this.isSuccess = false
            this.defaultView = false
            this.loading = false
          }
          this.resMsg = response.msg
        })
    }
  }
}
</script>

<style scoped>
.succ_an {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
</style>
