import axios from 'axios'
import Cookies from 'js-cookie'

const service = axios.create({
  baseURL: import.meta.env.VITE_BASE_API,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  },
  timeout: 50000
})

// Interceptor untuk request
service.interceptors.request.use(
  (config) => {
    // Cek apakah permintaan memerlukan header JWT
    if (config.headers.requiresAuth) {
      const jwtToken = Cookies.get('jwt')

      if (jwtToken) {
        config.headers['JWT'] = `${jwtToken}`
      }
    }

    if (config.headers['Content-Type'] === 'multipart/form-data') {
      config.headers['Content-Type'] =
        'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
    }

    // Disini kita dapat menambahkan logika tambahan di sini sebelum permintaan dikirim
    return config
  },
  (error) => {
    console.error('Error in request interceptor:', error)
    return Promise.reject(error)
  }
)

// Interceptor untuk response
service.interceptors.response.use(
  (response) => {
    // Disini kita dapat menambahkan logika tambahan di sini sebelum response dikirim ke aplikasi
    return response.data
  },
  (error) => {
    console.error('Error in response interceptor:', error)

    // Tambahkan logika penanganan error di sini
    // Contoh: menampilkan pesan error menggunakan library notifikasi atau memperbarui status aplikasi

    return Promise.reject(error)
  }
)

export default service
