<template>
  <div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="bg-primary-subtle">
                <div class="row">
                  <div class="col-7">
                    <div class="text-primary p-4">
                      <h5 class="text-primary">Wavedex Registration</h5>
                      <p>Get your account and active your bot now.</p>
                    </div>
                  </div>
                  <div class="col-5 align-self-end">
                    <img src="../../assets/images/profile-img.png" alt="" class="img-fluid" />
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div>
                  <router-link to="/">
                    <div class="avatar-md profile-user-wid mb-4">
                      <span class="avatar-title rounded-circle bg-light">
                        <img
                          src="../../assets/images/logo.svg"
                          alt=""
                          class="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </router-link>
                </div>
                <div class="p-2">
                  <form class="needs-validation" action="index.html" @submit.prevent="submitForm">
                    <div class="mb-3">
                      <label for="useremail" class="form-label">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="useremail"
                        placeholder="Enter email"
                        v-model="postData.email"
                        @blur="v$.postData.email.$touch"
                      />
                      <div v-if="v$.postData.email.$error" style="color: red">
                        <p v-if="v$.postData.email.required.$invalid">
                          * Email address is required
                        </p>
                        <p v-if="v$.postData.email.email.$invalid">* Please enter valid email</p>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label for="userreferal" class="form-label">Referral ID</label>
                      <input
                        type="text"
                        class="form-control"
                        id="userreferal"
                        placeholder="Enter referral ID"
                        v-model="postData.upline_shadow"
                        @blur="v$.postData.upline_shadow.$touch"
                      />
                      <div v-if="v$.postData.upline_shadow.$error" style="color: red">
                        <p v-if="v$.postData.upline_shadow.required.$invalid">
                          * Referral id is required
                        </p>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label for="userpassword" class="form-label">Password</label>
                      <div class="d-flex">
                        <input
                          type="password"
                          class="form-control"
                          id="userpassword"
                          placeholder="Enter password"
                          v-model="postData.password"
                          @blur="v$.postData.password.$touch"
                          ref="showPassword"
                        />
                        <button
                          class="btn btn-light"
                          type="button"
                          id="password-addon"
                          @click="() => showPassword('password')"
                        >
                          <i
                            :class="
                              isShowPassword ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'
                            "
                          ></i>
                        </button>
                      </div>
                      <div v-if="v$.postData.password.$error" style="color: red">
                        <p v-if="v$.postData.password.required.$invalid">* Password is required</p>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label for="userrepassword" class="form-label">Re-Type Password</label>
                      <div class="d-flex">
                        <input
                          type="password"
                          class="form-control"
                          id="userrepassword"
                          placeholder="Re-type password"
                          v-model="postData.password_confirm"
                          @blur="v$.postData.password_confirm.$touch"
                          ref="showPasswordConfirm"
                        />
                        <button
                          class="btn btn-light"
                          type="button"
                          id="password-addon"
                          @click="() => showPassword('passwordConfirm')"
                        >
                          <i
                            :class="
                              isShowPasswordConfirm
                                ? 'mdi mdi-eye-outline'
                                : 'mdi mdi-eye-off-outline'
                            "
                          ></i>
                        </button>
                      </div>
                      <div v-if="v$.postData.password_confirm.$error" style="color: red">
                        <p v-if="v$.postData.password_confirm.required.$invalid">
                          * Please re-enter password
                        </p>
                        <p v-else-if="v$.postData.password_confirm.sameAsPassword.$invalid">
                          * Password do not match
                        </p>
                      </div>
                    </div>

                    <div class="form-check mt-4">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="termsCheckbox"
                        ref="tncCheckbox"
                        @click="tncModalHandler"
                        required
                      />
                      <label class="form-check-label fw-normal" for="termsCheckbox">
                        By registering you agree to Wavedex
                        <a href="#" class="text-primary">Terms of Use</a> <br />
                        Please scroll until the end to accept the terms & condition.
                      </label>
                    </div>

                    <div class="mt-4 d-grid">
                      <button
                        class="btn btn-primary waves-effect waves-light"
                        :disabled="loading"
                        type="submit"
                      >
                        <span
                          v-if="loading"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span v-else>Register</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="mt-5 text-center">
              <div>
                <p>
                  Already have an account ?
                  <router-link to="/auth/login" class="fw-medium text-primary"> Login</router-link>
                </p>
                <p>© {{ dateTime }} Wavedex.io</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <modal-reusable
        :show="showModalSuccess"
        @close="showModalSuccess = false"
        :typeSuccess="false"
        :isSuccess="false"
        :resMsg="responseMsg"
      >
        <template #body>
          <div class="body_wrap">
            <SuccessAnimation />
            <p class="text-center">{{ responseMsg }}</p>
          </div>
        </template>
        <template #footer>
          <router-link to="/auth/login">
            <button class="btn btn-outline-primary">Login</button>
          </router-link>
        </template>
      </modal-reusable>

      <modal-reusable
        :show="showModalFail"
        @close="showModalFail = false"
        :typeSuccess="true"
        :isSuccess="false"
        :resMsg="responseMsg"
      >
      </modal-reusable>
    </Teleport>

    <ModalTNC
      :show="showModalTNC"
      @close="
        () => {
          showModalTNC = false
          this.$refs.tncCheckbox.checked = false
        }
      "
      @accept="showModalTNC = false"
    ></ModalTNC>
  </div>
</template>

<script>
import service from '@/utils/service'
import { useVuelidate } from '@vuelidate/core'
import { required, email, sameAs } from '@vuelidate/validators'
import ModalReusable from '@/components/modals/ModalReusable.vue'
import SuccessAnimation from '@/components/animations/SuccessAnimation.vue'
import ModalTNC from '@/components/modals/ModalTNC.vue'

export default {
  components: { ModalReusable, SuccessAnimation, ModalTNC },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      dateTime: '2023',
      loading: false,
      showModalSuccess: false,
      showModalFail: false,
      showModalTNC: false,
      responseMsg: '',
      isShowPassword: false,
      isShowPasswordConfirm: false,
      postData: {
        email: '',
        password: '',
        password_confirm: '',
        upline_shadow: this.$route.query.ref
      }
    }
  },
  validations() {
    return {
      postData: {
        email: { required, email },
        password: {
          required
          // minLength: minLength(8),
          // hasCapital: (value) => /[A-Z]/.test(value),
          // hasNumber: (value) => /[0-9]/.test(value)
        },
        password_confirm: {
          required,
          sameAsPassword: sameAs(this.postData.password)
        },
        upline_shadow: {
          required
        }
      }
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year
  },
  methods: {
    submitForm() {
      this.v$.postData.$touch()

      if (!this.v$.$invalid) {
        this.loading = true
        service
          .post('/auth/signup_post', this.postData)
          .then((response) => {
            if (response.success) {
              this.showModalSuccess = true
              this.responseMsg = response.msg
            } else {
              this.showModalFail = true
              this.responseMsg = response.msg
            }
          })
          .catch((error) => {
            console.error('Error:', error)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    showPassword(type) {
      if (type === 'password') {
        this.isShowPassword = !this.isShowPassword
        const showPassword = this.$refs.showPassword
        showPassword.type = this.isShowPassword ? 'text' : 'password'
      } else {
        this.isShowPasswordConfirm = !this.isShowPasswordConfirm
        const showPasswordConfirm = this.$refs.showPasswordConfirm
        showPasswordConfirm.type = this.isShowPasswordConfirm ? 'text' : 'password'
      }
    },
    tncModalHandler() {
      if (this.$refs.tncCheckbox.checked) {
        this.showModalTNC = true
      }
    }
  }
}
</script>

<style scoped>
.body_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
#termsCheckbox + label:hover {
  cursor: default;
}
</style>
