<template>
  <Teleport to="body">
    <modal-reusable v-if="!isSuccess" :show="showModalCreate" @close="closeModal">
      <template #body>
        <strong class="title_modal">Add Address</strong>
        <div class="form-group mt-3">
          <label for="exampleFormControlSelect1">Currency Network</label>
          <input type="text" v-model="netWorkSelected" readonly />
          <!-- <select
              @change="warMsg = null"
              v-model="netWorkSelected"
              class="form-control"
              id="exampleFormControlSelect1"
            >
              <option>USDT.BEP20</option>
              <option>USDT.TRC20</option>
            </select> -->
        </div>
        <div class="form-group mt-3">
          <label for="exampleFormControlInput1">Address</label>
          <input
            @input="warMsg = null"
            v-model="address"
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Pasting the correct address"
          />
        </div>

        <div v-if="warMsg !== null" class="war_box">
          <p>{{ warMsg }}</p>
        </div>
      </template>

      <template #footer>
        <button @click="closeModal">Cancel</button>
        <button @click="creadWallet" :disabled="loading">
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>Confirm</span>
        </button>
      </template>
    </modal-reusable>

    <modal-reusable
      v-else
      :show="isSuccess"
      @close="isSuccess = false"
      :typeSuccess="true"
      :isSuccess="true"
      resMsg="Successfully"
    >
    </modal-reusable>
  </Teleport>
</template>

<script>
// services
import service from '@/utils/service'

// store
import { useWalletHistory } from '@/stores/wallet-store'

// components
import ModalReusable from '@/components/modals/ModalReusable.vue'

export default {
  components: { ModalReusable },
  props: {
    showModalCreate: Boolean
  },
  data() {
    return {
      store: useWalletHistory(),
      isSuccess: false,
      netWorkSelected: 'USDT.BEP20',
      address: '',
      warMsg: null,
      loading: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('close', false)
    },
    creadWallet() {
      const isValidate = this.validForm()
      const addressValidate = this.isValidAddress()
      console.log({ addressValidate })

      if (isValidate === true) {
        if (!addressValidate) {
          this.warMsg = '*Address not valid'
        } else {
          this.loading = true
          service
            .post(
              '/profile/update-my-wallet',
              { currency_network: this.netWorkSelected, address: this.address },
              { headers: { requiresAuth: true } }
            )
            .then((res) => {
              if (res.success) {
                this.store.getMyWallet()
                this.isSuccess = true
                console.log(res.data)
              }
            })
            .catch((err) => {
              console.error('Error: ', err)
            })
            .finally(() => {
              this.loading = false

              setTimeout(() => {
                this.resetState()
                this.closeModal()
                window.location.reload()
              }, 2000)
            })
        }
      } else {
        this.warMsg = isValidate
      }
    },
    validForm() {
      if (!this.netWorkSelected) {
        return '*Please select network'
      } else if (!this.address) {
        return '*Please paste the address'
      } else {
        return true
      }
    },
    isValidAddress() {
      let result
      if (this.netWorkSelected === 'USDT.BEP20') {
        result = /^0x[0-9A-Fa-f]{40}$/
      } else {
        result = /^T[0-9A-Za-z]{33}$/
      }
      return result.test(this.address)
    },
    resetState() {
      this.resMsg = null
      // this.netWorkSelected = ''
      this.address = ''
      this.warMsg = null
      this.isSuccess = false
    }
  }
}
</script>

<style scoped>
.title_modal {
  font-size: 20px;
}
.form-group label {
  color: grey;
  font-weight: 400;
}
.form-group input::placeholder {
  font-size: 14px;
  color: grey;
}
.war_box {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding: 3px 5px;
  background-color: rgb(233, 107, 128);
}
.war_box p {
  font-size: 14px;
  color: aliceblue;
}
</style>
