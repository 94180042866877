<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <BreadcrumbsBar />
        <!-- <FinancialData /> -->
        <RevenueClient />
        <FooterBar />
      </div>
    </div>
  </div>
</template>

<script>
// components
// import FinancialData from '../client/FinancialData.vue'
import RevenueClient from '../client/RevenueClient.vue'
import BreadcrumbsBar from '../global/BreadcrumbsBar.vue'
import FooterBar from '../global/FooterBar.vue'

export default {
  components: {
    // FinancialData,
    BreadcrumbsBar,
    RevenueClient,
    FooterBar
  }
}
</script>
