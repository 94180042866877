<template>
  <Transition name="modal-announcement" appear>
    <div v-if="show" class="modal-masks">
      <div class="modal-container">
        <div class="modal-header">
          <img src="../../assets/images/logo-dark.png" width="200" alt="logo" />
        </div>

        <div class="modal-body">
          <h1 class="text-center fw-bold">🚀 Exciting Update: Wavedex Dashboard Upgrade 🚀</h1>
          <p>🔍 Attention Wavedex Users:</p>
          <p>
            📢 We've revamped our dashboard! All your previous bonuses have now been moved to
            'Trading Income.' Moving forward, all bonuses will be directly allocated across:
          </p>
          <ol>
            <li>Subscription Bonus</li>
            <li>Sales Income</li>
            <li>Withdrawal Income</li>
            <li>Trading Income</li>
          </ol>
          <p>
            🌟 We're truly committed in enhancing your experience and continually improving our
            system.
          </p>
          <p>Thank you for being part of the journey! 🌟 #WavedexUpgrade #EnhancedExperience</p>
          <p>Sincerely,<br /><span class="fw-bold">WaveDex Team</span></p>
        </div>

        <div class="modal-footers">
          <button type="button" class="btn btn-primary px-4 py-2" @click="closeModal">
            Continue
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      isShowTimeoutSession: true,
      isCountdownStart: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
p,
li {
  font-size: 14px;
  font-weight: 500;
  text-align: justify;
}
h1 {
  font-size: 22px;
  margin-bottom: 1.5rem;
}
.modal-masks {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 90vh;
  padding: 0;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  background: linear-gradient(to top, lightblue, white);
}
.modal-header {
  display: flex;
  justify-content: center;
  cursor: auto;
  padding: 1.5rem 0;
}
.modal-body {
  justify-content: center;
  padding: 0rem 2rem;
  margin: 0;
  overflow-y: auto;
}
.modal-footers {
  display: flex;
  justify-content: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

/* transition */
.modal-announcement-enter-active,
.modal-announcement-leave-active {
  transition: opacity 0.2s ease;
}
.modal-announcement-enter,
.modal-announcement-leave-to {
  opacity: 0;
}
.modal-announcement-enter-from .modal-container,
.modal-announcement-leave-to .modal-container {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
</style>
