<script setup>
import { RouterView } from 'vue-router'
import Cookies from 'js-cookie'
import { onMounted } from 'vue'

const getThemes = () => {
  const theme = Cookies.get('theme')

  if (theme) {
    document.documentElement.setAttribute('data-bs-theme', theme)
  } else {
    Cookies.set('theme', 'dark')
    document.documentElement.setAttribute('data-bs-theme', 'dark')
  }
}

const initializeGoogleAnalytics = () => {
  const gaProperty = 'G-WXK8QZ1V8L'

  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gaProperty}`
  document.head.appendChild(script)

  script.onload = () => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', gaProperty)
  }
}

const liveChat = () => {
  const isLogin = Cookies.get('jwt')

  if (isLogin) {
    if (window.iticks) return

    window.iticks = {
      host: 'https://app.intelliticks.com',
      settings: {}, // Add your settings if needed
      clientId: '6JZEi5KNmCHDgceNb_c',
      cdn: 'https://cdn-v1.intelliticks.com/prod/common',
      queue: []
    }

    const head = document.head || document.documentElement
    const script = document.createElement('script')

    script.async = true
    script.src = `${window.iticks.cdn}/client/inject-v2.min.js`

    head.insertBefore(script, head.firstChild)

    window.iticks.call = function (a, b) {
      window.iticks.queue.push([a, b])
    }
  }
}

onMounted(() => {
  getThemes()
  initializeGoogleAnalytics()
  liveChat()
})
</script>

<template>
  <RouterView />
</template>

<style></style>
