<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="../../../assets/images/logo.svg" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="../../../assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="../../../assets/images/logo-light.svg" alt="" height="28" />
            </span>
            <span class="logo-lg">
              <img src="../../../assets/images/logo-light.png" alt="" height="19" />
            </span>
          </a>
        </div>

        <button
          @click="handleMenuSideBar"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item waves-effect"
          id="vertical-menu-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              id="iconPath"
              d="M20 17.5C20.3852 17.5002 20.7556 17.6486 21.0344 17.9144C21.3132 18.1802 21.479 18.5431 21.4975 18.9279C21.516 19.3127 21.3858 19.6898 21.1338 19.9812C20.8818 20.2726 20.5274 20.4558 20.144 20.493L20 20.5H4C3.61478 20.4998 3.24441 20.3514 2.96561 20.0856C2.68682 19.8198 2.52099 19.4569 2.50248 19.0721C2.48396 18.6873 2.61419 18.3102 2.86618 18.0188C3.11816 17.7274 3.47258 17.5442 3.856 17.507L4 17.5H20ZM20 10.5C20.3978 10.5 20.7794 10.658 21.0607 10.9393C21.342 11.2206 21.5 11.6022 21.5 12C21.5 12.3978 21.342 12.7794 21.0607 13.0607C20.7794 13.342 20.3978 13.5 20 13.5H4C3.60218 13.5 3.22064 13.342 2.93934 13.0607C2.65804 12.7794 2.5 12.3978 2.5 12C2.5 11.6022 2.65804 11.2206 2.93934 10.9393C3.22064 10.658 3.60218 10.5 4 10.5H20ZM20 3.5C20.3978 3.5 20.7794 3.65804 21.0607 3.93934C21.342 4.22064 21.5 4.60218 21.5 5C21.5 5.39782 21.342 5.77936 21.0607 6.06066C20.7794 6.34196 20.3978 6.5 20 6.5H4C3.60218 6.5 3.22064 6.34196 2.93934 6.06066C2.65804 5.77936 2.5 5.39782 2.5 5C2.5 4.60218 2.65804 4.22064 2.93934 3.93934C3.22064 3.65804 3.60218 3.5 4 3.5H20Z"
              fill="#2D2B2B"
            />
          </svg>
        </button>

        <div class="dropdown dropdown-mega d-none d-lg-block ms-2">
          <div class="dropdown-menu dropdown-megamenu">
            <div class="row">
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-md-4">
                    <h5 class="font-size-14" key="t-ui-components">UI Components</h5>
                    <ul class="list-unstyled megamenu-list">
                      <li>
                        <a href="javascript:void(0);" key="t-lightbox">Lightbox</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-range-slider">Range Slider</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-sweet-alert">Sweet Alert</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-rating">Rating</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-forms">Forms</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-tables">Tables</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-charts">Charts</a>
                      </li>
                    </ul>
                  </div>

                  <div class="col-md-4">
                    <h5 class="font-size-14" key="t-applications">Applications</h5>
                    <ul class="list-unstyled megamenu-list">
                      <li>
                        <a href="javascript:void(0);" key="t-ecommerce">Ecommerce</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-calendar">Calendar</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-email">Email</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-projects">Projects</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-tasks">Tasks</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-contacts">Contacts</a>
                      </li>
                    </ul>
                  </div>

                  <div class="col-md-4">
                    <h5 class="font-size-14" key="t-extra-pages">Extra Pages</h5>
                    <ul class="list-unstyled megamenu-list">
                      <li>
                        <a href="javascript:void(0);" key="t-light-sidebar">Light Sidebar</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-compact-sidebar">Compact Sidebar</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-horizontal">Horizontal layout</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-maintenance">Maintenance</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-coming-soon">Coming Soon</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-timeline">Timeline</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-faqs">FAQs</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="row">
                  <div class="col-sm-6">
                    <h5 class="font-size-14" key="t-ui-components">UI Components</h5>
                    <ul class="list-unstyled megamenu-list">
                      <li>
                        <a href="javascript:void(0);" key="t-lightbox">Lightbox</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-range-slider">Range Slider</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-sweet-alert">Sweet Alert</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-rating">Rating</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-forms">Forms</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-tables">Tables</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-charts">Charts</a>
                      </li>
                    </ul>
                  </div>

                  <div class="col-sm-5">
                    <div>
                      <img
                        src="../../../assets/images/megamenu-img.png"
                        alt=""
                        class="img-fluid mx-auto d-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <!-- <div class="dropdown d-inline-block d-lg-none ms-2">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            id="page-header-search-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="mdi mdi-magnify"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-search-dropdown"
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div> -->

        <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end">
            <div class="px-lg-2">
              <div class="row g-0">
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="../../../assets/images/brands/github.png" alt="Github" />
                    <span>GitHub</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="../../../assets/images/brands/bitbucket.png" alt="bitbucket" />
                    <span>Bitbucket</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="../../../assets/images/brands/dribbble.png" alt="dribbble" />
                    <span>Dribbble</span>
                  </a>
                </div>
              </div>

              <div class="row g-0">
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="../../../assets/images/brands/dropbox.png" alt="dropbox" />
                    <span>Dropbox</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="../../../assets/images/brands/mail_chimp.png" alt="mail_chimp" />
                    <span>Mail Chimp</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="../../../assets/images/brands/slack.png" alt="slack" />
                    <span>Slack</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
            @click="openFullScreen"
            type="button"
            class="btn header-item noti-icon waves-effect"
            data-bs-toggle="fullscreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect position-relative"
            id="page-header-notifications-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="bx bx-bell bx-tada"></i>
            <!-- <span class="badge bg-danger rounded-pill">1</span> -->
            <span
              v-if="hasNotification"
              class="position-absolute top-25 start-75 translate-middle p-1 bg-danger rounded-circle"
            >
            </span>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-notifications-dropdown"
          >
            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-0" key="t-notifications">Notifications</h6>
                </div>
                <div class="col-auto">
                  <a href="javascript:void(0)" class="small" key="t-view-all"> View All</a>
                </div>
              </div>
            </div>
            <div v-if="hasNotification" data-simplebar style="max-height: 230px">
              <!-- notification messages -->
              <a href="javascript: void(0);" class="text-reset notification-item">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <h6 class="mb-1">Wavedex Technical</h6>
                    <div v-html="responseMsg" class="font-size-12 text-muted mb-1"></div>
                  </div>
                </div>
              </a>
            </div>
            <div class="p-2 border-top d-grid">
              <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                <i class="mdi mdi-arrow-right-circle me-1"></i>
                <span key="t-view-more">View More..</span>
              </a>
            </div>
          </div>
        </div>

        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item waves-effect d-flex align-items-center gap-1"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="rounded-circle avatar-cover">
              <img
                class="rounded-circle avatar-thumbnail"
                :src="datas?.orang_avatar_file_url"
                alt="Header Avatar"
              />
            </div>
            <span class="d-none d-xl-inline-block ms-1" key="t-henry">{{
              datas?.orang_nickname
            }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <!-- item-->
            <!-- <a class="dropdown-item" href="#"
              ><i class="bx bx-user font-size-16 align-middle me-1"></i>
              <span key="t-profile">Profile</span></a
            >
            <a class="dropdown-item" href="#"
              ><i class="bx bx-wallet font-size-16 align-middle me-1"></i>
              <span key="t-my-wallet">My Wallet</span></a
            >
            <a class="dropdown-item d-block" href="#"
              ><span class="badge bg-success float-end">11</span
              ><i class="bx bx-wrench font-size-16 align-middle me-1"></i>
              <span key="t-settings">Settings</span></a
            >
            <a class="dropdown-item" href="#"
              ><i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
              <span key="t-lock-screen">Lock screen</span></a
            >
            <div class="dropdown-divider"></div> -->
            <div @click="logoutUser" class="dropdown-item text-danger cursor_pointer">
              <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              <span key="t-logout">Logout</span>
            </div>
          </div>
        </div>

        <div class="dropdown d-inline-block">
          <button
            @click="showModalSetting = true"
            type="button"
            class="btn header-item noti-icon right-bar-toggle waves-effect"
          >
            <i class="bx bx-cog bx-spin"></i>
          </button>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <ModalMenu :show="showModalMenu" @close="showModalMenu = false"> </ModalMenu>
      <ModalSetting :show="showModalSetting" @close="showModalSetting = false"></ModalSetting>
    </Teleport>
  </header>
</template>

<script>
// modules
import { computed } from 'vue'

// stores
import { useProfile } from '@/stores/profile-store'

// components
import ModalMenu from '@/components/modals/ModalMenu.vue'
import ModalSetting from '@/components/modals/ModalSetting.vue'

//service
import service from '@/utils/service'

export default {
  components: {
    ModalMenu,
    ModalSetting
  },
  data() {
    return {
      store: useProfile(),
      datas: computed(() => this.store.profiles?.orang),
      showModalMenu: false,
      showModalSetting: false,
      responseMsg: '',
      hasNotification: false
    }
  },
  mounted() {
    this.getProfileData()
    this.getMsg()
  },
  methods: {
    getProfileData() {
      this.store.getProfile()
    },
    handleMenuSideBar() {
      var body = document.body
      const widthScreen = window.innerWidth

      if (widthScreen <= 985) {
        this.showModalMenu = true
      } else {
        // Periksa apakah body memiliki class "sidebar-enabled vertical-collpsed"
        var isSidebarEnabled = body.classList.contains('sidebar-enable')
        var isVerticalCollapsed = body.classList.contains('vertical-collpsed')

        // Jika body tidak memiliki class tersebut, tambahkan keduanya
        if (!isSidebarEnabled && !isVerticalCollapsed) {
          body.classList.add('sidebar-enable', 'vertical-collpsed')
        } else {
          // Jika body sudah memiliki class tersebut, hapus keduanya
          body.classList.remove('sidebar-enable', 'vertical-collpsed')
        }
      }
    },

    logoutUser() {
      this.store.logOut()
    },

    openFullScreen() {
      const elem = document.getElementById('parent')

      if (
        !document.fullscreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        // Jika tidak dalam mode fullscreen, masuk ke fullscreen
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
        }
      } else {
        // Jika sudah dalam mode fullscreen, keluar dari fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    },
    getMsg() {
      service
        .get('/dashboard/msg-users')
        .then((response) => {
          if (response.success) {
            if (response.data) {
              this.responseMsg = response.data
              this.hasNotification = true
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }
}
</script>

<style scoped>
.avatar-cover {
  width: 2rem;
  height: 2rem;
  outline: 3px solid #c3d7f1b9;

  overflow: hidden;
}
.avatar-thumbnail {
  width: 100%;
  height: 100%;
}
</style>
