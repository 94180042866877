<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">User Wallet</h4>
          <div class="row">
            <div v-if="myWallet != null" class="table-responsive">
              <table class="table align-middle table-nowrap mb-0">
                <thead class="table-light">
                  <tr>
                    <th class="align-middle">Currency Network</th>
                    <th class="align-middle">Address</th>
                    <th class="align-middle">Created At</th>
                    <th class="align-middle">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ myWallet.currency_network }}</td>
                    <td class="qr_wrap">
                      <div v-if="isHovered" class="copy_icon">
                        <img v-if="isClicked" src="@/assets/images/copied.png" alt="copied" />
                        <img v-else src="@/assets/images/copy.png" alt="copy" />
                      </div>

                      <span
                        @click="handleCopyClick(myWallet.address)"
                        @mouseover="handleMouseHover"
                        @mouseleave="handleMouseLeave"
                        >{{ myWallet.address }}</span
                      >
                    </td>
                    <td>{{ moment(myWallet.created_at).format('DD MMMM YYYY, h:mm:ss') }}</td>
                    <td>
                      <button @click="showModalCreate = true" class="btn_update">Update</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-else class="not_found">
              <img src="@/assets/images/found-icon.png" alt="found" width="50" />
              <p>No addresses have been saved</p>
              <button @click="showModalCreate = true" class="btn_add">Add Address</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalAddress :showModalCreate="showModalCreate" @close="(val) => (showModalCreate = val)" />
  </div>
</template>

<script>
// modules
import moment from 'moment'
import copy from 'copy-to-clipboard'

// services
import service from '@/utils/service'

// components
import ModalAddress from '../../modals/ModalAddress.vue'

export default {
  components: { ModalAddress },
  data() {
    return {
      myWallet: null,
      loading: false,
      showModalCreate: false,
      netWorkSelected: 'USDT.BEP20',
      address: '',
      warMsg: null,
      isSuccess: false,

      isHovered: false,
      isClicked: false,

      moment
    }
  },
  mounted() {
    this.getMyWallet()
  },
  methods: {
    getMyWallet() {
      this.loading = true
      service
        .get('/profile/my-wallet', { headers: { requiresAuth: true } })
        .then((res) => {
          if (res.data.address != null) {
            this.myWallet = res.data
            console.log(res.data)
          }
        })
        .catch((err) => {
          console.error('Error: ', err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    creadWallet() {
      const isValidate = this.validForm()
      const addressValidate = this.isValidAddress()
      console.log({ addressValidate })

      if (isValidate === true) {
        if (!addressValidate) {
          this.warMsg = '*Address not valid'
        } else {
          this.loading = true
          service
            .post(
              '/profile/update-my-wallet',
              { currency_network: this.netWorkSelected, address: this.address },
              { headers: { requiresAuth: true } }
            )
            .then((res) => {
              if (res.success) {
                this.myWallet = res.data
                this.isSuccess = true
                console.log(res.data)
              }
            })
            .catch((err) => {
              console.error('Error: ', err)
            })
            .finally(() => {
              this.loading = false

              setTimeout(() => {
                this.resetState()
                window.location.reload()
              }, 3000)
            })
        }
      } else {
        this.warMsg = isValidate
      }
    },
    validForm() {
      if (!this.netWorkSelected) {
        return '*Please select network'
      } else if (!this.address) {
        return '*Please pasting address'
      } else {
        return true
      }
    },
    isValidAddress() {
      let result
      if (this.netWorkSelected === 'USDT.BEP20') {
        // Alamat BEP20 harus dimulai dengan "0x" dan memiliki panjang 42 karakter
        result = /^0x[0-9A-Fa-f]{40}$/
      } else {
        // Alamat TRC20 harus dimulai dengan "T" dan memiliki panjang 34 karakter
        result = /^T[0-9A-Za-z]{33}$/
      }
      return result.test(this.address)
    },
    resetState() {
      this.loading = false
      this.showModalCreate = false
      // this.netWorkSelected = ''
      this.address = ''
      this.warMsg = null
      this.isSuccess = false
    },
    handleCopyClick(val) {
      this.isClicked = true
      copy(val)
    },

    handleMouseLeave() {
      this.isHovered = false
      this.isClicked = false
    },

    handleMouseHover() {
      this.isHovered = true
    }
  }
}
</script>

<style scoped>
.not_found {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.not_found p {
  max-width: 150px;
  text-align: center;
}
.btn_add {
  padding: 5px 15px;
  background-color: #eaf5ff;
  border: none;
  border-radius: 8px;
  color: #1e90ff;
}
.title_modal {
  font-size: 20px;
}
.form-group label {
  color: grey;
  font-weight: 400;
}
.form-group input::placeholder {
  font-size: 14px;
  color: grey;
}
.war_box {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding: 3px 5px;
  background-color: rgb(233, 107, 128);
}
.war_box p {
  font-size: 14px;
  color: aliceblue;
}
.btn_update {
  background-color: #eaf5ff;
  padding: 2px 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  color: #1e90ff;
}
.qr_wrap {
  position: relative;
}
.copy_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -120%);
}
.copy_icon img {
  width: 90px;
  height: auto;
}
.qr_wrap span {
  cursor: copy;
}

@media only screen and (max-width: 500px) {
  th,
  td {
    font-size: 14px;
  }
  .form_check label {
    display: none;
  }
  .input_pr {
    width: 90px;
    font-size: 14px;
  }
}
</style>
