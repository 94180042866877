<template>
  <div class="fa_en">
    <div v-if="!profiles.orang?.two_fa_is_active" class="fa_wrapper">
      <div class="card">
        <div class="card-body">
          <ul class="nav nav-tabs nav-tabs-custom justify-content-around" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeTab === 'qrCode' }"
                @click.prevent="activeTab = 'qrCode'"
                role="tab"
              >
                QR Code
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeTab === 'setupKey' }"
                @click.prevent="activeTab = 'setupKey'"
                role="tab"
              >
                Setup Key
              </a>
            </li>
          </ul>
          <div v-if="activeTab === 'qrCode'" class="qr_code mt-4">
            <div class="alert alert-info">
              If you are using a mobile device, you can access the
              <span class="fw-medium">Setup Key</span> tab
            </div>
            <img :src="qrCode?.qrUrl" alt="" class="qr_blur" />
            <p class="mt-3">Open the Google Authenticator app and scan this QR Code</p>
          </div>
          <div v-else class="setup_key mt-4">
            <div class="mb-3 row align-items-center m-0">
              <label class="col-md-3 text-start">Account :</label>
              <div class="col-md-9 d-flex">
                <input
                  class="form-control m-0 w-100"
                  type="text"
                  :value="qrCode?.accountName"
                  placeholder="Email"
                  id="example-text-input-email"
                  readonly="readonly"
                />
                <button
                  @click="handleCopyClick(qrCode?.accountName, 'account')"
                  @mouseover="handleMouseHover('account')"
                  @mouseleave="handleMouseLeave('account')"
                  class="btn_copy qr_wrap"
                  type="button"
                >
                  <div v-if="isHoveredAccount" class="copy_icon">
                    <img v-if="isClickedAccount" src="@/assets/images/copied.png" alt="copied" />
                    <img v-else src="@/assets/images/copy.png" alt="copy" />
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M20.9821 25H8.48209C7.41649 25 6.39453 24.5767 5.64104 23.8232C4.88754 23.0697 4.46423 22.0478 4.46423 20.9822V8.48215C4.46423 7.41655 4.88754 6.39459 5.64104 5.6411C6.39453 4.8876 7.41649 4.46429 8.48209 4.46429H20.9821C22.0477 4.46429 23.0697 4.8876 23.8231 5.6411C24.5766 6.39459 24.9999 7.41655 24.9999 8.48215V20.9822C24.9999 22.0478 24.5766 23.0697 23.8231 23.8232C23.0697 24.5767 22.0477 25 20.9821 25Z"
                        fill="currentColor"
                      />
                      <path
                        d="M7.14286 2.67857H20.3058C20.028 1.89578 19.5148 1.21812 18.8366 0.738553C18.1584 0.258984 17.3485 0.00100279 16.5179 0H4.01786C2.95226 0 1.9303 0.423309 1.1768 1.1768C0.423309 1.9303 0 2.95226 0 4.01786V16.5179C0.00100279 17.3485 0.258984 18.1584 0.738553 18.8366C1.21812 19.5148 1.89578 20.028 2.67857 20.3058V7.14286C2.67857 5.95886 3.14891 4.82335 3.98613 3.98613C4.82335 3.14891 5.95886 2.67857 7.14286 2.67857Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
            <div class="mb-3 row align-items-center m-0">
              <label class="col-md-3 text-start">Key :</label>
              <div class="col-md-9 d-flex">
                <textarea
                  class="form-control m-0 w-100"
                  name="key"
                  rows="2"
                  :value="qrCode?.key"
                  readonly="readonly"
                ></textarea>
                <button
                  @mouseover="handleMouseHover('key')"
                  @mouseleave="handleMouseLeave('key')"
                  @click="handleCopyClick(qrCode?.key, 'key')"
                  class="btn_copy qr_wrap"
                  type="button"
                >
                  <div v-if="isHovered" class="copy_icon">
                    <img v-if="isClicked" src="@/assets/images/copied.png" alt="copied" />
                    <img v-else src="@/assets/images/copy.png" alt="copy" />
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M20.9821 25H8.48209C7.41649 25 6.39453 24.5767 5.64104 23.8232C4.88754 23.0697 4.46423 22.0478 4.46423 20.9822V8.48215C4.46423 7.41655 4.88754 6.39459 5.64104 5.6411C6.39453 4.8876 7.41649 4.46429 8.48209 4.46429H20.9821C22.0477 4.46429 23.0697 4.8876 23.8231 5.6411C24.5766 6.39459 24.9999 7.41655 24.9999 8.48215V20.9822C24.9999 22.0478 24.5766 23.0697 23.8231 23.8232C23.0697 24.5767 22.0477 25 20.9821 25Z"
                        fill="currentColor"
                      />
                      <path
                        d="M7.14286 2.67857H20.3058C20.028 1.89578 19.5148 1.21812 18.8366 0.738553C18.1584 0.258984 17.3485 0.00100279 16.5179 0H4.01786C2.95226 0 1.9303 0.423309 1.1768 1.1768C0.423309 1.9303 0 2.95226 0 4.01786V16.5179C0.00100279 17.3485 0.258984 18.1584 0.738553 18.8366C1.21812 19.5148 1.89578 20.028 2.67857 20.3058V7.14286C2.67857 5.95886 3.14891 4.82335 3.98613 3.98613C4.82335 3.14891 5.95886 2.67857 7.14286 2.67857Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
            <p class="mt-3">Open the Google Authenticator app and enter this Setup Key</p>
          </div>
        </div>
      </div>
    </div>

    <div class="fa_form">
      <h2 v-if="profiles.orang?.two_fa_is_active">Disable 2FA</h2>
      <h2 v-else>Enable 2FA</h2>
      <p>Enter the 6-digit code in authenticator.</p>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <otp v-model="google_secret" :count="6" ref="otpComponent">
        <template v-slot="{ digits, onInput, onPaste, onBlur }">
          <div class="d-flex align-items-center">
            <otp-group class="d-flex">
              <template v-slot="{ focusNext, focusPrev }">
                <otp-group-input
                  v-for="(digit, index) in digits"
                  :key="index"
                  :value="digit"
                  autofocus
                  placeholder="*"
                  @blur="onBlur"
                  @next="focusNext(index)"
                  @prev="focusPrev(index)"
                  @paste="onPaste(index, $event)"
                  @input="onInput(index, $event)"
                  class="form-control h-12 w-12 rounded-xl text-3xl text-center text-muted border-2 border-muted placeholder-text-muted focus-outline-none focus-border-teal"
                />
              </template>
            </otp-group>

            <button class="btn" @click="clearPin">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M3.92996 18.07C2.97486 17.1475 2.21303 16.0441 1.68894 14.824C1.16485 13.604 0.888991 12.2918 0.877453 10.964C0.865915 9.63622 1.11893 8.31943 1.62174 7.09046C2.12455 5.8615 2.86708 4.74498 3.80601 3.80605C4.74493 2.86713 5.86145 2.1246 7.09042 1.62179C8.31938 1.11898 9.63618 0.86596 10.964 0.877499C12.2918 0.889037 13.604 1.1649 14.824 1.68899C16.044 2.21308 17.1475 2.9749 18.07 3.93C19.8915 5.81602 20.8995 8.34205 20.8767 10.964C20.8539 13.586 19.8022 16.0941 17.9481 17.9482C16.0941 19.8023 13.5859 20.854 10.964 20.8767C8.342 20.8995 5.81598 19.8916 3.92996 18.07ZM5.33996 16.66C6.84108 18.1611 8.87705 19.0045 11 19.0045C13.1229 19.0045 15.1588 18.1611 16.66 16.66C18.1611 15.1589 19.0044 13.1229 19.0044 11C19.0044 8.87709 18.1611 6.84113 16.66 5.34C15.1588 3.83888 13.1229 2.99555 11 2.99555C8.87705 2.99555 6.84108 3.83888 5.33996 5.34C3.83883 6.84113 2.99551 8.87709 2.99551 11C2.99551 13.1229 3.83883 15.1589 5.33996 16.66ZM15.24 8.17L12.41 11L15.24 13.83L13.83 15.24L11 12.41L8.16996 15.24L6.75996 13.83L9.58996 11L6.75996 8.17L8.16996 6.76L11 9.59L13.83 6.76L15.24 8.17Z"
                  fill="#ED4C4C"
                />
              </svg>
            </button>
          </div>
          <span v-if="message != null" class="text-danger">{{ message }}</span>
        </template>
      </otp>
    </div>
    <button
      :disabled="loading"
      @click="post2fa(profiles.orang?.two_fa_is_active)"
      class="btn btn-primary mt-3"
    >
      <div
        v-if="loading"
        class="spinner-border"
        role="status"
        style="width: 1.2rem; height: 1.2rem"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span v-else>Confirm</span>
    </button>

    <Teleport to="body">
      <modal-reusable
        :show="showModal"
        @close="showModal = false"
        :typeSuccess="true"
        :isSuccess="hasError"
        :resMsg="resMsg"
      >
      </modal-reusable>
    </Teleport>
  </div>
</template>

<script>
// service
import service from '@/utils/service'

// modules
import copy from 'copy-to-clipboard'

import { useProfile } from '@/stores/profile-store'
import { computed } from 'vue'

// components
import { Otp, OtpGroup, OtpGroupInput } from '@/components/otp'
import ModalReusable from '@/components/modals/ModalReusable.vue'

export default {
  components: {
    Otp,
    OtpGroup,
    OtpGroupInput,
    ModalReusable
  },
  data() {
    return {
      store: useProfile(),
      profiles: computed(() => this.store.profiles),
      loading: false,
      qrCode: null,
      google_secret: '',
      showModal2fa: false,
      activeTab: 'qrCode',

      hasError: false,
      message: null,
      showModal: false,
      resMsg: '',

      isHovered: false,
      isClicked: false,
      isHoveredAccount: false,
      isClickedAccount: false
    }
  },
  mounted() {
    this.getTwoFaQr()
  },
  watch: {
    google_secret(google_secret) {
      if (google_secret) {
        this.message = null
      }
    }
  },
  methods: {
    handleCopyClick(val, target) {
      copy(val)

      if (target === 'account') {
        this.isClickedAccount = true
      } else {
        this.isClicked = true
      }
    },
    handleMouseLeave(val) {
      if (val === 'account') {
        this.isHoveredAccount = false
        this.isClickedAccount = false
      } else {
        this.isHovered = false
        this.isClicked = false
      }
    },

    handleMouseHover(val) {
      if (val === 'account') {
        this.isHoveredAccount = true
      } else {
        this.isHovered = true
      }
    },
    getTwoFaQr() {
      service.get('/profile/two-fa-qr-img', { headers: { requiresAuth: true } }).then((res) => {
        if (res.success) {
          this.qrCode = {
            qrUrl: res.qr_url,
            accountName: res.account_name,
            key: res.key
          }
        }
      })
    },
    post2fa(val) {
      if (this.google_secret != '') {
        this.loading = true
        const endPoint = val ? '/profile/two-fa-deactivate' : '/profile/two-fa-activate'
        service
          .post(
            endPoint,
            { google_secret: this.google_secret },
            { headers: { requiresAuth: true } }
          )
          .then((res) => {
            if (res.success) {
              console.log(res)
              if (!val) {
                this.resMsg = 'Congratulations, you have successfully activated 2FA'
              } else {
                this.resMsg = '2FA has been successfully turned off for your account'
              }
            } else {
              this.resMsg = res.msg
            }
            this.hasError = res.success
          })
          .finally(() => {
            this.loading = false
            this.showModal = true
            setTimeout(() => {
              this.clearPin()
              this.store.getProfile()
              this.getTwoFaQr()
              this.showModal = false
            }, 2000)
          })
      } else {
        this.message = 'Code cannot be empty.'
      }
    },
    clearPin() {
      this.message = null
      this.google_secret = ''
      this.$refs.otpComponent.clearDigits()
    }
    // deActive2fe() {
    //   service
    //     .post(
    //       '/profile/two-fa-deactivate',
    //       { google_secret: this.google_secret },
    //       { headers: { requiresAuth: true } }
    //     )
    //     .then((res) => {
    //       if (res.success) {
    //         //   this.qrCode = res.qr_url
    //         console.log(res)
    //         alert('Oke')
    //       } else {
    //         alert('gagal')
    //       }
    //     })
    // }
  }
}
</script>

<style scoped>
textarea {
  resize: none;
}
.fa_en {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fa_wrapper,
.qr_code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.setup_key {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}
.fa_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
}
.form-control {
  width: 40px;
  margin-right: 10px;
}
.text-danger {
  font-size: 14px;
  text-align: center;
}
.btn_copy {
  background: none;
  border: none;
}
.qr_wrap {
  position: relative;
}
.copy_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -120%);
}
.copy_icon img {
  width: 90px;
  height: auto;
}
.nav-link {
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .form-control {
    width: 30px;
    height: 30px;
    padding: 0;
  }
}
</style>
