<template>
  <!-- Footer start -->
  <footer class="landing-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="mb-4 mb-lg-0">
            <h5 class="mb-3 footer-list-title">Wavedex.io</h5>
            <ul class="list-unstyled footer-list-menu">
              <li><a href="#about">About</a></li>
              <li><a href="#features">Features</a></li>
              <li><a href="#news">News</a></li>
              <li><a href="#faqs">FAQs</a></li>
              <li><router-link to="/auth/login">Sign in</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="mb-4 mb-lg-0">
            <h5 class="mb-3 footer-list-title">Resources</h5>
            <ul class="list-unstyled footer-list-menu">
              <li><a href="javascript: void(0);">Privacy Policy</a></li>
              <li><a href="javascript: void(0);">Terms & Conditions</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="mb-4 mb-lg-0">
            <h5 class="mb-3 footer-list-title">Links</h5>
            <ul class="list-unstyled footer-list-menu">
              <li><a href="#news">News</a></li>
              <li><a href="#roadmap">Roadmap</a></li>
              <li><a href="#faqs">FAQs</a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="mb-4 mb-lg-0">
            <h5 class="mb-3 footer-list-title">Latest News</h5>
            <div class="blog-post">
              <a href="javascript: void(0);" class="post">
                <div class="badge badge-soft-success font-size-11 mb-3">Cryptocurrency</div>
                <h5 class="post-title">News coming soon</h5>
                <p class="mb-0"><i class="bx bx-calendar me-1"></i> 04 Sept, 2023</p>
              </a>
              <a href="javascript: void(0);" class="post">
                <div class="badge badge-soft-success font-size-11 mb-3">Cryptocurrency</div>
                <h5 class="post-title">News coming soon</h5>
                <p class="mb-0"><i class="bx bx-calendar me-1"></i> 04 Sept, 2023</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <hr class="footer-border my-5" />

      <div class="row">
        <div class="col-lg-6">
          <div class="mb-4">
            <img src="../../assets/images/logo-light.png" alt="" height="20" />
          </div>

          <p class="mb-2">
            {{ dateTime }}
            © Wavedex.io
          </p>
          <p>Rezolutionizing The Future of Trading</p>
        </div>
      </div>
    </div>
    <!-- end container -->
  </footer>
  <!-- Footer end -->
</template>

<script>
export default {
  data() {
    return {
      dateTime: '2023'
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year
  }
}
</script>
