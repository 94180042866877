<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-3" v-if="deposits.length > 0">
              Choose Capital to Withdraw

              <div class="table-responsive">
                <table class="table table-hover dt-responsive nowrap mt-3">
                  <thead class="table-light">
                    <tr>
                      <th class="align-middle">Action</th>
                      <th class="align-middle">#</th>
                      <th class="align-middle">Amount</th>
                      <th class="align-middle">Maturity Period</th>
                      <th class="align-middle">Penalty</th>
                      <th class="align-middle">Fee</th>
                      <th class="align-middle">Received</th>
                    </tr>
                  </thead>

                  <!-- barisan deposit -->
                  <tr v-for="item in deposits" :key="item.id">
                    <td v-if="item.id">
                      <div class="form_check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="flexRadioDefault"
                          :id="item.id"
                          @change="depositSelect(item.id, item.amount)"
                        />
                        <!-- <label class="form-check-label" :for="item.id">Select</label> -->
                      </div>
                    </td>
                    <td>DEPO #{{ item.id }}</td>
                    <td>
                      {{ item.amount }}
                    </td>
                    <td>
                      {{ moment(item.mature_time).format('DD MMMM YYYY, h:mm:ss') }}
                    </td>
                    <td>
                      {{ maturityPenalty(item.mature_time) }}
                    </td>
                    <td>
                      {{ (5 / 100) * parseFloat(item.amount) }}
                    </td>
                    <td>
                      {{ maturityNetWd(item.mature_time, parseFloat(item.amount)) }}
                    </td>
                  </tr>
                </table>
              </div>

              <!-- <h3>Total Withdraw: {{ totalSumSelectedDeposits }}</h3> -->
            </div>

            <div class="mb-3" v-else>You does not have any deposit or capital</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div v-if="addressWallet" class="card-body">
            <h4 class="card-title mb-4">Withdraw</h4>
            <div class="mb-3 row">
              <label for="example-text-input" class="col-md-3 col-form-label">
                Total Withdraw
              </label>
              <div class="col-md-9">
                {{ wdTotal }}
              </div>
            </div>
            <div class="mb-3 row">
              <label for="example-text-input" class="col-md-3 col-form-label"> Network: </label>
              <div class="col-md-9">
                <input type="text" class="form-control" readonly v-model="networkWallet" />
                <!-- <select id="network-select" v-model="network" class="form-control">
                  <option value="USDT.TRC20">USDT.TRC20</option>
                  <option value="USDT.BEP20">USDT.BEP20</option>
                </select> -->
              </div>
            </div>
            <div class="mb-3 row">
              <label for="example-text-input" class="col-md-3 col-form-label"> Address: </label>
              <div class="col-md-9">
                <input type="text" readonly class="form-control" v-model="addressWallet" />
                <button @click="showModalCreate = true" class="btn_add mt-3">Change Address</button>
              </div>
            </div>

            <div v-if="profiles.orang?.two_fa_is_active" class="mb-3 row">
              <label for="example-text-input" class="col-md-3 col-form-label"> 2FA Code </label>
              <div class="col-md-9">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your code"
                  v-model="google_secret"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <button
                  @click="showModalAgreement = true"
                  style="
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    box-sizing: border-box;
                    font-size: 14px;
                    color: #fff;
                    text-decoration: none;
                    line-height: 2em;
                    font-weight: bold;
                    text-align: center;
                    cursor: pointer;
                    display: inline-block;
                    border-radius: 5px;
                    text-transform: capitalize;
                    background-color: #34c38f;
                    margin: 0;
                    border-color: #34c38f;
                    border-style: solid;
                    border-width: 6px 16px;
                  "
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span v-else>Submit</span>
                </button>
              </div>
            </div>
          </div>

          <div v-else class="card-body add_addr">
            <p>
              No addresses have been saved. <br />
              Please add an address first to proceed with withdrawals.
            </p>

            <button @click="showModalCreate = true" class="btn_add">Add Address</button>
          </div>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <modal-reusable
        :show="showModalFail"
        @close="showModalFail = false"
        :typeSuccess="true"
        :isSuccess="false"
        :resMsg="resMsg"
      >
      </modal-reusable>

      <modal-reusable
        :show="showModalSuccess"
        @close="showModalSuccess = false"
        :typeSuccess="true"
        :isSuccess="true"
        :resMsg="resMsg"
      >
      </modal-reusable>

      <modal-reusable :show="showModalAgreement" @close="showModalAgreement = false">
        <template #body>
          <div class="body_content alert alert-info">
            <p>
              Please note that you are withdrawing your active capital. Early withdrawal before
              maturity date stated is subject to 30% penalty fees. Please continue if you agree to
              proceed withdrawing your capital or cancel to cancel it
            </p>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="isAgree"
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              I agree to the terms and conditions {{ isAgree }}
            </label>
          </div>
        </template>

        <template #footer>
          <button @click="showModalAgreement = false">Cancel</button>
          <button :disabled="!isAgree" @click="agreementWdCapital" :class="{ disabled: !isAgree }">
            Submit
          </button>
        </template>
      </modal-reusable>
    </Teleport>

    <ModalAddress :showModalCreate="showModalCreate" @close="(val) => (showModalCreate = val)" />
  </div>
</template>

<script>
// modules
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'
import { computed } from 'vue'

// services
import service from '@/utils/service'

// store
import { useWalletHistory } from '@/stores/wallet-store'
import { useProfile } from '@/stores/profile-store'

// components
import ModalReusable from '@/components/modals/ModalReusable.vue'
import ModalAddress from '../../../modals/ModalAddress.vue'

export default {
  components: { ModalReusable, ModalAddress },
  data() {
    return {
      store: useWalletHistory(),
      profileStore: useProfile(),
      addressWallet: computed(() => this.store.addressWalletA),
      networkWallet: computed(() => this.store.networkWalletA),
      profiles: computed(() => this.profileStore.profiles),
      deposits: [],
      baseURL: import.meta.env.VITE_BASE_API,
      selectedDeposits: [],

      totalSumSelectedDeposits: 0,
      isSelected: false,

      wdTotal: 0,
      google_secret: '',

      loading: false,
      showModalSuccess: false,
      showModalFail: false,
      resMsg: null,
      isNoValid: false,

      showModalCreate: false,
      netWorkSelected: 'USDT.BEP20',
      address: '',
      warMsg: null,
      isSuccess: false,

      showModalAgreement: false,
      isAgree: false,
      moment
    }
  },
  async mounted() {
    await this.ambilDataProfil()

    await this.ambilDataDeposits()
  },

  methods: {
    async ambilDataProfil() {
      var res = await axios({
        method: 'get',
        url: `${this.baseURL}/profile`,
        headers: {
          JWT: Cookies.get('jwt')
        }
      })

      this.userProfile = res.data.data
    },

    async ambilDataDeposits() {
      var res = await axios({
        method: 'get',
        url: `${this.baseURL}/deposits?filter_rules=[{"field": "paid", "op": "boolean", "value": true}, {"field": "claimed", "op": "boolean", "value": false}]`,
        headers: {
          JWT: Cookies.get('jwt')
        }
      })
      this.deposits = res.data.data.datas
    },

    depositSelect(depositId, amount) {
      console.log('depositId', depositId)
      var depos = this.selectedDeposits
      var totalSelectedAmount = parseFloat(this.totalSumSelectedDeposits)

      // jika ada dalam array, maka dibuang deposit id tersebut
      if (depos.includes(depositId)) {
        depos = depos.filter((item) => item !== depositId)
        totalSelectedAmount = totalSelectedAmount - parseFloat(amount)
      } else {
        depos.push(depositId)
        totalSelectedAmount = totalSelectedAmount + parseFloat(amount)
      }

      this.selectedDeposits = depos
      this.totalSumSelectedDeposits = totalSelectedAmount

      this.wdTotal = parseFloat(this.totalSumSelectedDeposits)
    },

    agreementWdCapital() {
      if (this.isAgree) {
        this.showModalAgreement = false
        setTimeout(() => {
          this.wdSubmit()
        }, 400)
      } else {
        alert('Agreement is requred!')
      }
    },

    async wdSubmit() {
      this.loading = true

      try {
        var res = await axios({
          method: 'post',
          url: `${this.baseURL}/withdraws/create-deposit`,
          headers: {
            JWT: Cookies.get('jwt')
          },
          data: {
            deposit_ids: this.selectedDeposits.join(','),
            google_secret: this.google_secret
          }
        })

        if (res.data.success) {
          this.showModalSuccess = true
          this.resMsg = 'Withdrawal processing'
          setTimeout(() => {
            window.location.reload()
          }, 4000)
        } else {
          this.showModalFail = true
          this.resMsg = res.data.msg
        }

        console.log('res', res.data)
      } catch (error) {
        console.error('Error: ', error)
      } finally {
        this.loading = false

        setTimeout(() => {
          this.resetState()
        }, 4000)
      }
    },

    resetState() {
      this.showModalFail = false
      this.showModalSuccess = false
      this.resMsg = null
      this.isNoValid = false
      this.totalSumSelectedDeposits = 0
      this.showModalCreate = false
      // this.netWorkSelected = ''
      this.address = ''
      this.warMsg = null
      this.isSuccess = false
    },

    maturityPenalty(theDate) {
      const nowDate = new Date()

      if (nowDate > moment(theDate)) {
        return 'No'
      } else {
        return 'Yes'
      }
    },

    maturityNetWd(theDate, amount) {
      const nowDate = new Date()

      if (nowDate > moment(theDate)) {
        // jika passed, cuma 5%

        let keluar = amount - (5 / 100) * parseFloat(amount)
        return keluar
      } else {
        // disini kena penalty 35%
        let keluar = amount - (35 / 100) * parseFloat(amount)
        return keluar
      }
    },

    // create walled
    creadWallet() {
      const isValidate = this.validForm()
      const addressValidate = this.isValidAddress()
      console.log({ addressValidate })

      if (isValidate === true) {
        if (!addressValidate) {
          this.warMsg = '*Address not valid'
        } else {
          this.loading = true
          service
            .post(
              '/profile/update-my-wallet',
              { currency_network: this.netWorkSelected, address: this.address },
              { headers: { requiresAuth: true } }
            )
            .then((res) => {
              if (res.success) {
                // this.myWallet = res.data
                this.addressWallet = res.data.address
                this.networkWallet = res.data.currency_network
                this.isSuccess = true
                console.log(res.data)
              }
            })
            .catch((err) => {
              console.error('Error: ', err)
            })
            .finally(() => {
              this.loading = false

              setTimeout(() => {
                this.resetState()
              }, 3000)
            })
        }
      } else {
        this.warMsg = isValidate
      }
    },
    validForm() {
      if (!this.netWorkSelected) {
        return '*Please select network'
      } else if (!this.address) {
        return '*Please pasting address'
      } else {
        return true
      }
    },
    isValidAddress() {
      let result
      if (this.netWorkSelected === 'USDT.BEP20') {
        // Alamat BEP20 harus dimulai dengan "0x" dan memiliki panjang 42 karakter
        result = /^0x[0-9A-Fa-f]{40}$/
      } else {
        // Alamat TRC20 harus dimulai dengan "T" dan memiliki panjang 34 karakter
        result = /^T[0-9A-Za-z]{33}$/
      }
      return result.test(this.address)
    }
  }
}
</script>

<style scoped>
.head_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.head_wrap h2 {
  font-size: 16px;
  margin: 0;
  font-weight: 400;
}
.input_pr {
  width: 130px;
  padding: 2px 10px;
}
.form_check {
  display: flex;
  gap: 5px;
}
.form_check label {
  font-size: 14px;
  font-weight: 400;
}

.btn_add {
  padding: 5px 15px;
  background-color: #eaf5ff;
  border: none;
  border-radius: 8px;
  color: #1e90ff;
}
.title_modal {
  font-size: 20px;
}
.form-group label {
  color: grey;
  font-weight: 400;
}
.form-group input::placeholder {
  font-size: 14px;
  color: grey;
}
.war_box {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  padding: 3px 5px;
  background-color: rgb(233, 107, 128);
}
.war_box p {
  font-size: 14px;
  color: aliceblue;
}
.add_addr {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add_addr p {
  text-align: center;
}
.btn_update {
  background-color: #eaf5ff;
  padding: 2px 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  color: #1e90ff;
}
.disabled {
  background-color: gray !important;
  border: none !important;
  cursor: not-allowed;
  color: rgb(206, 205, 205) !important;
}

@media only screen and (max-width: 500px) {
  th,
  td {
    font-size: 14px;
  }
  .form_check label {
    display: none;
  }
  .input_pr {
    width: 90px;
    font-size: 14px;
  }
}
</style>
