<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5 text-muted">
            <a href="index.html" class="d-block auth-logo">
              <img
                src="../../assets/images/logo-dark.png"
                alt=""
                height="20"
                class="auth-logo-dark mx-auto"
              />
              <img
                src="../../assets/images/logo-light.png"
                alt=""
                height="20"
                class="auth-logo-light mx-auto"
              />
            </a>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card">
            <div class="card-body">
              <div class="p-2">
                <div class="text-center">
                  <div class="avatar-md mx-auto">
                    <div class="avatar-title rounded-circle bg-light">
                      <i class="bx bxs-envelope h1 mb-0 text-primary"></i>
                    </div>
                  </div>
                  <div class="p-2 mt-4">
                    <h4>Please enter your email</h4>
                    <p class="mb-5">A verification link will be sent to your email address</p>

                    <form @submit.prevent="submitForm">
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          id="email"
                          placeholder="Enter email"
                          v-model="postData.email"
                          @blur="v$.postData.email.$touch"
                        />
                        <div v-if="v$.postData.email.$error" style="color: red">
                          <p v-if="v$.postData.email.required.$invalid">
                            * Email address is required
                          </p>
                          <p v-if="v$.postData.email.email.$invalid">* Please enter valid email</p>
                        </div>
                      </div>

                      <div class="mt-5">
                        <button class="btn btn-success w-md" :disabled="loading" type="submit">
                          <span
                            v-if="loading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span v-else>Confirm</span>
                        </button>
                      </div>

                      <Teleport to="body">
                        <!-- <modal-reusable
                          :show="showModalSuccess"
                          @close="showModalSuccess = false"
                          :typeSuccess="true"
                          :isSuccess="true"
                          :resMsg="responseMsg"
                        >
                        </modal-reusable> -->
                        <modal-reusable
                          :show="showModalSuccess"
                          @close="showModalSuccess = false"
                          :typeSuccess="false"
                          :isSuccess="false"
                          :resMsg="responseMsg"
                        >
                          <template #body>
                            <div
                              class="d-flex flex-column align-items-center justify-content-center"
                            >
                              <SuccessAnimation />
                              <p class="text-center mt-4">{{ responseMsg }}</p>
                            </div>
                          </template>
                          <template #footer>
                            <router-link to="/auth/login">
                              <button class="btn btn-outline-primary">Login</button>
                            </router-link>
                          </template>
                        </modal-reusable>

                        <modal-reusable
                          :show="showModalFail"
                          @close="showModalFail = false"
                          :typeSuccess="true"
                          :isSuccess="false"
                          :resMsg="responseMsg"
                        >
                        </modal-reusable>
                      </Teleport>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p>
              Have you verified ?
              <router-link to="/auth/login" class="fw-medium text-primary"> login </router-link>
            </p>
            <p>© {{ dateTime }} Wavedex.io</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from '@/utils/service'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ModalReusable from '@/components/modals/ModalReusable.vue'
import SuccessAnimation from '@/components/animations/SuccessAnimation.vue'

export default {
  components: { ModalReusable, SuccessAnimation },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      dateTime: '2023',
      loading: false,
      showModalSuccess: false,
      showModalFail: false,
      responseMsg: '',
      postData: {
        email: ''
      }
    }
  },
  validations() {
    return {
      postData: {
        email: { required, email }
      }
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year
  },
  methods: {
    submitForm() {
      this.v$.postData.$touch()

      if (!this.v$.$invalid) {
        this.loading = true
        service
          .post('/auth/email-verification-token-request', this.postData)
          .then((response) => {
            if (response.success) {
              this.showModalSuccess = true
              this.responseMsg = response.msg
            } else {
              this.showModalFail = true
              this.responseMsg = response.msg
            }
          })
          .catch((error) => {
            console.error('Error:', error)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
