import axios from 'axios'
import { BASE_API } from './http-common'

var utama = {
  async postAuthLoginTwoFa(email, password, google_secret) {
    var res = await axios({
      method: 'post',
      url: `${BASE_API}/auth/two-fa-login`,
      data: {
        email,
        password,
        google_secret
      }
    })
    return res.data
  },

  async getMyProfileData(token) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${BASE_API}/profile`,
    });
    return res.data;
  },

  async getMyTwoFaQR(token) {
    var res = await axios({
      method: "get",
      headers: {
        JWT: token,
      },
      url: `${BASE_API}/profile/two-fa-qr-img`,
    });
    return res.data;
  },

  async postTwoFaActivate(token, google_secret) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      data: {
        google_secret,
      },
      url: `${BASE_API}/profile/two-fa-activate`,
    });
    return res.data;
  },

  async postTwoFaDeactivate(token, google_secret) {
    var res = await axios({
      method: "post",
      headers: {
        JWT: token,
      },
      data: {
        google_secret,
      },
      url: `${BASE_API}/profile/two-fa-deactivate`,
    });
    return res.data;
  },
}

export default utama
