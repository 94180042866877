<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <!-- breadcrumbs -->
        <BreadcrumbsBar />

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">User Profile</h4>

                <div class="mb-3 row">
                  <label for="example-text-input" class="col-md-2 col-form-label">Email</label>
                  <div class="col-md-10">
                    <input
                      class="form-control disabled"
                      type="text"
                      :value="data?.orang_email"
                      placeholder="Email"
                      id="example-text-input-email"
                      ref="email"
                      readonly="readonly"
                      disabled
                    />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label for="example-text-input" class="col-md-2 col-form-label">Name</label>
                  <div class="col-md-10">
                    <input
                      class="form-control"
                      type="text"
                      :value="data?.orang_name"
                      placeholder="Name"
                      id="example-text-input"
                      ref="name"
                    />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label for="example-text-input" class="col-md-2 col-form-label">Username</label>
                  <div class="col-md-10">
                    <input
                      class="form-control"
                      type="text"
                      :value="data?.orang_nickname"
                      placeholder="Username"
                      id="example-text-input"
                      ref="username"
                    />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label for="example-text-input" class="col-md-2 col-form-label"
                    >ID No / Passport</label
                  >
                  <div class="col-md-10">
                    <input
                      class="form-control"
                      type="text"
                      :value="data?.orang_id_number"
                      placeholder="ID No/ Passport"
                      id="example-text-input"
                      ref="id"
                    />
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="example-email-input" class="col-md-2 col-form-label"
                    >Contact No</label
                  >
                  <div class="col-md-10">
                    <input
                      class="form-control"
                      type="contact"
                      :value="data?.orang_mobilenumber"
                      placeholder="Contact No"
                      id="example-number-input"
                      ref="contactNo"
                    />
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="example-url-input" class="col-md-2 col-form-label">Address</label>
                  <div class="col-md-10">
                    <input
                      class="form-control"
                      type="address"
                      :value="data?.orang_address"
                      placeholder="Address"
                      id="example-text-input"
                      ref="address"
                    />
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="example-tel-input" class="col-md-2 col-form-label"
                    >Referral Code</label
                  >
                  <div class="col-md-10">
                    <input
                      class="form-control disabled"
                      type="Reff id"
                      :value="data?.orang_refid_shadow"
                      placeholder="Enter Referral id"
                      id="example-text-input"
                      readonly="readonly"
                      disabled
                    />
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="example-text-input" class="col-md-2 col-form-label">Upline</label>
                  <div class="col-md-10">
                    <input
                      class="form-control disabled"
                      type="upline reff id"
                      :value="data?.upline_orang_obj.orang_refid_shadow"
                      placeholder="Upline Reff ID"
                      id="example-text-input"
                      readonly="readonly"
                      disabled
                    />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label for="example-text-input" class="col-md-2 col-form-label">
                    Referral Link
                  </label>
                  <div class="col-md-10">
                    <input
                      class="form-control disabled"
                      type="USDT wallet address"
                      :value="`https://www.wavedex.io/auth/register?ref=${data?.orang_refid_shadow}`"
                      placeholder="Enter Number"
                      id="example-text-input"
                      readonly="readonly"
                      disabled
                    />
                  </div>
                </div>

                <tr
                  style="
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    box-sizing: border-box;
                    font-size: 14px;
                    margin: 0;
                  "
                >
                  <td
                    class="content-block"
                    itemprop="handler"
                    itemscope
                    itemtype="http://schema.org/HttpActionHandler"
                    style="
                      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                      box-sizing: border-box;
                      font-size: 14px;
                      vertical-align: top;
                      margin: 0;
                      padding: 0 0 20px;
                    "
                    valign="top"
                  >
                    <a
                      @click="submitForm"
                      itemprop="url"
                      style="
                        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                        box-sizing: border-box;
                        font-size: 14px;
                        color: #fff;
                        text-decoration: none;
                        line-height: 2em;
                        font-weight: bold;
                        text-align: center;
                        cursor: pointer;
                        display: inline-block;
                        border-radius: 5px;
                        text-transform: capitalize;
                        background-color: #34c38f;
                        margin: 0;
                        border-color: #34c38f;
                        border-style: solid;
                        border-width: 6px 16px;
                      "
                      >Save Changes</a
                    >
                  </td>
                </tr>

                <Teleport to="body">
                  <modal-reusable
                    :show="showModalSuccess"
                    @close="showModalSuccess = false"
                    :typeSuccess="true"
                    :isSuccess="true"
                    :resMsg="resMsg"
                  >
                  </modal-reusable>

                  <modal-reusable
                    :show="showModalFailure"
                    @close="showModalFailure = false"
                    :typeSuccess="true"
                    :isSuccess="false"
                    :resMsg="resMsg"
                  >
                  </modal-reusable>
                </Teleport>
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>

        <MyWallet />

        <KycButton :kycStatus="data?.orang_kyc_approve" />

        <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                {{ dateTime }}
                © Wavedex.io
              </div>
              <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">www.wavedex.io</div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <!-- end main content-->
    </div>
    <!-- END layout-wrapper -->
  </div>
</template>

<script>
// service
import service from '@/utils/service'

// components
import BreadcrumbsBar from '../global/BreadcrumbsBar.vue'
import ModalReusable from '../../modals/ModalReusable.vue'
import KycButton from '../../global/KycButton.vue'
import MyWallet from '../profile/MyWallet.vue'

// modules
import { computed } from 'vue'

// stores
import { useProfile } from '@/stores/profile-store'

export default {
  components: { BreadcrumbsBar, ModalReusable, KycButton, MyWallet },
  data() {
    return {
      store: useProfile(),
      data: computed(() => this.store.profiles.orang),
      dateTime: '2023',
      resMsg: '',
      showModalSuccess: false,
      showModalFailure: false
    }
  },
  mounted() {
    this.getProfileData()
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year
  },
  methods: {
    getProfileData() {
      this.store.getProfile()
    },
    submitForm() {
      service
        .post(
          '/profile/update',
          {
            name: this.$refs.name.value,
            nickname: this.$refs.username.value,
            id_number: this.$refs.id.value,
            phone: this.$refs.contactNo.value,
            address: this.$refs.address.value
          },
          { headers: { requiresAuth: true } }
        )
        .then((response) => {
          if (response.success) {
            this.showModalSuccess = true
            this.resMsg = 'Update Profile Success'
            this.getProfileData()
          } else {
            this.showModalFailure = true
            this.resMsg = response.msg
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
        .finally(() => {
          setTimeout(() => {
            this.showModalSuccess = false
            this.showModalFailure = false
          }, 2000)
        })
    }
  }
}
</script>

<style scoped>
.form-control {
  font-weight: 700;
}
.form-control::placeholder {
  font-weight: 400;
}
.disabled {
  color: rgb(143, 143, 143);
}
</style>
