<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-primary-subtle">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Reset Password</h5>
                    <p>Reset wavedex password</p>
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img src="../../assets/images/profile-img.png" alt="" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <a href="index.html">
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <img
                        src="../../assets/images/logo.svg"
                        alt=""
                        class="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </a>
              </div>

              <div class="p-2">
                <div class="alert alert-success text-center mb-4" role="alert">
                  Enter your Email and instructions will be sent to you!
                </div>
                <form class="form-horizontal" action="index.html" @submit.prevent="submitForm">
                  <div class="mb-3">
                    <label for="useremail" class="form-label">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="useremail"
                      placeholder="Enter email"
                      v-model="postData.email"
                      @blur="v$.postData.email.$touch"
                    />
                    <div v-if="v$.postData.email.$error" style="color: red">
                      <p v-if="v$.postData.email.required.$invalid">* Email address is required</p>
                      <p v-if="v$.postData.email.email.$invalid">* Please enter valid email</p>
                    </div>
                  </div>

                  <div class="text-end">
                    <button
                      class="btn btn-primary w-md waves-effect waves-light"
                      :disabled="loading"
                    >
                      <span
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span v-else>Reset</span>
                    </button>

                    <Teleport to="body">
                      <modal-reusable
                        :show="showModalSuccess"
                        @close="showModalSuccess = false"
                        :typeSuccess="true"
                        :isSuccess="true"
                        :resMsg="responseMsg"
                      >
                      </modal-reusable>
                      <modal-reusable
                        :show="showModalFail"
                        @close="showModalFail = false"
                        :typeSuccess="true"
                        :isSuccess="false"
                        :resMsg="responseMsg"
                      >
                      </modal-reusable>
                    </Teleport>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p>© {{ dateTime }} Wavedex.io</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from '@/utils/service'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ModalReusable from '../../components/modals/ModalReusable.vue'

export default {
  components: {
    ModalReusable
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      dateTime: '2023',
      loading: false,
      showModalSuccess: false,
      showModalFail: false,
      responseMsg: '',
      postData: {
        email: ''
      }
    }
  },
  validations() {
    return {
      postData: {
        email: { required, email }
      }
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year
  },
  methods: {
    submitForm() {
      this.v$.postData.$touch()

      if (!this.v$.$invalid) {
        this.loading = true
        service
          .post('/auth/passchange-request', this.postData)
          .then((response) => {
            if (response.success) {
              this.showModalSuccess = true
              this.responseMsg = response.msg
            } else {
              this.showModalFail = true
              this.responseMsg = response.msg
            }
          })
          .catch((error) => {
            console.error('Error:', error)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
