<template>
  <section class="section hero-section bg-ico-hero" id="home">
    <div class="bg-overlay bg-primary"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="text-white-50">
            <div class="mb-4">
              <img src="../../assets/images/logo-light1.png" alt="" height="70" />
            </div>
            <p class="font-size-14">
              WaveDex is your gateway to the cutting-edge world of automated pool trading,
              arbitrage, copy trading, and decentralized exchanges (DEX).
            </p>

            <button
              type="button"
              class="btn btn-primary waves-effect waves-light"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalScrollable"
            >
              Read more
            </button>

            <!-- Scrollable modal -->
            <div
              class="modal fade"
              id="exampleModalScrollable"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalScrollableTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    At WaveDex, we recognize the transformative impact of automation within the
                    realm of trading. This understanding has led us to introduce the cutting-edge
                    "Pool Robot Trade" feature, meticulously designed to supercharge your trading
                    strategies and facilitate the achievement of maximum returns. Elevate your
                    trading experience by incorporating WaveDex's Pool Robot Trade feature into your
                    repertoire, taking your strategies to the next level. <br />
                    <br />

                    Whether you're a novice looking to glean insights from seasoned traders or an
                    experienced investor aiming to refine your approaches, our platform has you
                    covered comprehensively. Joining a pool becomes an opportunity to let automation
                    seamlessly work for you, providing a powerful tool for optimizing your trading
                    endeavors. WaveDex empowers traders of all levels to embrace the potential of
                    automation and unlock new heights in their financial success.
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </div>
                <!-- /.modal-content -->
              </div>
              <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->
          </div>
        </div>
        <!-- <div class="col-lg-7 hero_sale">
          <img src="@/assets/images/header.png" alt="header" class="img-fluid img-mobile" />

          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Read <map name=""></map>more
          </button>
        </div> -->

        <!-- Modal -->
        <!-- <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Term and Condition</h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <strong>Dateline: December 27, 2023</strong> <br />
                Participation in the competition is open to any user with direct sales totaling
                10,000 USD or more.
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-lg-5 col-md-8 col-sm-10 ms-lg-auto">
          <div class="card overflow-hidden mb-0 mt-5 mt-lg-0">
            <div class="card-header text-center">
              <h5 class="mb-0">Pre-Register Countdown</h5>
            </div>
            <div class="card-body">
              <div class="text-center">
                <h5>Time left deposit :</h5>
                <div class="mt-4">
                  <div data-countdown="2023/11/31" class="counter-number ico-countdown">
                    <div class="coming-box">{{ days }} <span>Days</span></div>
                    <div class="coming-box">{{ hours }} <span>Hours</span></div>
                    <div class="coming-box">{{ minutes }} <span>Minutes</span></div>
                    <div class="coming-box">{{ seconds }} <span>Seconds</span></div>
                  </div>
                </div>

                <div class="mt-4">
                  <div>

                    <button
                      type="button"
                      class="btn btn-primary waves-effect waves-light"
                      data-bs-toggle="modal"
                      data-bs-target=".bs-example-modal-center"
                      @click="subscribe"
                    >
                      Subscribe
                    </button>

                    <Teleport to="body">
                      <modal-reusable
                        :show="showModalFail"
                        @close="showModalFail = false"
                        :typeSuccess="true"
                        :isSuccess="false"
                        :resMsg="responseMsg"
                      >
                      </modal-reusable>
                    </Teleport>

                    <div
                      class="modal fade bs-example-modal-center"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Notice</h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <p>
                              Please register with your referral link to continue. We have disable
                              direct register for Pre-register period. Only referral with ID can
                              register during this period. Regards, Wavedex.io team.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="mt-5">
                    <h5 class="fw-semibold">Get extra bonus for early deposit</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
</template>

<script>
import Cookies from 'js-cookie'
// import ModalReusable from '@/components/modals/ModalReusable.vue'

export default {
  components: {},
  data() {
    return {
      targetDate: new Date('December 31, 2023 23:59:59').getTime(), // Tanggal target dalam milidetik
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      showModalFail: false,
      responseMsg: ''
    }
  },
  mounted() {
    //this.updateCountdown()
    //setInterval(this.updateCountdown, 1000)
  },
  methods: {
    updateCountdown() {
      const now = new Date().getTime()
      const timeDifference = this.targetDate - now

      this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
    },
    subscribe() {
      const jwt = Cookies.get('jwt')
      if (jwt) {
        const router = this.$router.push('/dashboard/subscriptions')
        console.log(router)
      } else {
        this.showModalFail = true
        this.responseMsg = 'Please log in first'
      }
    }
  }
}
</script>

<style scoped>
.coming-box {
  font-weight: bold;
}
.img-mobile {
  margin-top: 2rem;
}
.hero_sale {
  position: relative;
}
.hero_sale button {
  position: absolute;
  bottom: 5%;
  right: 21%;
}
.modal-body {
  text-align: justify;
  text-justify: inter-word;
}

@media only screen and (max-width: 685px) {
  .hero_sale button {
    font-size: 12px;
    padding: 1px 4px;
    right: 18%;
  }
}
</style>
