<template>
  <div>
    <div v-if="loading" class="spinner_wrapper">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else class="table-responsive">
      <table
        v-if="histories.length"
        id="datatable"
        class="table table-hover dt-responsive nowrap"
        style="border-collapse: collapse; border-spacing: 0; width: 100%"
      >
        <thead class="table-light">
          <tr>
            <th class="align-middle">ID</th>
            <th class="align-middle">Date</th>
            <th class="align-middle">Network</th>
            <th class="align-middle">Type</th>
            <th class="align-middle">Amount</th>
            <th class="align-middle">Status</th>
            <th class="align-middle">Action</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="history in histories" :key="history.id">
            <td>
              <a class="text-body fw-bold">#{{ history.id }}</a>
            </td>

            <td>{{ moment(history.created_at).format('DD MMMM YYYY, h:mm:ss') }}</td>
            <td>
              <img
                style="margin-right: -4px"
                src="@/assets/images/usdt-icon.svg"
                alt="usdt"
                width="20"
              />
              <img
                v-if="history.network === 'USDT.BEP20'"
                src="@/assets/images/bep.png"
                alt="bep"
                width="20"
              />
              <img v-else src="@/assets/images/trc.png" alt="trc" width="20" />
              <!-- <span>{{ history.network }}</span> -->
            </td>
            <td>Deposit</td>
            <td>{{ history.amount }}</td>
            <td>
              <span
                v-if="history.paid === true"
                class="badge badge-pill badge-soft-success font-size-11"
                >Paid</span
              >
              <span v-else class="badge badge-pill badge-soft-warning font-size-11">Unpaid</span>

              <span v-if="history.claimed == true"
                class="badge badge-pill badge-soft-danger font-size-11"
              >
                withdrawn
              </span>
            </td>
            <td class="btn_act">
              <button
                type="button"
                class="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                @click="detailTrasaction(history.id)"
              >
                View Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-else class="not_found">
        <img src="@/assets/images/found-icon.png" alt="found" width="50" />
        <p>No transactions have been recorded</p>
      </div>
    </div>
  </div>
</template>

<script>
// store
import { useWalletHistory } from '@/stores/wallet-store.js'

// helper
// import dateFormat from '@/helper/DateFormat'

// modules
import { computed } from 'vue'
import moment from 'moment'

export default {
  data() {
    return {
      store: useWalletHistory(),
      histories: computed(() => this.store.depositHistory),
      loading: computed(() => this.store.loading),
      page: 1,
      moment
    }
  },
  mounted() {
    this.getDataHistory()
    // this.dateFormat()
  },
  methods: {
    async getDataHistory() {
      await this.store.getDepositHistory(this.page)
    },

    detailTrasaction(id) {
      const path = `/dashboard/deposit/${id}`
      this.$router.push(path)
    }
  }
}
</script>

<style scoped>
.spinner_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.not_found {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.not_found p {
  max-width: 150px;
  text-align: center;
}
.usdt_icon {
  margin-right: -5px !important;
}
.btn_act button {
  padding: 2px 10px;
}
</style>
