import { ref } from 'vue'
import { defineStore } from 'pinia'
import service from '@/utils/service'

export const useSubscription = defineStore('subscription', () => {
  const subsDatas = ref([])
  const subsDetail = ref([])
  const network = ref('')
  const dateUpdate = ref(null)
  const loading = ref(false)
  const paymentStatus = ref(null)
  //   const doubleCount = computed(() => count.value * 2)
  function getSubsDatas(page) {
    loading.value = true
    service
      .get(`/subscriptions?page=${page}`, { headers: { requiresAuth: true } })
      .then((response) => {
        subsDatas.value = response.data.datas
      })
      .catch((error) => {
        console.error('Error Aman:', error)
      })
      .finally(() => {
        loading.value = false
      })
  }

  function getSubsDetail(id) {
    loading.value = true
    service
      .get(`/subscriptions?filter_rules=[{"field":"id", "op":"equal-num", "value":"${id}"}]`, { headers: { requiresAuth: true } })
      .then((response) => {
        subsDetail.value = response.data.datas[0].coinpayment_res.result
        network.value = response.data.datas[0].network
        dateUpdate.value = response.data.datas[0].updated_at
        paymentStatus.value = response.data.datas[0].paid
      })
      .catch((error) => {
        console.error('Error Aman:', error)
      })
      .finally(() => {
        loading.value = false
      })
  }

  return { loading, subsDatas, subsDetail, network, dateUpdate, paymentStatus, getSubsDatas, getSubsDetail }
})
