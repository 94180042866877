import { ref } from 'vue'
import { defineStore } from 'pinia'
import service from '@/utils/service'
import Cookies from 'js-cookie'

export const useProfile = defineStore('profile', () => {
  const profiles = ref([])
  const totalProfit = ref()
  //   const doubleCount = computed(() => count.value * 2)
  function getProfile() {
    service
      .get('/profile', { headers: { requiresAuth: true } })
      .then((response) => {
        profiles.value = response.data
        const profit = response.data?.profit
        const profitParse = parseFloat(profit).toFixed(2)
        totalProfit.value = profitParse
      })
      .catch((error) => {
        console.error('Error Aman:', error)
      })
  }

  function logOut() {
    service.post('/auth/signout', null, { headers: { requiresAuth: true } }).then((res) => {
      console.log(res)

      Cookies.remove('jwt')
      Cookies.remove('usid')
      window.location.href = '/'
    })
  }

  return { profiles, totalProfit, getProfile, logOut }
})
