<template>
  <swiper
    :slides-per-view="perView"
    :space-between="50"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="img in images" :key="img.id">
      <div class="client-images">
        <img :src="img.img" alt="logo" />
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  props: {
    images: Array
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      perView: 4
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange
    }
  },
  mounted() {
    this.handlePerView()
  },
  methods: {
    handlePerView() {
      const widthScreen = window.innerWidth
      if (widthScreen <= 770) {
        this.perView = 2
      }
    }
  }
}
</script>
