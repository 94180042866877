<template>
  <Transition name="modal-tnc">
    <div v-if="show" class="modal-masks">
      <div class="modal-container">
        <div class="modal-header">
          <svg
            @click="closeModal"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M14 0C6.2 0 0 6.2 0 14C0 21.8 6.2 28 14 28C21.8 28 28 21.8 28 14C28 6.2 21.8 0 14 0ZM19.4 21L14 15.6L8.6 21L7 19.4L12.4 14L7 8.6L8.6 7L14 12.4L19.4 7L21 8.6L15.6 14L21 19.4L19.4 21Z"
              fill="#E62A07"
            />
          </svg>
        </div>

        <div class="modal-body text-start" ref="tnc" :onScroll="scrollToAccept">
          <h1 class="text-center fw-bold">Terms and Conditions</h1>
          <ol>
            <li>
              Acceptance of Terms
              <p>
                By accessing or using
                <a href="https://wavedex.io" target="_blank">https://wavedex.io</a> 's crypto
                platform, you agree to comply with and be bound by these Terms and Conditions.
              </p>
            </li>
            <li>
              Account Registration
              <ol type="a">
                <li>
                  <p>
                    To use certain features of the platform, you may be required to register for an
                    account.
                  </p>
                </li>
                <li>
                  <p>
                    You must provide accurate and complete information during the registration
                    process.
                  </p>
                </li>
                <li>
                  <p>
                    You are responsible for maintaining the confidentiality of your account
                    information.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              Platform Usage
              <ol type="a">
                <li><p>You agree to use the platform in accordance with the terms.</p></li>
                <li>
                  <p>
                    <a href="https://wavedex.io" target="_blank">https://wavedex.io</a> reserves the
                    right to suspend or terminate accounts that violate these terms.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              Cryptocurrency Transactions
              <ol type="a">
                <li>
                  <p>
                    Transactions on the platform involve cryptocurrencies and are subject to market
                    risks.
                  </p>
                </li>
                <li>
                  <p>
                    <a href="https://wavedex.io" target="_blank">https://wavedex.io</a> is not
                    responsible for any financial losses incurred due to market fluctuations.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              Affliate Marketing
              <ol type="a">
                <li><p>The platform may involve an affliate marketing structure.</p></li>
                <li>
                  <p>
                    Users are prohibited from engaging in fraudulent activities, including pyramid
                    schemes.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              Intellectual Property
              <ol type="a">
                <li>
                  <p>
                    All content and materials on the platform are the property of
                    <a href="https://wavedex.io" target="_blank">https://wavedex.io</a>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    Users are prohibited from reproducing, distributing, or modifying any content
                    without permission.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              Privacy Policy
              <ol type="a">
                <li>
                  <p>
                    <a href="https://wavedex.io" target="_blank">https://wavedex.io</a> respects
                    user privacy. Refer to the Privacy Policy for details on data collection and
                    usage.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              Limitation of Liability
              <ol type="a">
                <li>
                  <p>
                    <a href="https://wavedex.io" target="_blank">https://wavedex.io</a> is not
                    liable for any direct, indirect, incidental, or consequential damages.
                  </p>
                </li>
                <li><p>Users use the platform at their own risk.</p></li>
              </ol>
            </li>
            <li>
              Termination
              <ol type="a">
                <li>
                  <p>
                    <a href="https://wavedex.io" target="_blank">https://wavedex.io</a> reserves the
                    right to terminate or suspend accounts without notice.
                  </p>
                </li>
                <li>
                  <p>Users may terminate their accounts by following the platform's procedures</p>
                </li>
              </ol>
            </li>
            <li>
              Changes to Terms
              <ol type="a">
                <li>
                  <p>
                    <a href="https://wavedex.io" target="_blank">https://wavedex.io</a> may modify
                    these terms at any time.
                  </p>
                </li>
                <li>
                  <p>
                    Users will be notified of changes, and continued use of the platform constitutes
                    acceptance.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            Please consult with a legal professional to customize these terms based on the specific
            details of your affliate crypto platform and to ensure compliance with relevant laws.
          </p>
        </div>

        <div class="modal-footers">
          <button
            type="button"
            class="btn btn-primary px-4 py-2"
            :class="!isAccept && 'disabled'"
            @click="acceptModal"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      isShowTimeoutSession: true,
      isCountdownStart: false,
      isAccept: false
    }
  },
  methods: {
    acceptModal() {
      this.$emit('accept')
    },
    closeModal() {
      this.$emit('close')
    },
    scrollToAccept() {
      const tncElement = this.$refs.tnc

      if (tncElement.offsetHeight + tncElement.scrollTop >= tncElement.scrollHeight) {
        this.isAccept = true
      }
    }
  },
  mounted() {
    // untuk mendeteksi scroll pada elemen window
    window.addEventListener('scroll', this.scrollToAccept)
  },
  beforeUnmount() {
    // saat komponen dihancurkan
    window.removeEventListener('scroll', this.scrollToAccept)
  }
}
</script>

<style scoped>
p,
li {
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
}

h1 {
  font-size: 24px;
  padding-bottom: 2rem;
}
ol > li {
  font-weight: 700;
}
ol > li ol[type='a'] li {
  font-weight: 700;
  font-size: 16px;
  width: 110%;
}
.modal-masks {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 90vh;
  padding: 0;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
.modal-header {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  padding: 1.5rem 2rem;
}
.modal-body {
  justify-content: center;
  padding: 0rem 1.2rem;
  margin: 0;
  overflow-y: auto;
}
.modal-footers {
  display: flex;
  justify-content: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

/* transition */
.modal-tnc-enter-active,
.modal-tnc-leave-active {
  transition: opacity 0.2s ease;
}
.modal-tnc-enter,
.modal-tnc-leave-to {
  opacity: 0;
}
.modal-tnc-enter-from .modal-container,
.modal-tnc-leave-to .modal-container {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
</style>
