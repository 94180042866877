<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-primary-subtle">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Change Password</h5>
                    <p>Change Wavedex Password</p>
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img src="../../assets/images/profile-img.png" alt="" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="auth-logo">
                <router-link to="/" class="auth-logo-light">
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <img
                        src="../../assets/images/logo-light.svg"
                        alt=""
                        class="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </router-link>

                <router-link to="/" class="auth-logo-dark">
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <img
                        src="../../assets/images/logo.svg"
                        alt=""
                        class="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </router-link>
              </div>
              <div class="p-2">
                <form class="form-horizontal" action="index.html" @submit.prevent="submitForm">
                  <div class="mb-3">
                    <label for="userpassword" class="form-label">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="userpassword"
                      placeholder="Enter password"
                      v-model="postData.password"
                      @blur="v$.postData.password.$touch"
                    />
                    <div v-if="v$.postData.password.$error" style="color: red">
                      <p v-if="v$.postData.password.required.$invalid">* Password is required</p>
                      <p v-else-if="v$.postData.password.minLength.$invalid">
                        * Password must be at least 6 characters
                      </p>
                      <p v-else-if="v$.postData.password.hasCapital.$invalid">
                        * Password must contain at least one capital letter
                      </p>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="userrepassword" class="form-label">Re-Type Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="userrepassword"
                      placeholder="Re-type password"
                      v-model="postData.password_confirm"
                      @blur="v$.postData.password_confirm.$touch"
                    />
                    <div v-if="v$.postData.password_confirm.$error" style="color: red">
                      <p v-if="v$.postData.password_confirm.required.$invalid">
                        * Please re-enter password
                      </p>
                      <p v-else-if="v$.postData.password_confirm.sameAsPassword.$invalid">
                        * Password do not match
                      </p>
                    </div>
                  </div>

                  <div class="mt-4 d-grid">
                    <button
                      class="btn btn-primary waves-effect waves-light"
                      :disabled="loading"
                      type="submit"
                    >
                      <span
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span v-else>Change</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <div>
              <p>© {{ dateTime }} Wavedex.io</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <modal-reusable :show="showModalSuccess" @close="showModalSuccess = false">
        <template #body>
          <success-animation />
          <p class="mt-4 mb-4 text-center">{{ resMsg }}</p>
        </template>

        <template #footer>
          <router-link to="/auth/login" class="btn btn-outline-primary">Login</router-link>
        </template>
      </modal-reusable>

      <modal-reusable
        :show="showModalFail"
        @close="showModalFail = false"
        :typeSuccess="true"
        :isSuccess="false"
        :resMsg="resMsg"
      >
      </modal-reusable>
    </Teleport>
  </div>
</template>

<script>
import service from '@/utils/service'
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs, minLength } from '@vuelidate/validators'

// components
import SuccessAnimation from '../../components/animations/SuccessAnimation.vue'
import ModalReusable from '../../components/modals/ModalReusable.vue'

export default {
  components: {
    SuccessAnimation,
    ModalReusable
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      dateTime: '2023',
      showModalSuccess: false,
      showModalFail: false,
      resMsg: null,
      loading: false,
      postData: {
        email: '',
        token: '',
        password: '',
        password_confirm: ''
      }
    }
  },
  validations() {
    return {
      postData: {
        password: {
          required,
          minLength: minLength(8),
          hasCapital: (value) => /[A-Z]/.test(value)
        },
        password_confirm: {
          required,
          sameAsPassword: sameAs(this.postData.password)
        }
      }
    }
  },
  mounted() {
    const getDate = new Date()
    const year = getDate.getFullYear()
    this.dateTime = year

    this.getQueryData()
  },
  methods: {
    submitForm() {
      this.v$.postData.$touch()

      if (!this.v$.$invalid) {
        this.loading = true

        service
          .post('/auth/passchange-form-post', this.postData)
          .then((response) => {
            if (response.success) {
              this.showModalSuccess = true
            } else {
              this.showModalFail = true
            }
            this.resMsg = response.msg
          })
          .catch((error) => {
            console.error('Error:', error)
          })
          .finally(() => {
            this.loading = false
            this.postData.password = ''
            this.postData.password_confirm = ''
          })
      }
    },

    getQueryData() {
      const router = this.$route.query
      this.postData.email = router.email
      this.postData.token = router.token
    }
  }
}
</script>
