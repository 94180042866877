<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table
          v-if="tradingDatas.length > 0"
          class="table align-middle table-nowrap mb-0 text-center"
        >
          <thead class="table-light">
            <tr>
              <th>Currency</th>
              <th>Pair</th>
              <th>Type</th>
              <th>Date</th>
              <!-- <th>Amount</th> -->
              <th>Start Price</th>
              <th>End Price</th>
              <!-- <th>Fee</th> -->
              <!-- <th>Total</th> -->
              <th>Result</th>
              <th>Trigger</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tradingDatas" :key="item.id">
              <td class="text-primary fw-bold">{{ item.currency }}</td>
              <td>{{ item.pair }}</td>
              <td>
                <span v-if="item.type === 'sell'" class="bg_sell py-1 px-2 text-white rounded"
                  >Sell</span
                >
                <span v-else class="bg_info py-1 px-2 text-white rounded">Buy</span>
              </td>
              <td>{{ item.date }}</td>
              <!-- <td>{{ item.amount }}</td> -->
              <td v-if="item.type == 'sell'">
                <span v-for="trade in priceDatas" :key="trade.id">
                  <span v-if="item.buy_id == trade.id">{{ trade.rate }}</span>
                </span>
              </td>
              <td v-else>{{ item.rate }}</td>
              <td>
                <span v-if="item.type == 'sell'">
                  {{ item.rate }}
                </span>
              </td>

              <!-- <td>{{ item.fee }}</td> -->
              <!-- <td>{{ item.total }}</td> -->
              <td>
                <div v-if="item.result">
                  <span v-if="parseFloat(item.result) <= 0" class="text-danger"
                    >{{ parseFloat(item.result).toFixed(2) }}%</span
                  >
                  <span v-else class="text-success">{{ parseFloat(item.result).toFixed(2) }}%</span>
                </div>
              </td>
              <td>{{ item.trigger_strategy }}</td>
            </tr>
          </tbody>
        </table>

        <div v-else class="no_data">
          <img src="@/assets/images/hold-on.gif" alt="no found" width="250" />
        </div>
      </div>

      <div v-if="tradingDatas.length > 0" class="pagination_btn">
        <div></div>
        <div class="btn_itm">
          <button v-if="page !== 1" @click="prevPage" class="btn_prev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="10"
              viewBox="0 0 14 9"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.841 2.27994C4.91469 2.21128 4.97379 2.12848 5.01478 2.03648C5.05577 1.94448 5.07781 1.84516 5.07959 1.74446C5.08137 1.64376 5.06284 1.54373 5.02512 1.45034C4.9874 1.35695 4.93126 1.27212 4.86004 1.2009C4.78882 1.12968 4.70399 1.07354 4.6106 1.03582C4.51721 0.998096 4.41718 0.979571 4.31648 0.981348C4.21577 0.983125 4.11646 1.00517 4.02446 1.04616C3.93246 1.08715 3.84966 1.14625 3.781 1.21994L0.53 4.46994L-1.74443e-07 4.99994L0.53 5.52994L3.78 8.77994C3.92139 8.91663 4.1108 8.99231 4.30745 8.9907C4.5041 8.98908 4.69225 8.91029 4.83137 8.7713C4.97049 8.63231 5.04946 8.44424 5.05126 8.24759C5.05306 8.05094 4.97755 7.86146 4.841 7.71994L2.871 5.74994L13.25 5.74994C13.4489 5.74994 13.6397 5.67092 13.7803 5.53027C13.921 5.38962 14 5.19885 14 4.99994C14 4.80103 13.921 4.61026 13.7803 4.46961C13.6397 4.32896 13.4489 4.24994 13.25 4.24994L2.871 4.24994L4.841 2.27994Z"
                fill="#0d6efd"
              />
            </svg>
          </button>
          <button v-if="totalPage > 1" @click="nextPage" class="btn_next">Next page</button>
        </div>

        <div class="total_page">
          <span>Page</span>
          <input type="number" v-model="page" @keyup="inputPage" />
          <span>of {{ totalPage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// modules
import { HTTP, VITE_BOT_API } from '@/applibs/http-common'

// helper
import persenTage from '../../../helper/PersentageResult'

// stores
import { usePaginationStore } from '@/stores/pagination-store'

export default {
  data() {
    return {
      persenTage,
      totalData: 10,
      pagination: usePaginationStore(),
      tradingDatas: [],
      priceDatas: [],
      starData: 0,
      endData: 10,
      page: 1,
      totalPage: null
    }
  },
  sockets: {
    connect: function () {
      console.log('socket connected')
    }
  },
  mounted() {
    this.getDatas()
    this.pagination.resetPagination()

    this.sockets.subscribe('trading_bot', (data) => {
      const today = new Date().toISOString().split('T')[0]
      const tradesToday = data.trades.filter((trade) => trade.date.startsWith(today))
      const totalArr = tradesToday.length
      const perPage = totalArr / 10
      const pembulatan = Math.ceil(perPage)
      this.totalPage = pembulatan
      this.tradingDatas = tradesToday.slice(this.starData, this.endData)
      this.priceDatas = data.trades
    })
  },

  methods: {
    async getDatas() {
      try {
        await HTTP.get(VITE_BOT_API)
      } catch (err) {
        console.error('Error: ', err)
      }
    },
    nextPage() {
      if (this.page !== this.totalPage) {
        this.page = this.page + 1
      }
      this.starData = this.starData + 10
      this.endData = this.endData + 10
      this.getDatas()
    },
    prevPage() {
      if (this.page !== 1) {
        this.page = this.page - 1
        this.starData = this.starData - 10
        this.endData = this.endData - 10
      }
      this.getDatas()
    },
    inputPage(event) {
      if (event.key === 'Enter') {
        // buat menuju ke page sesuai inputan dimana menampilkan 10 data per halaman
        if (this.page < 1) {
          this.page = 1
        } else if (this.page > this.totalPage) {
          this.page = this.totalPage
        }

        this.starData = (this.page - 1) * 10
        this.endData = this.page * 10

        this.getDatas()
      }
    }
  }
}
</script>

<style scoped>
td {
  font-size: 14px;
}
.pagination_btn {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.total_page {
  display: flex;
  gap: 5px;
  align-items: center;
}
.total_page input {
  width: 60px;
  border-radius: 10px;
  display: flex;
  border: 0.5px solid gray;
  background: none;
  text-align: center;
}
.btn_itm {
  display: flex;
  gap: 10px;
}
.btn_prev {
  background: none;
  border: 1px solid #0d6efd;
  border-radius: 5px;
  padding: 2px 10px;
}
.btn_next {
  background-color: #0d6efd;
  border-radius: 5px;
  border: 1px solid #0d6efd;
  padding: 2px 10px;
}
.bg_info {
  background-color: #00b19d;
}
.bg_sell {
  background-color: #ef5350;
}
.no_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
</style>
